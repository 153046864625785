<template>
	<div v-show="model.show !=='none'" class=" lr-content" @click="mouseClick" @mouseover="mouseOver"
		@mouseleave="mouseLeave" @mouseup="parentMouseUp" :style="formStyle" :class="layoutcss">
		<div v-if="model.type=='leftright'" ref="lrcontent" class=" lr-content-area" @mouseup="resizeMouseUp"
			@mousemove="resizeMouseMove">
			<div class="lr-left-block">
				<container :model="model.items[0]" :project="project" :page="page" :host="host" :isedit="isedit"
					:datas="bindData" />
			</div>
			<div class="lr-right-block">
				<container :model="model.items[1]" :project="project" :page="page" :host="host" :isedit="isedit"
					:datas="bindData" />
			</div>
			<div class="lr-leftright noselect" @mousedown="resizeMouseDown">
				<i @click="lrResetSize"
					:class="model.leftMinWidth==leftWidth?'lr-resize-icon fas fa-caret-right':'lr-resize-icon fas fa-caret-left'"></i>
			</div>
		</div>
		<div v-if="model.type=='topbottom'" ref="tpcontent" class=" lr-content-area-b" @mouseup="resizeMouseUp"
			@mousemove="resizeMouseMove">
			<div class="lr-top-block">
				<container :model="model.items[0]" :project="project" :page="page" :host="host" :isedit="isedit"
					:datas="bindData" />
			</div>
			<div class="lr-bottom-block">
				<container :model="model.items[1]" :project="project" :page="page" :host="host" :isedit="isedit"
					:datas="bindData" />
			</div>
			<div class="lr-topbottom  noselect" @mousedown="resizeMouseDown">
				<i @click="lrResetSize"
					:class="model.topMinHeight==topHeight?'lr-resize-icon-b fas fa-caret-down':'lr-resize-icon fas fa-caret-up'"></i>
			</div>
		</div>
	</div>
</template>

<script>
	//import containerBase from '../containerBase.js'
	import layoutBase from './layoutBase.js'


	export default {
		mixins: [layoutBase],
		data() {
			return {
				bindData: null,
				reSizeIng: {
					start: false,
					minX: 0,
					minY: 0,
					maxX: 0,
					maxY: 0,
					x: 0,
					y: 0,
					lx: 0,
					ly: 0,



				},
				//样式中涉及的属性，从模型中的设置属性转移出来，减少对模型的数据变化操作
				leftWidth: null,
				topHeight: null,
			}
		},

		methods: {
			min() { //切换到最小设置值
				let model = this.model
				if (model.type === 'leftright') {
					this.leftWidth = model.leftMinWidth

				} else {
					this.topHeight = model.topMinHeight
				}
			},
			max() { //切换到最小设置值
				let model = this.model
				if (model.type === 'leftright') {
					this.leftWidth = model.leftMaxWidth
				} else {
					this.topHeight = model.topMaxHeight
				}
			},
			default () { //切换到最小设置值
				let model = this.model
				if (model.type === 'leftright') {
					this.leftWidth = model.initWidth
				} else {
					this.topHeight = model.initHeight
				}
			},

			initResizeItems() {
				let model = this.model
				let items = model.items
				this.leftWidth = model.leftWidth
				this.topHeight = model.topHeight
				for (let i = 0; i < 2; i++) {
					if (items.length < 2) {
						let el = null
						if (model.type == 'leftright') {
							el = this.getConfig().create('col', model, this.page.formData)
							//el.minH=model.minH
						} else {
							el = this.getConfig().create('row', model, this.page.formData)
						}
						el.$hold = true
						el.height = '100%'
						el.width = '100%'
						el.overH = 'auto'
						el.overV = 'auto'

					}
				}

				//if (this.isedit) {	}

			},
			lrResetSize() {
				let model = this.model
				if (model.type == 'leftright') {
					let x = parseInt(this.leftWidth)
					let minX = parseInt(model.leftMinWidth)
					if (x <= minX) { //如果拉小了还原成默认宽度,否则缩成最小宽度
						this.leftWidth = model.initWidth
					} else {
						this.leftWidth = model.leftMinWidth
					}
					this.model.leftWidth = this.leftWidth
					this.linkUpdate('leftWidth')
				} else {
					let y = parseInt(this.topHeight)
					let minY = parseInt(model.topMinHeight)
					if (y <= minY) { //如果拉小了还原成默认宽度,否则缩成最小宽度
						this.topHeight = model.initHeight
					} else {
						this.topHeight = model.topMinHeight
					}
					this.model.topHeight = this.topHeight
					this.linkUpdate('topHeight')
				}

			},
			resizeMouseDown(event) {
				let rs = this.reSizeIng
				let model = this.model
				if (model.type == 'leftright') {
					rs.x = event.clientX
					rs.lx = parseInt(this.leftWidth)
					//let content = this.$refs['lrcontent']
					rs.maxX = parseInt(model.leftMaxWidth) //content.offsetWidth-100
					rs.minX = parseInt(model.leftMinWidth)
				} else {
					rs.y = event.clientY
					rs.ly = parseInt(this.topHeight)
					rs.maxY = parseInt(model.topMaxHeight) //content.offsetWidth-100
					rs.minY = parseInt(model.topMinHeight)
				}

				rs.start = true

			},

			resizeMouseMove(event) {
				let rs = this.reSizeIng

				if (rs.start) {
					let model = this.model
					if (model.type == 'leftright') {
						let x = event.clientX - rs.x
						x = rs.lx + x
						if (x < rs.minX) {
							x = rs.minX
							//rs.start=false
						}
						if (x > rs.maxX) {
							x = rs.maxX
							//rs.start=false
						}
						this.leftWidth = x + 'px'
						//model.leftWidth = x + 'px'
					} else {
						let y = event.clientY - rs.y
						y = rs.ly + y
						if (y < rs.minY) {
							y = rs.minY
							//rs.start=false
						}
						if (y > rs.maxY) {
							y = rs.maxY
							//rs.start=false
						}
						this.topHeight = y + 'px'
						//model.topHeight = y + 'px'
					}

				}
			},
			resizeMouseUp(event) {
				this.reSizeIng.start = false
				if (this.model.type == 'leftright') {
					this.model.leftWidth = this.leftWidth
					this.linkUpdate('leftWidth')
				} else {
					this.model.topHeight = this.topHeight
					this.linkUpdate('topHeight')
				}

			},

			parentMouseUp() { //主子关系的容器中不允许在主元素中创建元素
				if (this.isedit) {
					if (this.newComp.show) { //创建或拖动组件
						this.newComp.show = false
						this.$logic.tip.error('请在子容器中创建元素')
					}
					this.tdStart = null //只要是鼠标弹起即取消框选状态
				}

			},
		},
		computed: {
			formStyle() {
				let model = this.model
				let css = this.layoutstyle
				if (model.type == 'leftright') {
					//this.loadExStyle(css, 'leftright')
					css['--lr-left-width'] = this.leftWidth
					css['--lr-right-width'] = 'calc(100% - ' + this.leftWidth + ' - ' + model.splitWidth + ')'
					css['--lr-right-marging'] = model.splitWidth
					css['width'] = 'calc(100% - ' + model.borderL + ' - ' + model.borderR + ')'
					css['height'] = 'calc(100% - ' + model.borderT + ' - ' + model.borderB + ')'
					for (let item of model.items) {
						item.minH = model.minH
					}
				} else if (model.type == 'topbottom') {
					//this.loadExStyle(css, 'topbottom')
					let bh = 'calc(100% - ' + this.topHeight + ' - ' + model.splitWidth + ')'
					css['--lr-top-height'] = this.topHeight
					css['--lr-bottom-height'] = bh
					css['--lr-bottom-marging'] = model.splitWidth
					css['width'] = 'calc(100% - ' + model.borderL + ' - ' + model.borderR + ')'
					css['height'] = 'calc(100% - ' + model.borderT + ' - ' + model.borderB + ')'

					bh = parseInt(model.minH)
					bh = bh - parseInt(model.topHeight) - parseInt(model.splitWidth)
					css['--lr-min-height-b'] = bh + 'px'

					//model.items[0].minH = model.topHeight 内部行容器设置最小高度会撑出上层容器滚动失效
					//model.items[1].minH = bh

				}

				return css
			},
		},
		watch: {
			'model.leftWidth': function(newValue, oldValue) {
				this.leftWidth = newValue
			},
			'model.topHeight': function(newValue, oldValue) {
				this.topHeight = newValue
			}
			/* 'model.url': {
				deep: true,
				handler: function(newV, oldV) {
					if (this.model.type == 'subpage' && newV && newV.length > 0) {
						this.loadPage()
					}
			
				}
			
			}, */
		},
		created() {
			this.createInit()
			this.initResizeItems()
			if (!this.isedit) {
				this.model.$max = this.max
				this.model.$min = this.min
				this.model.$default = this.default
			}

		}
	}
</script>

<style scoped>
	/* 上下左右调节的容器 */
	.lr-content {
		width: 100%;
		height: 100%;
		margin: 0px;
		padding: 0px;
	}

	.lr-content-area {
		display: flex;
		/* width: var(--lr-content-width);
		height: var(--lr-content-height); */
		width: 100%;
		height: 100%;
		min-height: var(--lr-min-height);
		margin: 0px;
		padding: 0px;
		position: relative;
	}

	/* 左右容器 */
	.lr-left-block {
		height: 100%;
		min-height: var(--lr-min-height);
		margin: 0px;
		padding: 0px;
		width: var(--lr-left-width);
		/* background-color: green; */
	}

	.lr-right-block {
		height: 100%;
		min-height: var(--lr-min-height);
		margin: 0px;
		padding: 0px;
		width: var(--lr-right-width);
		margin-left: var(--lr-right-marging);
		/* background-color: red; */
	}

	.lr-leftright {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 0px;
		left: var(--lr-left-width);
		height: 100%;
		width: 10px;
		background-color: rgba(255, 255, 255, 0);
		cursor: ew-resize;
		border-left-style: solid;
		border-left-width: var(--tr-split-width);
		border-left-color: var(--tr-split-color);
		/* 隐藏箭头 */
		overflow: hidden;
		color: rgba(255, 255, 255, 0);
	}

	.lr-leftright:hover {

		color: #000000;
	}

	.lr-resize-icon {
		font-size: 18px;
		cursor: pointer;
		margin: 0px;
	}

	/* 上下容器 */
	.lr-content-area-b {
		display: flex;
		flex-direction: column;
		/* width: var(--lr-content-width);
		height: var(--lr-content-height); */
		width: 100%;
		height: 100%;
		min-height: var(--lr-min-height);
		margin: 0px;
		padding: 0px;
		position: relative;
	}

	.lr-top-block {
		height: var(--lr-top-height);
		margin: 0px;
		padding: 0px;
		width: 100%;
		/* background-color: green; */
	}

	.lr-bottom-block {
		width: 100%;
		min-height: var(--lr-min-height-b);
		margin: 0px;
		padding: 0px;
		height: var(--lr-bottom-height);
		margin-top: var(--lr-bottom-marging);
		/* background-color: red; */
	}

	.lr-topbottom {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: var(--lr-top-height);
		left: 0px;
		height: 10px;
		width: 100%;
		background-color: rgba(255, 255, 255, 0);
		cursor: ns-resize;
		border-top-style: solid;
		border-top-width: var(--tr-split-width);
		border-top-color: var(--tr-split-color);
		/* 隐藏箭头 */
		overflow: hidden;
		color: rgba(255, 255, 255, 0);
	}

	.lr-topbottom:hover {

		color: #000000;
	}

	.lr-resize-icon-b {
		font-size: 18px;
		cursor: pointer;
		margin: 0px;
	}
</style>