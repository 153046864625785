import font from '../../public/font.js'
import layout from '../../public/layout.js'
import special from '../../public/layoutspecial.js'
import options from '../../public/options.js'
import compBase from '../../public/comp.js'
let dataConfig=compBase.getDataConfig()
let dataConfiP=compBase.getDataConfig()
let cs=[
{key:'title',text:'表单绑定'},
{key:'dataId',text:'表单数据Id',type:'text',init:null,target:'',bursh:true,bind:true,tip:'设置属性绑定关系，用于数据表ID字段值，运行时根据属性绑定关系赋值或编程赋值',list: null},
{key:'dataPid',text:'表单数据Pid',type:'text',init:'0',target:'',bursh:true,bind:true,tip:'预留，将来有可能表单中嵌表单',list: null},

{key:'dbTable',text:'数据库表名',type:'table',init:'',target:'',bursh:true,bind:false,tip:'与表单绑定的数据表请勿使用自动增长类型的ID字段',list: null},
//{key:'dbTableText',text:'数据表别名',type:'text',init:'',target:'',bursh:true,bind:false,tip:'',list: null},
//{key:'dbTableId',text:'ID字段',type:'field',init:'',target:null,bursh:true,bind:false,tip:'指定当前数据表的ID字段',list: null},
{key:'idField',text:'ID字段',type:'field',init:dataConfig,target:null,bursh:true,bind:false,tip:'指定当前数据表的ID字段',list: null},

{key:'pidField',text:'父级ID字段',type:'field',init:dataConfiP,target:null,bursh:true,bind:false,tip:'如果是主从关系表，子表中必须设置主表Id值在本表中的字段名称,单表无需设置',list: null},
{key:'idParam',text:'表单ID参数',type:'text',init:'formId',target:null,bursh:true,bind:false,tip:'支持页面自由多个参数传值，如:xxx?p1=123&p2，需指定表单ID值的参数名（区分大小写），默认参数名为formId，如果值为0表示新增表单数据，大于值表示修改，其它值将无法提交',list:null},

//{key:'action',text:'提交接口',type:'text',init:'',target:'action',bursh:true,bind:true,tip:'',list: null},

//{key: 'auto',text: '自动完成开',type: 'select',init: 'Y',	target: '',	extend: false,tip: '',list: options.YON},	
	{key: 'ask',text: '提交前确认',type: 'switch',init: 'Y',	target: '',	bursh: true,bind: true,tip: '只在编程调用表单$submit方法提交的情况下会触发此事件,提交前是否开启对话确认',list: null},
	{key: 'message',text: '确认提示',type: 'text',init: '是否确定提交',	target: '',	bursh: true,bind: true,tip: '只在编程调用表单$submit方法提交的情况下会触发此事件,提交前的对话确认提示消息',list: null},
	{key: 'tell',text: '提交后提示',type: 'switch',init: 'Y',	target: '',	bursh: true,bind: true,tip: '只在编程调用表单$submit方法提交的情况下会触发此事件,提交后是否提示成功信息',list: null},
	{key: 'tip',text: '成功提示',type: 'text',init: '已成功提交',	target: '',	bursh: true,bind: true,tip: '只在编程调用表单$submit方法提交的情况下会触发此事件,提交成功后的提示消息',list: null},
	{key: 'menuTo',text: '提交后菜单',type: 'text',init: '',	target: '',	bursh: true,bind: true,tip: '只在编程调用表单$submit方法提交的情况下生效,提交成功后模拟点击指定的菜单项，只适于当前页为子页面的场景，当前页转向到指定菜单的页面.请输入菜单项的key值,如设定了此属性，[提交后转向]设置失效',list: null},
	{key: 'goto',text: '提交后转向',type: 'text',init: '',	target: '',	bursh: true,bind: true,tip: '只在编程调用表单$submit方法提交的情况下生效,提交成功后自动转向到指定的页面(当前页面是子页面时生效)，只适于当前页为子页面的场景，格式：#key',list: null},
	{key: 'dataTo',text: '组件传值',type: 'text',init: '',	target: '',	bursh: true,bind: true,tip: '表单数据给其它组件传值，例如向打印容器传值，数据加载完成后触发，多组件ID请用逗号分隔，格式：#1,#2,#3',list: null},
	
{key:'title',text:'默认表单项布局'},
{key:'fieldWidth',text:'表单项宽度',type:'autowidth',send:'formfield.autoWidth',init:{xs:"100%",sm:"50%",md:"50%",lg:"50%"},target:null,bursh:true,bind:true,tip:'各类设备屏幕下每个表单项默认宽度',list:null},
{key:'fieldMinHeight',text:'最小高度',type:'text',send:'formfield.minH',init:'30px',target:null,bursh:true,bind:true,tip:'最小高度',list:null},
{key:'fieldTop',text:'外上间距',type:'text',send:'formfield.marginT',init:'5px',target:null,bursh:true,bind:true,tip:'象素、百分比',list:null},	
{key:'fieldBottom',text:'外下间距',type:'text',send:'formfield.marginB',init:'5px',target:null,bursh:true,bind:true,tip:'象素、百分比',list:null},	

{key:'labelWidth',text:'标签区宽度',type:'text',send:'formlabel.width',init:'120px',target:null,bursh:true,bind:true,tip:'各类设备屏幕下每个表单项默认宽度',list:null},
{key:'labelAlign',text:'标签区对齐',type:'autoalign',send:'formlabel.autoAlign',init:{xs:"flex-start",sm:"flex-start",md:"flex-end",lg:"flex-end"},target:null,bursh:true,bind:true,tip:'各类设备屏幕下每个表单项默认宽度',list:null},

{key:'compWidth',text:'组件区宽度',type:'autowidth',send:'formcomp.autoWidth',init:{xs:"100%",sm:"calc(100% - 120px)",md:"calc(100% - 120px)",lg:"calc(100% - 120px)"},target:null,bursh:true,bind:true,tip:'各类设备屏幕下每个表单项默认宽度',list:null},

]
let formEvents=[
	{key: 'title',text: '表单事件'	},
//	{key: '$comp_sql',text: 'SQL自定义',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '系统默认根据列字段关系自动生成SQL，支持用户指定SQL，在此函数中返回SQL字符串，字段名大小写必须与列中配置的字段名一致',list: null	},

//	{key: '$comp_validateCustom',text: '自定义校验',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '表单提交前的用户自定义数据校验，返回false 将阻止提交并触发校验失败 ，返回 true 继续进行设定的其它规则校验',list: null	},
	 {key: '$comp_loadBefore',text: '数据加载前',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '数据加载前触发，可进行数据预处理,返回值：SQL字符串，如有返回值，即以此SQL作为列表的查询语句（SQL中应用包含一个接收ID值的?），否则采用自动查询方式',list: null	},
	 {key: '$comp_loadAfter',text: '数据加载后',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '数据加载完成后触发，可进行特定业务逻辑处理',list: null	},
	 {key: '$comp_loadEmpty',text: '空数据加载',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '未获取到指定ID的数据时触发',list: null	},


	{key: '$comp_validateFailed',text: '验证失败',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '表单提交前的数据校验失败',list: null	},
	
	{key: '$comp_submitBefore',text: '提交前',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '只在编程调用表单$submit方法提交的情况下会触发此事件',list: null	},
	{key: '$comp_submitAfter',text: '提交成功',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '只在编程调用表单$submit方法提交的情况下会触发此事件',list: null	},
	{key: '$comp_submitError',text: '提交失败',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '只在编程调用表单$submit方法提交的情况下会触发此事件',list: null	},


	{key: '$comp_getBeforeSql',text: '前置SQL',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '表单提交前在服务器后端事务内执行的SQL,语句中使用$id引用主表ID，返回SQL对象或SQL对象数组',list: null	},
	{key: '$comp_getAfterSql',text: '后置SQL',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '表单提交后在服务器后端事务内执行的SQL,语句中使用$id引用主表ID，返回SQL对象或SQL对象数组',list: null	},
	
]
let specials= [].concat(special).concat([
	//{key:'beforeSql',text:'创建前SQL',type:'sql',init:'',target:null,bursh:true,bind:true,tip:'表单提交前执行SQL，多条以;分隔',list:null},
	//{key:'afterSql',text:'修改后SQL',type:'sql',init:'',target:null,bursh:true,bind:true,tip:'表单提交后执行SQL，多条以;分隔',list:null},
	
])
let form= {
	data:{
		type:'form',width:'100%',height:'',alignV:'flex-start',minH:'100px',overH:'visible',overV:'visible',paddingT:'15px',paddingB:'15px',paddingL:'15px',paddingR:'15px',
	},
	delete:'',
	hide:'pidField,display,flexW,position,contentD,width,minW,auto,dataId,dataPid',
	base: cs.concat(layout).concat(font) ,
	special :specials,
	action: formEvents.concat( options.getEvents(null,'$hook','$hook_mount,$hook_show,$hook_hide,$hook_destroy'))//.concat( options.getEvents(null,'$timer,$event,$hook'))//,'$event_change,$event_input'
}

export default form