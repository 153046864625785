<template>

	<div v-if="isedit " v-show="model.show !='none'" :class="css" :style="baseStyle" @click.stop="mouseClick"
		@mouseover.stop="mouseOver" @mouseleave.stop="mouseLeave" @mouseup="mouseUp" @mousedown.stop="mouseDown">

		<template v-if="model.group=='base'">
			<el-dropdown v-if="model.allowMenu=='Y'" :trigger="model.popType" :hide-on-click="model.clickHide=='Y'"
				:size="model.menuSize">
				<el-badge v-if="model.allowBadge=='Y'" :type="model.colorType" :is-dot="model.badgeType=='dot'"
					:value="model.badgeType=='num'?parseInt(model.badgeValue): model.badgeValue"
					:max="parseInt(model.maxValue)" class="badgeitem"
					:style="{'--badge-top-offset':model.topOff,'--badge-right-offset':model.rightOff}">
					<basecomp :model="model" :project="project" :page="page" :host="host" :isedit="isedit"
						:datas="datas" />

				</el-badge>
				<basecomp v-else :model="model" :project="project" :page="page" :host="host" :isedit="isedit"
					:datas="datas" />
				<template #dropdown>
					<el-dropdown-menu>
						<el-dropdown-item v-for="item in menuList" :command="item.key" :divided="item.divided=='Y'"
							:disabled="model.disable=='Y'">
							<span v-if="item.divided !='Y'" :style="{color:item.disable=='Y'?'#cccccc' : item.color}">
								<i :class="'menuitem-icon '+item.icon"></i>
								<span>{{item.label}}</span>

							</span>
						</el-dropdown-item>

					</el-dropdown-menu>

				</template>
			</el-dropdown>
			<el-badge v-else-if="model.allowBadge=='Y'" :type="model.colorType" :is-dot="model.badgeType=='dot'"
				:value="model.badgeType=='num'?parseInt(model.badgeValue): model.badgeValue"
				:max="parseInt(model.maxValue)" class="badgeitem"
				:style="{'--badge-top-offset':model.topOff,'--badge-right-offset':model.rightOff}">
				<basecomp :model="model" :project="project" :page="page" :host="host" :isedit="isedit" :datas="datas" />

			</el-badge>
			<basecomp v-else :model="model" :project="project" :page="page" :host="host" :isedit="isedit"
				:datas="datas" />

		</template>
		<template v-else-if="model.group=='wrap'">
			<wrapcomp :model="model" :project="project" :page="page" :host="host" :isedit="isedit" :datas="datas" />
		</template>
		<template v-else-if="model.group=='report'">
			<reportcomp :model="model" :project="project" :page="page" :host="host" :isedit="isedit" :datas="datas" />
		</template>
		<template v-else-if="model.group=='chart'">
			<chartcomp :model="model" :project="project" :page="page" :host="host" :isedit="isedit" :datas="datas" />
		</template>
		<template v-else-if="model.group=='custom'">
			<customcomp :model="model" :project="project" :page="page" :host="host" :isedit="isedit" :datas="datas" />
		</template>
		<template v-else-if="model.group=='doc'">
			<doccomp :model="model" :project="project" :page="page" :host="host" :isedit="isedit" :datas="datas" />
		</template>
		<template v-else-if="model.group=='flow'">
			<flowcomp :model="model" :project="project" :page="page" :host="host" :isedit="isedit" :datas="datas" />
		</template>
		<template v-else-if="model.group=='action'">
			<actioncomp :model="model" :project="project" :page="page" :host="host" :isedit="isedit" :datas="datas" />
		</template>
		<template v-else>
			<div>未知元素[{{model.type}}]</div>
		</template>

		<div v-if="'FH'.indexOf(dbBinded)>-1" class="data-tag">
			<i v-if="dbBinded=='F'" class="far fa-database" title="已绑定数据库表字段"></i>
			<i v-else class="far fa-database" style="color: #FF8C00;" title="部分属性已绑定数据库表字段"></i>
		</div>


		<div @mousedown.stop="toMove" class="toolbutton noselect" v-show="showToolButton">
			<i class="far fa-expand-arrows-alt"></i>
		</div>

	</div>

	<!-- @[eventsMap.$event_click.name]="eventsMap.$event_click.handler"
		@[eventsMap.$event_mousedown.name]="eventsMap.$event_mousedown.handler"
		@[eventsMap.$event_mouseup.name]="eventsMap.$event_mouseup.handler"
		@[eventsMap.$event_mouseleave.name]="eventsMap.$event_mouseleave.handler"
		@[eventsMap.$event_mouseover.name]="eventsMap.$event_mouseover.handler"
		@[eventsMap.$event_dblclick.name]="eventsMap.$event_dblclick.handler"
		@[eventsMap.$event_mouseenter.name]="eventsMap.$event_mouseenter.handler"
		@[eventsMap.$event_mousemove.name]="eventsMap.$event_mousemove.handler"  -->
	<div v-else v-show="model.show !='none'" :style="baseStyle"
		>

		<template v-if="model.group=='base'">
			<el-dropdown v-if="model.allowMenu=='Y'" @command="menuCommand" :trigger="model.popType"
				:hide-on-click="model.clickHide=='Y'" :size="model.menuSize">
				<el-badge v-if="model.allowBadge=='Y'" :type="model.colorType" :is-dot="model.badgeType=='dot'"
					:value="model.badgeType=='num'?parseInt(model.badgeValue): model.badgeValue"
					:max="parseInt(model.maxValue)" class="badgeitem"
					:style="{'--badge-top-offset':model.topOff,'--badge-right-offset':model.rightOff}">
					<basecomp :model="model" :project="project" :page="page" :host="host" :isedit="isedit"
						:datas="datas" />

				</el-badge>
				<basecomp v-else :model="model" :project="project" :page="page" :host="host" :isedit="isedit"
					:datas="datas" />
				<template #dropdown>
					<el-dropdown-menu>
						<el-dropdown-item v-for="item in menuList" :command="item.key" :divided="item.divided=='Y'"
							:disabled="model.disable=='Y'">
							<span v-if="item.divided !='Y'" :style="{color:item.disable=='Y'?'#cccccc' : item.color}">
								<i :class="'menuitem-icon '+item.icon"></i>
								<span>{{item.label}}</span>

							</span>
						</el-dropdown-item>

					</el-dropdown-menu>

				</template>
			</el-dropdown>
			<el-badge v-else-if="model.allowBadge=='Y'" :type="model.colorType" :is-dot="model.badgeType=='dot'"
				:value="model.badgeType=='num'?parseInt(model.badgeValue): model.badgeValue"
				:max="parseInt(model.maxValue)" class="badgeitem"
				:style="{'--badge-top-offset':model.topOff,'--badge-right-offset':model.rightOff}">
				<basecomp :model="model" :project="project" :page="page" :host="host" :isedit="isedit" :datas="datas" />

			</el-badge>
			<basecomp v-else :model="model" :project="project" :page="page" :host="host" :isedit="isedit"
				:datas="datas" />

		</template>
		<template v-else-if="model.group=='wrap'">
			<wrapcomp :model="model" :project="project" :page="page" :host="host" :isedit="isedit" :datas="datas" />
		</template>
		<template v-else-if="model.group=='report'">
			<reportcomp :model="model" :project="project" :page="page" :host="host" :isedit="isedit" :datas="datas" />
		</template>
		<template v-else-if="model.group=='chart'">
			<chartcomp :model="model" :project="project" :page="page" :host="host" :isedit="isedit" :datas="datas" />
		</template>
		<template v-else-if="model.group=='custom'">
			<customcomp :model="model" :project="project" :page="page" :host="host" :isedit="isedit" :datas="datas" />
		</template>
		<template v-else-if="model.group=='doc'">
			<doccomp :model="model" :project="project" :page="page" :host="host" :isedit="isedit" :datas="datas" />
		</template>
		<template v-else-if="model.group=='flow'">
			<flowcomp :model="model" :project="project" :page="page" :host="host" :isedit="isedit" :datas="datas" />
		</template>
		<template v-else-if="model.group=='action'">
			<actioncomp :model="model" :project="project" :page="page" :host="host" :isedit="isedit" :datas="datas" />
		</template>
		<template v-else>
			<div>未知元素[{{model.type}}]</div>
		</template>


	</div>


</template>

<script>
	import formBase from '../../formbase.js'
	//本组件为所有组件元素的根元素 
	import BaseComp from './base/BaseComp.vue'
	import WrapComp from './wrap/WrapComp.vue'

	import ReportComp from './report/ReportComp.vue'
	import DocComp from './doc/DocComp.vue'
	import ChartComp from './chart/ChartComp.vue'
	import CustomComp from './custom/CustomComp.vue'
	import FlowComp from './flow/FlowComp.vue'
	import ActionComp from './action/ActionComp.vue'


	import CommEvent from '../../commevent.js'
	import TimeTask from '../../timeTask.js'
	export default {
		mixins: [formBase, CommEvent, TimeTask],
		//	props: ["model", "datas", "isedit"],
		data() {
			return {
				timer: null,
				superComp: true
			}
		},

		methods: {
			dataLoad(sql = null, reCall = null, targetObj = null) { //动态加载具有列表数据属性的组件数据
				let model = targetObj || this.model //不指定赋值对象就是当前组件

				if ('listData' in model && model.dataOption && model.dataOption.jsonArray && model.dataOption.jsonArray
					.load == 'Y') {
					model.listData = []
				} else if ('initData' in model && model.dataOption && model.dataOption.jsonArray && model.dataOption
					.jsonArray
					.load == 'Y') { //图表类组件的数据加载
					if (Array.isArray(model.initData) && model.initData.length > 1) {
						model.initData = [model.initData[0]]
					}
					setTimeout(() => {
						this.queryData()
					}, 500)
					/* this.$nextTick(() => {
						this.queryData()
					}) */

					return
				} else if (model.type == 'inputhead' //子表编辑器组件的下拉类型列
					||
					model.inputOption && model.inputOption.jsonArray && model.inputOption.jsonArray.load == 'Y' //报表查询的枚举选项
				) {

				} else {
					return
				}
				let dataOption = model.dataOption ? model.dataOption : model.inputOption //兼容有的组件用的不同名称
				let sqlParam = sql && (typeof(sql) == 'string') || dataOption.jsonArray.sql
				if (!sqlParam) { //SQL为空不作处理
					return
				}
				//console.log(sqlParam)
				sqlParam = this.$logic.db.getParamSql(sqlParam, this.page.$params || {}, this.page, model)
				//console.log(sqlParam)
				let heads = dataOption.jsonArray.heads
				let sourceKey = dataOption.sourceKey ? dataOption.sourceKey : this.project.dataSource.sourceKey
				this.$logic.http.sqlQuery(sourceKey, sqlParam, [], {}).then(res => {
					let list = res.data.data.dataList
					let headMap = {}
					for (let i = 0; i < res.data.data.heads.length; i++) {
						let head = res.data.data.heads[i]
						headMap[head.label] = i
					}
					let items = []


					for (let row of list) {
						let rowData = {} //带回原始数据
						for (let key in headMap) {
							rowData[key] = row[headMap[key]]
						}
						let item = {
							$data: rowData
						}
						for (let head of heads) {
							let value = row[headMap[head.field]]

							item[head.key] = value
						}
						items.push(item)
					}
					if ('listData' in model) {
						this.doEvent({
							eventName: 'listLoadAfter',
							list: items,
						}, '$comp_listLoadAfter')

						//model.listData = items
						this.setData('listData', items)
					}

					if (reCall && typeof(reCall) == 'function') {
						reCall(items)
					}


				}).catch(err => {
					this.$logic.tip.error('数据加载异常：' + err.info)
				})

			},
			menuCommand(item) {
				this.doEvent({
					menuItem: item
				}, '$comp_menuclick')
			},

			toMove(e) {
				let model = this.model
				this.newComp.act = 'move'
				this.newComp.obj = model
				this.newComp.type = model.type
				this.newComp.icon = model.icon
				this.newComp.text = model.name
				this.newComp.left = e.clientX - 45
				this.newComp.top = e.clientY + 30
				this.$store.commit('setNewComp', this.newComp)
				this.newComp.show = true
			},
			mouseDown(e) {
				if (this.isedit) {
					return
				}

				this.doEvent(e, '$event_mousedown')

			},
			mouseUp(e) { //只要有鼠标弹起，必取消创建状态		
				if (this.isedit) {
					if (this.newComp.show) {

						let moveObj = this.newComp
						let model = this.model
						if (moveObj.act == 'move') {
							this.$store.commit('clearCreateStatus') //清除待创建状态
							let obj = moveObj.obj
							if (obj === this.model) { //在自己身上放开的取消操作
								return
							}
							let x1 = this.$logic.arrayUtil.getItemIndex(obj.parent.items, obj)
							let x2 = this.$logic.arrayUtil.getItemIndex(model.parent.items, model)
							obj.parent.items.splice(x1, 1)
							if (obj.parent === model.parent) { //同层下,如果移动对象在当前对象前面，之前已删除自己，位置应上移一位，
								if (x1 < x2) {
									model.parent.items.splice(x2 - 1, 0, obj)
								} else {
									model.parent.items.splice(x2, 0, obj) //如果移动对象在当前对象后面，直接插入到当前对象位置
								}
							} else {
								model.parent.items.splice(x2, 0, obj)
								obj.parent = model.parent
							}
							this.$store.commit('setEditElementObj', {
								parent: null,
								element: null,
								act: 'flush'
							})

						} else if (moveObj.act == 'create') { //如果是创建状态，在当前元素前面创建新对象
							let newObj = this.createObj(moveObj, null, this.page.formData)
							this.$store.commit('clearCreateStatus') //清除待创建状态
							if (newObj == null) {
								//this.$logic.tip.error('新建元素失败：' + moveObj.text)
								return
							}
							//新对象移至当前元素前面，并设置当前元素为新建元素
							let items = model.parent.items
							let idx = this.$logic.arrayUtil.getItemIndex(items, model)
							newObj.parent = model.parent
							items.splice(idx, 0, newObj)
							this.$store.commit('setElement', newObj)
						}
					}
				} else {
					this.doEvent(e, '$event_mouseup')
				}
			},
			mouseOver(e) {
				if (this.isedit) {
					this.$store.commit('setMouserInbody', true)
					this.$store.commit('setHoverElement', this.model)
				} else {
					this.doEvent(e, '$event_mouseover')
				}
			},
			mouseLeave(e) {
				if (this.isedit) {
					this.$store.commit('setMouserInbody', false)
					this.$store.commit('setHoverElement', null)
				} else {
					this.doEvent(e, '$event_mouseleave')
				}
			},

			/////////////////////////预置事件

			/* 
						async queryData() {
							let model = this.model
							let check = await this.doEvent({
								eventName: 'requestBefor',
								type: 'API',
								url: model.timerUrl,
								sql: model.timerSql
							}, '$timer_queryBefor')
							if (check === false) {
								return
							}

							if (model.timerUrl && model.timerUrl.length > 10) {

								await this.$logic.http.get(model.timerLayer == 'Y',
									model.timerUrl, {}, res => {
										this.flushData({ //调用公共数据更新函数，具体组件自行覆盖方法,create事件中为model设置$flushData方法
											eventName: 'requestAfter',
											type: 'API',
											result: res.data.data
										}, '$timer_queryAfter')


										//this.dataSet = new Date().getTime()

									}, err => {
										this.flushData({
											eventName: 'requestError',
											type: 'API',
											result: null,
											error: {
												code: err.code,
												msg: err.info
											}
										}, '$timer_queryError')
									})
							} else if (model.timerSql && model.timerSql.length > 10) {
								let option = {
									size: model.timerSqlLimit || 10000,
									pageNo: 0,
									isJson: model.timerSqlFormat == 'N',
									showLayer: model.timerLayer == 'Y'
								}
								await this.$logic.http.sqlQuery(model.dataSource?model.dataSource: this.project.dataSource.sourceKey, model.timerSql, [], option).then(
									res => {
										this.flushData({
											eventName: 'requestAfter',
											type: 'Sql',
											result: res.data.data
										}, '$timer_queryAfter')
									}, err => {
										this.flushData({
											eventName: 'requestError',
											type: 'API',
											result: null,
											error: {
												code: err.code,
												msg: err.info
											}
										}, '$timer_queryError')

									})
							} else { //没有可指定的

							}


						},
						///////////////////////定时任务
						async timeTask() { //

							let model = this.model
							let check = true
							try {
								check = await this.doEvent({
									eventName: 'timerBefor'
								}, '$timer_befor')
								if (check !== false) {
									await this.queryData() //至少执行一次
									this.doEvent({
										eventName: 'timerAfter'
									}, '$timer_after')
								}
							} catch (ex) {
								this.$logic.tip.error('自动加载数据异常：' + ex)
							}

							if (!this.isedit && model.loadAllow === 'Y' && model.timerSplit > 0) { //未开始或未设置周期退出,设计模式只运行一次
								this.timer = setTimeout(() => {

									this.timeTask()

								}, model.timerSplit * 1000)
							}
						}
			 */
		},
		computed: {
			dbBinded() {
				let model = this.model
				let tag = 'N'
				let c = 0
				let cfgs = this.getConfigs('field')
				for (let cfg of cfgs) {
					if (model[cfg.key].fieldName) {
						c++
					}
				}
				if (c > 0 && c == cfgs.length) {
					tag = 'F' //full
				} else if (c > 0) {
					tag = 'H' //half
				}
				return tag
			},
			menuList() {
				return this.getDataList(this.model.menuData)
			},

			css() {
				let css = ['element']
				if (this.isCurrent) {
					css.push('currentel')
				}
				// if(this.isHover){
				// 	css.push('hoverel')
				// }

				return css
			},
			showToolButton() {
				return this.isCurrent
			}

		},
		components: {
			basecomp: BaseComp,
			wrapcomp: WrapComp,
			reportcomp: ReportComp,

			doccomp: DocComp,
			chartcomp: ChartComp,
			customcomp: CustomComp,
			flowcomp: FlowComp,
			actioncomp: ActionComp


		},
		watch: {

			'model.show': {
				handler: function(newV, oldV) {
					if (newV == 'show') {
						this.doEvent({
							eventName: 'show',
							type: newV
						}, '$hook_show')
					} else {
						this.doEvent({
							eventName: 'show',
							type: newV
						}, '$hook_hide')
					}

				}

			},
			/* 			'model.loadAllow': {
							handler: function(newV, oldV) {
								if (!this.isedit && newV === 'Y') { //如果重新设置了启用定时器执行定时任务
									this.timeTask()
								}

							}

						}, */
		},

		mounted() {
			let model = this.model
			if (!this.isedit) {

				this.initMethod()
				this.doEvent({
					eventName: 'afterMount'
				}, '$hook_mount')

				this.dataLoad() //自动加载数据				
				//如果有菜单配置项，且动态加载类型的，加载菜单				
				if (this.model.menuOption && this.model.menuOption.jsonArray.load === 'Y') {
					this.dataLoad(null, (list) => {
						this.model.menuData = []
						setTimeout(() => { //延时替换数据，否则会有数据的污染异常

							this.doEvent({
								eventName: 'menueLoadAfter',
								list: list,
							}, '$comp_menueLoadAfter')

							this.model.menuData = list
						}, 100)
					}, { //传入一个虚组件对象，为了不被自动替换其它数组属性的值
						type: 'inputhead', //为了满足加载数据的条件
						dataOption: this.model.menuOption
					})

				}

				if (model.loadAllow === 'Y') { //开启加载时生效,如果是延迟0.1秒调用，等页面组件渲染完成
					setTimeout(() => {
						this.timeTask()
					}, 1000)

				}

			}



		},
		created() {

			/* 为元素增加取值函数,,
			元素需要增加一个字段名称配置属性，指定各字段名称所在的属性名称：{dataName:'value',fields:[{fieldName:'dateStart',unionType:'int4',refuseNull:'Y'} ,]}
			 */

			if (!this.isedit) {


				this.model.$set = this.setData //属性值不能有set
				this.model.$get = this.getData
				this.model.$linkUpdate = this.linkUpdate
				let model = this.model

				if (model.$DataMap && this.datas && Object.keys(this.datas).length > 0) { //有绑定数据的情况下初始化绑定数据
					this.setBindData(this.datas)
				}
				this.model.$dataLoad = this.dataLoad

				this.initCompEvents()
				this.doEvent({
					eventName: 'beforeCreate'
				}, '$hook_create')
				//console.log(this.model.id,this.eventsMap)
			}

		},
		beforeUnmount() {
			clearInterval(this.timer)
			if (!this.isedit) {
				this.doEvent({
					eventName: 'afterDestroy'
				}, '$hook_destroy')
			}
		},
	}
</script>

<style scoped>
	.element {
		position: relative;
		border: 0px;
	}

	.currentel {
		/* border: dashed 2px #FFD700; 
		background-color: rgba(250, 250, 210, 0.5);*/
		box-shadow: 0px 0px 0px 1px #ff5500 inset;
	}

	.element:hover {
		/* border: dashed 3px #ff5500; */
		box-shadow: 0px 0px 0px 1px #FFD700 inset;
	}

	.toolbutton {
		position: absolute;
		height: 20px;
		width: 20px;
		font-size: 14px;
		color: #FFFFFF;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #409EFF;
		left: 1px;
		top: 1px;
		z-index: 100;
		cursor: pointer;
		overflow: visible;
	}

	.toolbutton:hover {
		border: solid 1px #FFD700;

	}

	.data-tag {
		position: absolute;
		font-size: 14px;
		color: #409EFF;
		left: 0px;
		top: 0px;
		z-index: 100;

	}

	.menuitem-icon {
		width: 20px;
	}

	:deep(.el-dropdown) {
		width: var(--element-width);
	}

	:deep(.el-dropdown--default) {
		width: 100%;
	}



	:deep(.el-badge__content.is-fixed) {
		top: var(--badge-top-offset);
		right: calc(var(--badge-right-offset) + var(--el-badge-size)/ 2);
	}



	/* 	:deep(.badgeitem .el-badge__content) { 设计模式正常，运行模式无效		
		top: var(--badge-top-offset) ;
		right: calc(var(--badge-right-offset) + var(--el-badge-size)/ 2) ;
	} */
</style>