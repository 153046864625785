<template>
	<el-radio-group v-if="model.type=='radio'" v-model="selectValue" @change="onChange" :size="model.size"
		:disabled="model.status=='disabled'" :border="model.border=='Y'" :text-color="model.color" :fill="model.fill"
		:style="compStyle" class="radio-style">
		<template v-if="model.style=='A'">
			<template v-for="(item,index) in dataList">
				<el-radio :label="item.value" :value="item.value" :border="model.border=='Y'"
					:disabled="item.disabled=='Y'">{{item.label}}
				</el-radio>
			</template>

		</template>
		<template v-else>
			<template v-for="(item,index) in dataList">
				<el-radio-button :label="item.value" :disabled="item.disabeld=='Y'">
					{{item.label}}
				</el-radio-button>
			</template>
		</template>
	</el-radio-group>


	<el-checkbox-group v-else-if="model.type=='check'" v-model="checkValues" :size="model.size"
		:disabled="model.status=='disabled'" :border="model.border=='Y'" :text-color="model.color" :fill="model.fill"
		:style="compStyle">
		<template v-if="model.style=='A'">
			<template v-for="(item,index) in dataList">
				<el-checkbox :value="item.value" :label="item.value" :border="model.border=='Y'"
					:disabled="item.disabled=='Y'">
					{{item.label}}
				</el-checkbox>
			</template>

		</template>
		<template v-else>
			<template v-for="(item,index) in dataList">
				<el-checkbox-button :label="item.value" :disabled="item.disabled=='Y'">
					{{item.label}}
				</el-checkbox-button>
			</template>
		</template>
	</el-checkbox-group>


	<template v-else-if="model.type=='selectS'">
		<el-select :style="compStyle" v-model="selectValue" @click.stop="mouseClick" @change="onChange"
			:size="model.size" :multiple="model.multiple=='Y'" :multiple-limit="toInt(model.max)"
			:clearable="model.clearable==='Y'" :filterable="model.filtable==='Y'" :disabled="model.status=='disabled'"
			:placeholder="model.tip">
			<template v-for="(item,index) in dataList" :key="item.value">
				<el-option :label="item.label" :value="item.value" :disabled="item.disabled=='Y'">
				</el-option>
			</template>

		</el-select>
	</template>
	<template v-else-if="model.type=='selectM'">
		<el-select :style="compStyle" v-model="checkValues" @click.stop="mouseClick" :size="model.size"
			:multiple="model.multiple=='Y'" :multiple-limit="toInt(model.max)" :disabled="model.status=='disabled'"
			:filterable="model.filtable==='Y'" :placeholder="model.tip">
			<template v-for="(item,index) in dataList" :key="item.value">
				<el-option :label="item.label" :value="item.value" :disabled="item.disabled=='Y'">
				</el-option>
			</template>

		</el-select>
	</template>

	<el-switch v-else-if="model.type=='switch'" :style="compStyle" v-model="selectValue" @change="onChange"
		:size="model.size" :inline-prompt="model.prompt=='Y'" :active-value="model.activeV" :active-icon="model.activeI"
		:active-text="model.activeT" :active-color="model.activeC" :inactive-value="model.inactiveV"
		:inactive-icon="model.inactiveI" :inactive-text="model.inactiveT" :inactive-color="model.inactiveC"
		:width="model.wide?toInt(model.wide):60" :loading="model.loading=='Y'" :disabled="model.status=='disabled'" />






</template>

<script>
	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase],
		data() {
			return {
				checkValues: [],
				selectValue: null

			}
		},
		computed: {},
		watch: {
			checkValues(nv, ov) {
				//console.log(nv)
				let list = this.getResult()
				let vs = ''
				for (let item of list) {
					if (vs.length > 0) {
						vs = vs + ','
					}
					vs = vs + item
				}
				if (this.isedit) { //会多出空值项

					this.model.value = JSON.stringify(list)
					this.model.values = vs
				} else {

					//this.model.value = this.getResult()


					this.model.value = list //不需要用setData赋值,onChange中自动触发联动
					/* if (ov == null) {

						this.setData('value', list)
					} else {

					} */
					this.setData('values', vs)
					//	
				}


				/* if (ov == null) { //首次初始化不触发change事件
					return
				} */
				this.onChange(nv) //自带的change事件的值与当前什不一致,采用监听触发
			},
			selectValue(nv, ov) {
				/* if (this.isedit) {
					this.model.value = nv
				} */

				//this.setData('value', nv)
				this.model.value = nv
				this.linkUpdate('value')
				if ('label' in this.model) {
					let model = this.model
					let label = ''
					if (model.type == 'switch') {
						if (nv == model.activeV) {
							label = model.activeT
						} else {
							label = model.inactiveT
						}
					} else {
						let dataItem = null
						let listItem = null
						for (let item of this.dataList) {
							if (item.value == nv) {
								listItem = item
								label = item.label
								dataItem = item.$data
								break
							}
						}
						if ('dataItem' in model) {
							if (!dataItem && listItem) {
								dataItem = {
									...listItem
								}
							} else {
								dataItem = {}
							}
							this.setData('dataItem', dataItem)
						}

					}
					this.setData('label', label)
				}




			},
			'model.value': {
				handler: function(nv, ov) {

					if (this.isedit) { //设计阶段没值时清除选项类数据
						if (!nv && 'label' in this.model) {
							this.setData('label', '')
							this.setData('dataItem', {})
						}
					}
					//return
					/* 	if (this.model.isInput === 'S') {
						this.$value(nv)
					} else {
						//this.$value(nv, true) //属性赋值改在setData方法中处理了
					}
 */
				}
			}
		},
		methods: {
			$result() { //获取选中项的原始数据
				let rs = []
				let dataMap = {}
				for (let data of this.dataList) {
					dataMap[data.value] = data
				}
				let datas = this.model.isInput === 'L' ? this.checkValues : [this.selectValue]
				for (let item of datas) {
					let data = dataMap[item]
					if (data) {
						rs.push(data)
					}
				}

				return rs
			},
			getResult() { //排除掉空值的数组结果
				let rs = []
				for (let item of this.checkValues) {
					if (item) {
						rs.push(item)
					}
				}
				return rs
			},
			initValues() {
				this.$value(this.model.value)
			},
			$value(value, custom = false) {
				let model = this.model
				//console.log(model.type)
				if (typeof(value) === 'undefined') { //读取值
					if ('selectM,check'.indexOf(this.model.type) > -1) { //多值元素
						return this.getResult() //this.checkValues
					} else { //单值元素

						return this.selectValue

					}
				} else {
					if ('selectM,check'.indexOf(this.model.type) > -1) { //多值元素
						let vs = []
						if (typeof(value) == 'string') {
							if (value.indexOf('[') == 0) { //数组格式的								
								try {
									vs = JSON.parse(value)
								} catch (ex) {
									this.$logic.tip.error(model.name + '数据格式错误，非法的数组JSON格式：' + ex)
								}
							} else {
								vs = value.split(',')
							}
						} else if (Array.isArray(value)) { //数组元素直接赋值
							vs = value
						} else {
							this.$logic.tip.error(model.name + '(' + model.id + ')' + '只接受数组、Json字符串、逗号分隔的多值格式的数据赋值')
						}
						this.checkValues = vs
						this.model.value = vs
						this.linkUpdate('value')
						/* if(custom){//来自value watch变化时的的赋值,仅用于联动更新，不再作value属性赋值，防止循环解发
							this.linkUpdate('value')
						}else{
							this.setData('value', vs)
						}
						 */
					} else { //单值元素 
						this.selectValue = '' + value
						this.model.value = this.selectValue

						this.linkUpdate('value')
						/* if(custom){//来自value watch变化时的的赋值,仅用于联动更新，不再作value属性赋值，防止循环解发
							this.linkUpdate('value')
						}else{
							this.setData('value', value)
						} */


					}

				}
			}



		},
		created() {
			if (!this.isedit) {
				this.model.$value = this.$value
				this.model.$validate = this.validate
				this.model.$result = this.$result
			}
			this.initValues()


		}
	}
</script>

<style scoped>
	:deep(.el-radio) {
		--el-radio-input-width: var(--radio-input-width);
		--el-radio-input-height: var(--radio-input-height);
		height: var(--radio-item-height);
		margin-right: var(--radio-item-margin-right)
	}
</style>