export default {
	//所有元素，布局容器和组件的公共代码
	methods: {
		initMethod() { //为组件的代理对象增加组件动态产生的方法,Container和BaseElement的created方法中调用 

			if (!this.isedit) {
				this.$logic.pageUtil.setModelProxy(this.model)
			}
		},
		linkUpdate(paramName, onlyEmpty = false) { //传参只处理指定的属性变化 ，不传参处理全部属性,onlyEmpty：只有目标对象没有值时赋值

			if (this.isedit) {
				return
			}
			let panels = null
			let model = this.model
			let linkObj = model.$linkObj //如果当前model是模板元素，那么model.$linkObj 是所有动态元素的共享对象
			if (linkObj) {

				for (let key in linkObj) {
					let toLink = linkObj[key]
					let objMap = toLink.objMap
					let toObj = toLink.target //要么是页面静态元素要么是模板内的设计元素

					if (model.$bindData) { //如果源元素是模板内的动态元素，那么目标元素一定是同数据下的元素

						/* if (toLink.$inited) {

						} else { //对触发此事件的模板数据项元素初始化引用关系
						} */
						//先不启用缓存 model.$linkObj={... $linkObj} //初始化时为模板元素创建联动引用的单独实例，缓存

						if (toLink.$lastItem !== model) { //模板内，toLink是共享数据，当前输入的元素发生了变化就要重新查找联动目标元素
							/* 							panels = []
														for (let pkey in model.$bindData.$cellModel) {
															let item = model.$bindData.$cellModel[pkey]
															if (item && (typeof item === "object") && item.type) {
																panels.push(item)
															}

														}

														toObj = this.$logic.pageUtil.findFirstElementById(panels, toObj.id)
							 */
							//效率低了一点，但代码简单
							toObj = this.$logic.pageUtil.findElementFromBindData(model.$bindData, toLink.target.id)
							toLink.target = toObj

							for (let pkey in objMap) { //获取表达式中关联的其它组件
								//是否在模板容器中存在,如果存在就替换
								let jObj = objMap[pkey]
								//let tempObj = this.$logic.pageUtil.findFirstElementById(panels, jObj.id)
								let tempObj = this.$logic.pageUtil.findElementFromBindData(model.$bindData, jObj.id)
								if (tempObj) {
									objMap[pkey] = tempObj
								}
							}
							toLink.$lastItem = model

						}
					}
					for (let param in toLink.params) {
						let item = toLink.params[param]
						if (paramName) { //如果指定了响应源的哪个属性的变化，判断是否包括在目标联动的配置中
							let check = false
							for (let sp of item.link.comps) {
								if (sp.compId == this.model.id && sp.param == paramName) {
									check = true
									break
								}
							}
							if (!check) {
								continue
							}
						}

						try {
							let varMap = objMap
							let result = null
							eval(item.js)
							/* result=result+'0'
							console.log(toObj,item.js)
							console.log(param+'='+result)
							console.log(toObj) */
							if (toObj.$set) {
								if (onlyEmpty) { //只当无值时赋值
									if (toObj.$get) {
										if (toObj.$get(param)) {

										} else {
											toObj.$set(param, result)
										}
									}
								} else {
									toObj.$set(param, result)
								}

							} else { //模板容器如通用报表数据中产生的数据创建的动态元素此时还没有实例化前端组件没被初始化$set方法
								toObj[param] = result //留了一个问题，不能向二级联动传导 
							}

						} catch (ex) {
							console.log('向' + toObj.id + '.' + param + '联动赋值异常：' + ex)
						}
					}
				}
			}

			if (paramName && this.model.$sqlLink) { //是否被其它组件的SQL引用，如有，也需要更新

				if (paramName in this.model.$sqlLink) { //$sqlLink={key1:{id1:obj1,id2:obj2}}
					let objMap = this.model.$sqlLink[paramName]
					for (let objId in objMap) {
						let obj = objMap[objId]
						if (model.$bindData) { //如果源元素是模板内的动态元素，那么目标元素一定是同数据下的元素
							//obj = this.$logic.pageUtil.findFirstElementById(panels, obj.id)
							obj = this.$logic.pageUtil.findElementFromBindData(model.$bindData, obj.id)
						}
						if (obj) {
							if (obj.$sqlUpdate) { //各组件自行实现特定的SQL更新后的方法
								obj.$sqlUpdate()
							} else { //如果没有实现此方法的，调统一的列表数据更新方法
								if (obj.$dataLoad) { //此方法在BaseElement中定义
									obj.$dataLoad()
								}
							}
						}
					}
				}
			}

		},
		setData(key, value) { //可链式操作  

			if (key in this.model && key.indexOf('$') !== 0) { //不能是系统关键字
				//console.log(this.model.id, value, key)
				if (this.model[key] !== value) { //值有了变化才作响应
					if ('value' == key && this.model.$value) { //调用$value方法赋值
						this.model.$value(value) //,true防止触发循环调用,参数已取消
					} else {
						this.model[key] = value
						this.linkUpdate(key)
					}




				}

			} else {
				setTimeout(() => {
					this.$logic.tip.error('不存在的属性名称赋值：' + key)
				}, 100)

			}
			return this.model._proxy

		},
		getData(key) {
			if (key in this.model && key.indexOf('$') !== 0) { //不能是系统关键字
				return this.model[key]
			} else {
				setTimeout(() => {
					this.$logic.tip.error('不存在的属性名称赋值：' + key)
				}, 100)
				return null
			}
		},
		setBindData(bindData) {

			let data = bindData || this.bindData
			if (!data || Object.keys(data).length === 0) { //如果没有数据集直接返回
				return
			}

			//console.log(this.model,data)
			let model = this.model
			let map = model.$DataMap
			if (map == null || Object.keys(map).length === 0) { //如果没有绑定设置直接返回
			
				return
			}
			//console.log(this.model.type,data)
			for (let key in map) {
				let cfg = map[key]
				if (cfg && cfg.dataName) {
					let dataName = cfg.dataName

					if (cfg.setType == 'S') { //直接赋值
						if (dataName in data) {
							if (model.$set) {
								model.$set(key, data[dataName])
							} else {
								model[key] = data[dataName]
							} 
							//this.model[key] = data[dataName]
						} else { //容错处理key值
							for (let dataKey in data) {
								if (dataKey.toLowerCase() == dataName.toLowerCase()) {
									//this.model[key] = data[dataKey]
									//model.$set(key, data[dataKey])
									if (model.$set) {
										model.$set(key, data[dataName])
									} else {
										model[key] = data[dataName]
									}

									break
								}
							}

						}
					} else { //表达式
						try {
							let js = ''
							let dataMap = data
							let result = null
							for (let dataKey in data) {
								js = js + 'let ' + dataKey + '=dataMap["' + dataKey + '"];'
							}
							js = js + 'result= ' + dataName + ';'
							eval(js)
							//	this.model[key] = result
							if (model.$set) {
								model.$set(key, result)
							} else {
								model[key] = result
							}


						} catch (ex) {
							//this.model[key] = ex
						}


					}

				}
			}

		},
		toLink() {
			if (this.isedit || this.model.enabled === 'N') {
				return
			}
			if (('url' in this.model) && ('containerId' in this.model) && this.model
				.url) { //如果是有效链接// this.model.type == 'link' && this.model.url
				//如果指定了子页面容器,在容器中加载
				let subpage = null
				if (this.model.containerId) { // 链接到当前窗口且指定容器的情况下取容器
					event.preventDefault() //先阻止默认行为
					let cid = this.model.containerId.trim()
					let obj = this.$logic.pageUtil.getElementById(this.page.formData, cid)

					subpage = obj && obj.type === 'subpage' ? obj : null
					if (subpage) {
						subpage.$set('url', this.model.url)
						return
					} else {
						this.$logic.tip.error('链接指向的子页面容器不存在，ID[' + cid + ']')
						return
					}
				}
				//没有目标容器的情况下
				let url = this.model.url
				let x = url.indexOf('?')
				if (x > 0) {
					url = url.substring(0, x) // 排除掉参数后判断是否内部地址
				}
				let sysPage = url.indexOf('/') > -1 || url.indexOf('.') > -1 ? false : true

				if (sysPage) { //如果是系统页面，获取完整地址
					event.preventDefault() //先阻止默认行为
					this.page.$reload(this.model.url, this.model.target)

				} else { //外部页面不用管，有链接自带的行为
					if (this.model.target === '_blank') {
						//window.open(url)不作处理，浏览器会自动打开
					} else {
						//event.preventDefault() //先阻止默认行为
						window.location.href = url
					}
				}

			}
		}

		/* 		setBindData() {
					let data = this.datas
					if (data) {
						let model = this.model
						let map = model.$DataMap
						for (let key in map) {
							let dataName = map[key]
							if (dataName && (dataName in data)) {
								this.model[key] = data[dataName]
							}
						}
					}
				}, */


	}


}