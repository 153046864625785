<template>

	<template v-if="model.dialogMode==='Y'">
		<div v-show="isedit" style="padding:5px 20px;background-color: #e8e8e8;">
			<i class="far fa-chart-network"></i>
		</div>

		<logicdialog ref="flowDialog" :title="'流程'" :dialog-width="model.dialogWidth" @close="isPageShow=false"
			:dialog-height="model.dialogHeight" :dialog-top="model.dialogTop" icon="far fa-file-plus"
			:show-cancel="false" :show-ok="false">
			<flow-page v-if="isPageShow" ref="flowPage" @cancel="onCancel" @success="flowSuccess" @error="flowError"
				@nodeopen="nodeOpen" @nodeclose="nodeClose" @taskchange="taskChange" :model="pageModel "
				:project="project" :page="page" :host="host" :isedit="isedit" :datas="datas" />

		</logicdialog>


	</template>
	<div v-else-if="isedit" @click="mouseClick" @mousedown="mouseDown" @mouseover="mouseOver"
		@mouseleave.stop="mouseLeave" @mouseup="mouseUp" style="min-width: 200px;min-height: 100px;">
		<!-- :style="layoutstyle" :class="layoutcss" -->
		<!-- 设计模式，内部元素不响应鼠标事件  style="pointer-events: none;"-->

		<flow-page style="pointer-events: none;" ref="flowPage" @cancel="onCancel" @success="flowSuccess"
			@error="flowError" :model="pageModel " :project="project" :page="page" :host="host" :isedit="isedit"
			:datas="datas" />

	</div>
	<template v-else>

		<flow-page v-if="isPageShow" ref="flowPage" @cancel="onCancel" @success="flowSuccess" @error="flowError"
			@nodeopen="nodeOpen" @nodeclose="nodeClose" @taskchange="taskChange" :model="pageModel " :project="project"
			:page="page" :host="host" :isedit="isedit" :datas="datas" />
	</template>


</template>

<script>
	import FlowPage from './FlowPage.vue'
	import formBase from '../../../formbase.js'
	//import layoutBase from '../layout/sub/layoutBase.js'
	export default {
		mixins: [formBase],
		//mixins: [formBase,layoutBase],
		data() {
			return {
				flowId: 0,
				insId: 0,
				taskId: 0,
				formDataId: 0,
				isPageShow: false,
				//: true, //是否第一次从数据发起流程，需进行延时处理防止在对话框内的前端还没渲染完成
			}
		},

		methods: {

			toTask(taskInsId) {
				this.insId = 0
				this.taskId = taskInsId
				this.formDataId = 0
				this.showFlowPage(0)
			},
			toView(flowInsId) { //查看流程
				this.taskId = 0
				this.insId = flowInsId
				this.formDataId = 0
				this.showFlowPage(0)
			},
			toCreate() {
				this.toStart(0)

			},
			toStart(formId) { //指定从哪个表单Id的数据发起流程，如值为0空表单创建
				this.insId = 0
				this.taskId = 0
				this.formDataId = formId
				this.showFlowPage(this.formDataId)


			},
			cancelFlow(flowInsId, ask = true) {
				this.$logic.http.get(true, '/api/flow/getflowversionbyflowins/', {

						flowInsId: flowInsId,
						dbSourceKey: this.project.dataSource.sourceKey,
					},
					res => {
						let flowVersion = res.data.data

						let flowDef = this.$logic.http.initFlowVersion(flowVersion, this.elementConfig, false)
						this.flowModel = flowDef.flowModel
						this.flowVersion = flowDef.flowVersion
						/* let taskIns = flowIns.taskItem || {
							id: 0,
							nodeId: 0,
							flowInsId: insId
						}
						this.taskItem = taskIns */

						let startNodeForms = this.$logic.pageUtil.getElements(flowDef.startNode.nodeForm.items,
							'.form',
							true)
						if (startNodeForms.length < 1) {
							this.$logic.tip.error('不存在流程表单容器，无法进行此操作')
							return
						}
						let nodeForm = startNodeForms[0]
						let idField = nodeForm.idField
						let dbTable = nodeForm.dbTable
						let submitData = {
							approve: {
								flowInsId: flowInsId,
								nodeInsId: 0,
								decide: 'closeFlow'

							},
							formData: {
								tableName: dbTable,
								idField: idField,
								dataId: flowInsId
							}
						}

						let dbSourceKey = this.project.dataSource.sourceKey
						if (ask) {
							this.$logic.pop.confirm('确认', '是否确定终止此流程', () => {
								this.$logic.http.post(true, '/api/flow/flowadmin/', {
									dbSourceKey: dbSourceKey
								}, submitData, res => {
									this.listFlush('flowCanceled')
									this.doEvent({
										eventName: 'flowClose',
										flowInsId: flowInsId
									}, '$comp_flowClose')
								})
							})

						} else {
							this.$logic.http.post(true, '/api/flow/flowadmin/', {
								dbSourceKey: dbSourceKey
							}, submitData, res => {
								this.listFlush('flowCanceled')
								this.doEvent({
									eventName: 'flowClose',
									flowInsId: flowInsId
								}, '$comp_flowClose')
							})
						}


					})



				/* if (this.$refs.flowPage) {
					this.doEndFlow(flowInsId)
				} else {
					setTimeout(() => { //等弹出对话框出来后
						this.doEndFlow(flowInsId)
					}, 200)
				} */
			},

			listFlush(optionKey) {
				if (this.model.listId && this.model[optionKey] === 'Y') {
					let listObj = this.$logic.pageUtil.getElementById(this.page.formData, this
						.model.listId)
					if (listObj) {
						if (listObj.$query) {
							listObj.$query()
						}
					} else {
						this.$logic.tip.error('设置了流程操作完成后自动刷新列表，但不存在此ID的列表组件')
					}

				}
			},
			showFlowPage(formId) {

				if (this.model.dialogMode === 'Y') { //弹出对话框模式					
					this.$refs.flowDialog.show()
					this.isPageShow = true
				} else {
					this.isPageShow = false
					setTimeout(() => { //等弹出对话框出来后
						this.isPageShow = true
					}, 200)
				}

				/*  */
			},
			hideFlowPage() {
				this.isPageShow = false
				if (this.$refs.flowDialog) {
					this.$refs.flowDialog.hide()
				}

			},
			flowError(err) {
				this.doEvent({
					eventName: 'error',
					error: err
				}, '$comp_error')
			},
			flowSuccess(result) {
				if (this.taskId) { //处理待办
					this.listFlush('taskFinished')
				} else { //流程发起
					this.listFlush('flowStarted')
				}

				this.hideFlowPage()
				this.doEvent({
					result: result,
					eventName: 'success',

				}, '$comp_success')

			},
			taskChange(user, taskItem) {
				this.listFlush('taskChanged')
				this.doEvent({
					eventName: 'taskChange',

				}, '$comp_taskChange')
			},
			nodeClose(node) {
				this.listFlush('nodeClosed')
				this.doEvent({
					eventName: 'nodeClose',

				}, '$comp_nodeClose')
			},
			nodeOpen(node) {
				this.listFlush('nodeOpened')
				this.doEvent({
					eventName: 'nodeOpen',

				}, '$comp_nodeOpen')
			},
			onCancel(insId, flowNode) {
				this.hideFlowPage()
				this.doEvent({
					eventName: 'cancel',

				}, '$comp_cancel')

			}

		},
		components: {

			flowPage: FlowPage

		},
		computed: {
			pageModel() {
				let model = this.model
				let data = {
					flowId: this.flowId,
					insId: this.insId,
					taskId: this.taskId,
					dataId: this.formDataId
				}

				return data
			}
		},
		created() {
			this.flowId = this.model.flowId
			this.insId = this.model.insId
			this.taskId = this.model.taskId
			this.model.$toStart = this.toStart
			this.model.$toCreate = this.toCreate
			this.model.$toView = this.toView
			this.model.$toTask = this.toTask
			this.model.$cancelFlow = this.cancelFlow

		}
	}
</script>

<style scoped>

</style>