<template>
	<div style="padding-bottom: 20px;">
		<!-- <div>
			 {{flowIns.flowDef}}
		</div> -->
		<div class="title-bar">
			<div>
				<span class="title-point">
					<i class="fas fa-rectangle-landscape" style=" color: #E8E8E8"></i> 未开始
				</span>
				<span class="title-point">
					<i class="fas fa-rectangle-landscape" :style="{color:flowModel.workColor}"></i> 正进行
				</span>
				<span class="title-point">
					<i class="fas fa-rectangle-landscape" :style="{color:flowModel.finishColor}"></i> 已完成
				</span>
				<span class="title-point">
					<i class="fas fa-rectangle-landscape" :style="{color:flowModel.errorColor}"></i> 异常
				</span>
			</div>
			<div>
				<span class="title-mode">
					<i class="fad fa-circle" :style="{color:flowModel.finishColor}"></i> 开始
				</span>
				<span class="title-mode"><i class="fad fa-diamond" style="color:#C71585;"></i> 异步路由
				</span>
				<span class="title-mode"><i class="fad fa-parking-circle" style="color:#00BABD;"></i> 同步路由
				</span>
				<span class="title-mode"><i class="fad fa-stop-circle" :style="{color:flowModel.errorColor}"></i> 结束
				</span>

			</div>


		</div>

		<work-flow :model="flowModel" :project="project" :page="null" :host="null" :isedit="false" :datas="{}"
			@nodeclose="nodeClose" @nodeopen="nodeOpen" />
		<template v-if="true || flowIns.id>0 && flowIns.flowStatus < 10">
			<table v-if="flowIns.id>0 && flowIns.flowStatus < 10" class="tablelist" cellspacing="0" cellpadding="0">
				<tr class="tabletop">
					<td style="width: 180px;">
						任务ID
					</td>
					<td>
						流程位置
					</td>


					<td>
						参与人
					</td>
					<td>
						类型
					</td>
					<td>
						到达时间
					</td>
					<!-- 	<td>
				催办
			</td> -->

				</tr>
				<template v-for="task in flowIns.flowTasks">
					<tr v-if=" task.taskStatus<10" class="trbody">
						<td>
							{{task.id}}
						</td>
						<td>
							{{task.nodeName}} <!-- / {{task.groupName}} -->

						</td>


						<td class="data-tool">
							{{task.userName}}<i class="far fa-edit" style="margin-left: 5px;"
								@click="changeTaskUser(task)" title="变更执行人"></i>
						</td>
						<td>
							{{task.taskType==0?'处理':task.taskType==1?'传阅':'其它'}}
						</td>
						<td>
							{{task.createTime}}
						</td>

						<!-- 	<td> </td> -->
					</tr>
				</template>

			</table>


			<table v-if="isAdmin" class="tablelist" cellspacing="0" cellpadding="0">
				<tr class="tabletop">
					<td>
						变量文本
					</td>
					<td>
						变量名称
					</td>


					<td>
						数据类型
					</td>
					<td style="width:160px;">
						变量值
					</td>
					<td style="width:50px;">
						设置
					</td>


				</tr>
				<template v-for="data in flowIns.variables">
					<tr class="trbody">
						<td>
							{{data.title}}
						</td>
						<td>
							{{data.varName}}
						</td>


						<td>
							{{data.varType}}
						</td>
						<td>
							<div v-if="data.$edit" style="display:flex;">

								<el-input type="text" v-model="data.$newData"></el-input>


							</div>
							<span v-else>
								{{data.value }}
							</span>

						</td>
						<td class="data-tool">
							<div v-if="data.$edit" style="display:flex;justify-content: space-between;">
								<i class="far fa-check" @click="setFlowVar(data)" />
								<i class="far fa-reply" @click="data.$edit=false" />
							</div>
							<span v-else><i class="far fa-edit" @click="toSetVar(data)" /> </span>

						</td>


					</tr>
				</template>

			</table>


		</template>
		<span v-if="flowIns.flowDef">流程管理员：{{flowIns.flowDef.adminName}} </span>


	</div>
	<logicdialog ref="userDialog" @ok="userDialogOk" title="用户选择" dialog-width="800px" dialog-height="80vh"
		icon="far fa-user-plus">
		<orguserchoose ref="userchoose" :max-size="0" @change="userSelected" />
	</logicdialog>
</template>

<script>
	import workFlow from './WorkFlow.vue'
	import OrgUserChoose from '../../../../comm/business/OrgUserChoos.vue'
	export default {
		emits: ['nodeclose', 'nodeopen', 'taskchange'],
		props: {
			flowModel: {
				type: Object
			},
			flowIns: {
				type: Object,
				default: null
			},
			project: {
				type: Object,
				default: null
			}
		},
		data() {
			return {
				selectUsers: [],
				changeTask: null
			}
		},

		methods: {
			userDialogOk() {
				if (this.selectUsers.length !== 1) {
					this.$logic.tip.error('请选择一名用户')
					return
				}
				let targetUser = this.selectUsers[0]

				let submitData = {
					approve: {
						flowInsId: this.flowIns.id,
						nodeInsId: 0,
						decide: 'setExecutor',
						taskItem: {
							id: this.changeTask.id,
							userId: targetUser.key,
							userName: targetUser.label
						}
					}
				}
				let dbSourceKey = this.project.dataSource.sourceKey
				this.$logic.http.post(true, '/api/flow/flowadmin/', {
					dbSourceKey: dbSourceKey
				}, submitData, res => {
					this.$emit('taskchange', targetUser, this.changeTask)
					this.$logic.tip.success('参与人已变更')
				})

				this.$refs.userDialog.hide()


			},
			userSelected(users) {
				this.selectUsers = users

			},
			changeTaskUser(task) {
				this.changeTask = task
				this.selectUsers = []
				if (this.$refs.userchoose) { //弹出窗口没弹出之前没有实例产生,加个判断
					this.$refs.userchoose.clearResult()
				}
				this.$refs.userDialog.show()
			},
			setFlowVar(varItem) {
				let submitData = {
					approve: {
						flowInsId: this.flowIns.id,
						nodeInsId: 0,
						decide: 'setVar',
						taskItem: {
							groupName: varItem.varName,
							signData: varItem.$newData
						}
					}
				}
				let dbSourceKey = this.project.dataSource.sourceKey
				this.$logic.http.post(true, '/api/flow/flowadmin/', {
					dbSourceKey: dbSourceKey
				}, submitData, res => {
					varItem.value = varItem.$newData
					varItem.$edit = false
					this.$logic.tip.success('值已变更')
				})

			},
			toSetVar(varItem) {
				varItem.$newData = varItem.value
				varItem.$edit = true
			},
			nodeOpt(node, opt) {
				let submitData = {
					approve: {
						flowInsId: this.flowIns.id,
						nodeInsId: 0,
						nodeKey: node.id,
						decide: opt,
					}
				}
				let dbSourceKey = this.project.dataSource.sourceKey
				this.$logic.http.post(true, '/api/flow/flowadmin/', {
					dbSourceKey: dbSourceKey
				}, submitData, res => {
					this.$emit(opt.toLowerCase(), node, this.flowIns)
					//this.$logic.tip.success('值已变更')
				})
			},
			nodeClose(node) {
				this.$logic.pop.confirm('确认', '是否确定关闭此节点', () => {
					this.nodeOpt(node, 'nodeClose')
				})

			},
			nodeOpen(node) {
				this.$logic.pop.confirm('确认', '是否确定开启此节点', () => {
					this.nodeOpt(node, 'nodeOpen')
				})

			},
			setFlowStatus() {

				if (!this.flowModel || !this.flowIns || this.flowIns.id < 1) {
					return
				}
				let nodeMap = {}
				let lineMap = {}
				for (let node of this.flowModel.items) {
					nodeMap[node.id] = node
				}
				for (let line of this.flowModel.lineList) {
					lineMap[line.id] = line

				}


				let nodeHisMap = {}
				let lineHisMap = {}

				for (let node of this.flowIns.flowNodes) { //取第一个（按完成时间倒序）节点状态作最终状态
					if (node.taskStatus > 10) { //非正常状态 的节点忽略
						continue
					}
					/* if (!node.ways) {已审批过的节点不一定具有后面的路线，比如不同意退申请人的情况
						continue
					}
 */
					if (!nodeHisMap[node.nodeKey]) { //最新的节点加入集合
						nodeHisMap[node.nodeKey] = node.nodeStatus
					}
					if (node.ways) {
						for (let lineId of node.ways.split(',')) {
							let line = this.$logic.arrayUtil.itemByKey(this.flowModel.lineList, 'id', lineId)
							if (line) {
								lineHisMap[lineId] = line
							}
						}
					}


				}
				for (let key in nodeHisMap) { //为已完成状态的节点设置颜色
					let node = nodeMap[key]

					let nodeStatus = nodeHisMap[key]
					if (node) {
						if (nodeStatus == 10 ) { //完成
							node.nodeColor = this.flowModel.finishColor
							node.nodeFontColor = '#FFFFFF'
							if ('auto' == node.nodeMode) { //连接点类型的节点背景色无效，设置字段颜色为背景色
								node.nodeFontColor = node.nodeColor
							} else if ('start' == node.nodeType) {
								node.nodeFontColor = this.flowModel.finishColor
							} else {

							}
							 
						} else { //预留其它状态
							//console.log(node, nodeStatus)
						}

					}

				}
				//console.log(this.flowIns)
				//为当前活动节点设置颜色 ,覆盖已办流程的状态
				for (let item of this.flowIns.flowNodes) {
					let node = nodeMap[item.nodeKey]
					if (!node) {
						continue
					}
					if ('auto' != node.nodeMode) { //活动节点设置初始化默认颜色
						//	node.nodeColor = '' //
						//node.nodeFontColor = node.nodeType === 'start' ? '#67C23A' : ''
					}

					let nodeStatus = item.nodeStatus
					if (nodeStatus < 10) {
						if (nodeStatus == 7) { //异常状态
							node.nodeColor = this.flowModel.errorColor

							if ('auto' == node.nodeMode) { //连接点类型的节点背景色无效，设置字段颜色为背景色
								node.nodeFontColor = node.nodeColor
							} else if ('start' == node.nodeType) {
								node.nodeFontColor = this.flowModel.errorColor
							} else {
								node.nodeFontColor = '#FFFFFF'
							}
						} else {
							node.nodeColor = this.flowModel.workColor
							if ('auto' == node.nodeMode) { //连接点类型的节点背景色无效，设置字段颜色为背景色
								node.nodeFontColor = node.nodeColor
							} else if ('start' == node.nodeType) {
								node.nodeFontColor = this.flowModel.workColor
							} else {
								node.nodeFontColor = '#FFFFFF'
							}
						}
					}
				}

				for (let key in lineHisMap) {
					let line = lineHisMap[key]
					line.lineColor = this.flowModel.finishColor
				}


			}

		},
		computed: {
			panelSize() {
				let css = {
					/* width:'1200px',
					 //'calc(' + this.flowModel.width + ' + 1000px)',
					//minHeight: 'calc(' + this.flowModel.height + ' + 40px)',
					display: 'flex',
					justifyContent: 'center',
					overflow: 'auto' */
				}
				return css
			},
			isAdmin() {
				let user = this.$logic.getUser()
				//单位管理员或流程管理员
				let check = user.id < 0 || this.flowIns && this.flowIns.flowDef && this.flowIns.flowDef.adminId == user.id

				return check
			}
		},
		watch: {
			flowIns(newValue, oldValue) {
				this.setFlowStatus()
			}
		},
		components: {
			workFlow: workFlow,
			orguserchoose: OrgUserChoose,
		},
		created() {
			this.setFlowStatus()

		}
	}
</script>

<style scoped>
	.title-bar {
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;
	}

	.title-point {
		margin: 0px 20px 0px 0px;
	}

	.title-mode {
		margin: 0px 0px 0px 20px;

	}

	.title-mode i {
		font-size: 16px;
	}

	.tablelist {
		width: 100%;
		min-width: 800px;
		margin-top: 10px;
		border-collapse: collapse;
		border-spacing: 0px;
		border-color: #cccccc;
	}

	.tablelist td {
		text-align: center;
		border: solid 1px #CCCCCC;
		padding: 5px;
	}

	.tabletop>td {
		height: 35px;
		background: linear-gradient(#f5f5f5, #e7e7e7, #f5f5f5);

		text-align: center;


	}

	.trbody {
		height: 30px;
	}

	.trbody:nth-child(odd) {
		background-color: rgba(242, 246, 252, 1);
	}

	.trbody:hover {
		background-color: rgba(250, 250, 210, 1);
	}

	.data-tool i {
		cursor: pointer;
	}

	.data-tool i:hover {
		color: rgb(255, 69, 0);
	}
</style>