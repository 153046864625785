import compBase from '../../public/comp.js'
import options from '../../public/options.js'
import tableCol from '../report/reportcol.js'
import ganttData from './ganttInitData.js'

let bold=[{label:'常规',value:''},{label:'加粗',value:'bold'}]

let nodeFont=[
	{key:'title',text:'节点样式'},
//	{key:'areaWidth',text:'目录区宽度',type:'text',init:'250px',target:'--node-area-width',bursh:true,bind:true,tip:'',list:null},
	
	{key:'iconColor',text:'图标颜色',type:'color',init:'#1E90FF',target:'--node-icon-color',bursh:true,bind:true,tip:'',list:null},
	{key:'indent',text:'缩进距离',type:'number',init:25,target:'',bursh:true,bind:true,tip:'',list:null},
	{key:'nodeH',text:'横向对齐',type:'select',init:'left',target:'',bursh:true,bind:true,tip:'',list: options.LCR},
	{key:'nodeV',text:'纵向对齐',type:'select',init:'middle',target:'',bursh:true,bind:true,tip:'',list: options.TMB},
	
	{key:'nfontName',text:'节点字体',type:'select',init:'',target:'--node-font-name',bursh:true,bind:true,tip:'',list:options.fonts},
	{key:'nfontSize',text:'节点字号',type:'select',init:'',target:'--node-font-size',bursh:true,bind:true,tip:'',list:options.fontSizes},	
	{key:'nfontColor',text:'节点颜色',type:'color',init:'#1E90FF',target:'--node-font-color',tip:'',bursh:true,bind:true,list:null},	
	{key:'nfontBold',text:'节点粗体',type:'select',init:'',target:'--node-font-weight',bursh:true,bind:true,tip:'',list:bold},
	{key:'nbackColor',text:'节点背景色',type:'color',init:'#F4F4F4',target:'--node-back-color',bursh:true,bind:true,tip:'选择颜色或输入渐变色',list:null},
	
]
let dataFont=[
	{key:'title',text:'数据样式'},
	{key:'dataH',text:'横向对齐',type:'select',init:'center',target:'',bursh:true,bind:true,tip:'',list: options.LCR},
	{key:'dataV',text:'纵向对齐',type:'select',init:'middle',target:'',bursh:true,bind:true,tip:'',list: options.TMB},
	
	{key:'dfontName',text:'数据字体',type:'select',init:'',target:'--data-font-name',bursh:true,bind:true,tip:'',list:options.fonts},
	{key:'dfontSize',text:'数据字号',type:'select',init:'',target:'--data-font-size',bursh:true,bind:true,tip:'',list:options.fontSizes},	
	{key:'dfontColor',text:'数据颜色',type:'color',init:'',target:'--data-font-color',tip:'',bursh:true,bind:true,list:null},	
	{key:'dfontBold',text:'数据粗体',type:'select',init:'',target:'--data-font-weight',bursh:true,bind:true,tip:'',list:bold},
	{key:'dbackColor',text:'数据背景色',type:'color',init:null,target:'--data-back-color',bursh:true,bind:true,tip:'选择颜色或输入渐变色',list:null},
	
]


let colls=[{label:'折叠',value:'collapse'},{label:'不折叠',value:''}] 
let oprations=[{label:'可编辑',value:'edit'},{label:'只读',value:'read'}] 
let sumLayer=[{label:'不自动汇总',value:''}
//,{label:'叶子节点汇总',value:'leaf'}
,{label:'逐级汇总',value:'all'}] 

let dataSet={dataSourceKey:'',sql:'',headItems:[],queryItems:[],orderItems:[],tick:0}

let base=[
{key:'title',text:'甘特图属性'},
/* 
{key:'dataSet',text:'数据集',type:'report',init:dataSet,target:'',bursh:true,bind:true,tip:'',list: null},
{key:'allowQuery',text:'允许查询',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list: options.YON},
{key:'queryItemWidth',text:'查询项宽度',type:'text',init:'100px',target:'',bursh:true,bind:true,tip:'文本框等输入元素的宽度，日期时间输入框不受此影响',list: options.YON},
{key:'allowOrder',text:'允许排序',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list: options.YON},
{key:'advanceQuery',text:'可高级查询',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list: options.YON},
{key:'headCustom',text:'定制标题栏',type:'switch',init:'N',target:'',bursh:true,bind:true,tip:'',list: options.YON},

//{key:'opration',text:'操作方式',type:'select',init:'read',target:'',bursh:true,bind:true,tip:'',list: oprations},
{key:'total',text:'数据汇总',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list:  options.YON},

 */
{key:'timeType',text:'任务精度',type:'select',init:'day',target:'',bursh:true,bind:true,tip:'任务计划精确到天或小时或分钟',list: options.toOptions('天=day,小时=hour,分钟=minute') },
{key:'planType',text:'时间轴单位',type:'select',init:'day',target:'',bursh:true,bind:true,tip:'以什么单位进行时间轴排列',list: options.toOptions('天=day,小时=hour,分钟=minute') },
{key:'maxPoints',text:'时间点上限',type:'number',init:50,target:'',bursh:true,bind:true,tip:'时间点大多个数',list: null },
{key:'unitSize',text:'时间跨度',type:'number',init:0,target:'',bursh:true,bind:true,tip:'以多长的时间区间作为合并的时间点，值为0自动计算',list: null },

{key:'width',text:'宽度',type:'text',init:'100%',target:'width',bursh:true,bind:true,tip:'',list: null},
{key:'height',text:'高度',type:'text',init:'100%',target:'height',bursh:true,bind:true,tip:'',list: null},

{key:'minW',text:'最小宽度',type:'text',init:'500px',target:'minWidth',bursh:true,bind:true,tip:'用于兼容移动端宽度自适应,象素、百分比、函数类型',list:null},
{key:'minH',text:'最小高度',type:'text',init:'100px',target:'minHeight',tip:'象素、百分比、函数类型',bursh:true,bind:true,list:null},	


{key:'foldWidth',text:'目录区宽度',type:'text',init:null,target:'--node-area-width',bursh:true,bind:true,tip:'',list: null},
{key:'foldMinWidth',text:'目录最小宽',type:'text',init:'300px',target:'--node-area-minwidth',bursh:true,bind:true,tip:'目录区在此最小宽度的基础上自动拉伸,如需固定宽度请在设置目录区宽度',list: null},
{key:'colMinWidth',text:'列最小宽度',type:'text',init:'100px',target:'--col-min-width',bursh:true,bind:true,tip:'各数据列在此最小宽度的基础上自动拉伸,如需固定宽度请在列属性中设置列宽度',list: null},

//{key:'nodeAct',text:'节点折叠',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list: options.YON},

//{key:'dataAct',text:'数据交互',type:'select',init:'N',target:'',bursh:true,bind:true,tip:'',list: options.YON},
{key:'headHeight',text:'表头行高',type:'text',init:'40px',target:'--head-height',bursh:true,bind:true,tip:'',list: null},

{key:'trH',text:'表体行高',type:'text',init:'40px',target:'--row-height',bursh:true,bind:true,tip:'',list: null},

//{key:'lineShow',text:'显示网格线',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list: options.YON},
{key:'hoverColor',text:'悬浮颜色',type:'color',init:'#FAFAD2',target:'--hover-color',bursh:true,bind:true,tip:'选择颜色',list:null},	 
{key:'lineColor',text:'斑马线颜色',type:'color',init:'#fafafa',target:'--line-color',bursh:true,bind:true,tip:'选择颜色',list:null},	 

//{key:'borderColor',text:'网格颜色',type:'color',init:'#909399',target:'borderColor',bursh:true,bind:true,tip:'选择颜色',list:null},	 	 
//{key:'lineSize',text:'网格线粗',type:'number',init:'1',target:'',bursh:true,bind:true,tip:'',list: null},


{key: 'taskNodes',text: '任务项',type: 'json',init: ganttData,	target: '',	bursh: true,bind: true,tip: '',list: null},
{key: 'items',text: '数据列',type: 'json',init:null,	target: '',	bursh: true,bind: true,tip: '',list: null}
	

]
let events=[
	{key: '$comp_filter',text: '数据过滤',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '修改或增加数据项，干预处理当前所有数据',	list: null	},
	{key: '$comp_nodeClick',text: '节点点击',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '',	list: null	},
	{key: '$comp_dataClick',text: '数据点击',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '',	list: null	},
	{key: '$comp_getSql',text: '动态SQL',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '动态变更SQL,返回数据结果中的字段应与初始设定中的SQL结果一致',	list: null	},

]






/* let base=[
	{key:'title',text:'条形码'},
 

].concat(compBase.base)
 */
let gantt= {
	data:{
		type:'gantt',
	},
	delete:'',
	hide:'taskNodes,items',
	base:base.concat(nodeFont).concat(dataFont) ,
	special :[],
	action:options.getCommonEvents().concat(options.getEvents(null,'$hook'))
}

export default gantt