import font from '../../public/font.js'
import layout from '../../public/layout.js'
import special from '../../public/layoutspecial.js'
import options from '../../public/options.js'
import extend from '../../public/extend.js'
 let initData=JSON.stringify( [
 	['标题', '文本', '图片', '数值'],
 	['标题一', '文本1', '地址1', 10],
 	['标题二', '文本2', '地址2', 50],
 	['标题三', '文本3', '地址3', 90]
 	 
 ]).replaceAll('],','],\r\n')

let inputOption=options.getInputOption() 
inputOption.jsonArray.dataType='array'
 
 
let base= [
/* 	{key:'display',text:'布局方式',type:'select',init:'flex',target:'display',bursh:true,bind:true,tip:'',list:null},
	{key:'flexD',text:'排版方式',type:'select',init:'row',target:'flex-direction',bursh:true,bind:true,tip:'设定内部元素横向与纵向的排列方向，将对水平和垂直方向发生转置，具体规则参考CSS Flex布局的flex-direction属性',list:null},
	{key:'flexW',text:'允许换行',type:'select',init:'wrap',target:'flex-wrap',bursh:true,bind:true,tip:'布局类型为flex时生效,是否允许内容内元素自动换行',list:null},
	{key:'alignH',text:'水平对齐',type:'select',init:'flex-start',target:'justifyContent',bursh:true,bind:true,tip:'主轴方向为横向时水平方向对齐，否则为纵向方向对齐',list:options.alignsH},
 
	{key:'alignV',text:'垂直对齐',type:'select',init:'flex-start',target:'alignContent',tip:'主轴垂直方向时垂直方向对齐，否则为水平方向对齐',bursh:true,bind:true,list:options.alignContents},	
	 */
	{key:'same',text:'共享模板',type:'switch',init:'Y',target:'',tip:'如果开启,第一页做为模板页,否则每一页独立设计,页数都由数据量生成',bursh:true,bind:true,list:null},
	
	{key:'face',text:'轮播类型',type:'select',init:'',target:'',tip:'切换箭头的显示时机',bursh:true,bind:true,list:options.toOptions('默认=,卡片=card')},	
	{key:'direction',text:'展示方向',type:'select',init:'horizontal',target:'',tip:'切换箭头的显示时机',bursh:true,bind:true,list:options.toOptions('水平=horizontal,垂直=vertical')}, 

	{key:'autoplay',text:'自动轮播',type:'switch',init:'Y',target:'',tip:'是否自动轮播',bursh:true,bind:true,list:null},	
	{key:'interval',text:'切换间隔',type:'number',init:3000,target:'',tip:'自动切换的时间间隔，单位为毫秒',bursh:true,bind:true,list:null},	
	{key:'loop',text:'循环播放',type:'switch',init:'Y',target:'',tip:'循环播放',bursh:true,bind:true,list:null},	
	{key:'pause',text:'悬停时暂停',type:'switch',init:'Y',target:'',tip:'悬停时暂停播放',bursh:true,bind:true,list:null},	 

	{key:'initIndex',text:'初始页序号',type:'number',init:0,target:'',tip:'初始从第几页开始,0为第一页',bursh:true,bind:true,list:null},	

	{key:'trigger',text:'指示器触发',type:'select',init:'hover',target:'',tip:'触发切换时的行为',bursh:true,bind:true,list:options.toOptions('鼠标悬停=hover,鼠标点击=click')},	
	{key:'indicator',text:'指示器位置',type:'select',init:'',target:'',tip:'指示器的位置',bursh:true,bind:true,list:options.toOptions('默认=,隐藏=none,外部=outside')},	
	{key:'arrow',text:'箭头显示',type:'select',init:'hover',target:'',tip:'切换箭头的显示时机',bursh:true,bind:true,list:options.toOptions('鼠标悬停=hover,始终显示=always,隐藏显示=never')},	

	{key:'width',text:'宽度',type:'text',init:'100%',target:'width',bursh:true,bind:true,tip:'象素、百分比、函数类型',list:null},	 
	{key:'minW',text:'最小宽度',type:'text',init:'0px',target:'minWidth',bursh:true,bind:true,tip:'象素、百分比、函数类型',list:null},
	{key:'height',text:'高度',type:'text',init:'100%',target:'height',tip:'象素、百分比、函数类型',bursh:true,bind:true,list:null},	
	{key:'minH',text:'最小高度',type:'text',init:'0px',target:'minHeight',tip:'象素、百分比、函数类型',bursh:true,bind:true,list:null},	

/* 
	{key:'overH',text:'水平滚动',type:'select',init:'hidden',target:'overflowX',tip:'容器内水平方向溢出时',bursh:true,bind:true,list:null},	
	{key:'overV',text:'垂直滚动',type:'select',init:'hidden',target:'overflowY',tip:'容器内垂直方向溢出时',bursh:true,bind:true,list:null},	
	 */
	
	
]
 
let carousel= {
	data:{
		type:'carousel',
	},
	delete:'',
	hide:'dataOption,indicator',//width,minW',display,flexD,flexW,overH,overV
	base:base ,
	special: [
	 {key:'dataOption',text:'轮播设置',type:'inputset',init:inputOption,target:'',bursh:true,bind:false,tip:'',list: null},
	  {key:'listData',text:'轮播数据',type:'jsonArray',init:initData,bursh:true,bind:true,tip:'',attribute:'dataOption',list:null},

		],

	action:[ 
		{key: '$comp_change',text: '轮播切换后',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '',list: null	},
		
	].concat( options.getEvents(null,'$hook',null)) //,$event_dblclick,$event_mouseenter,$hook,'$event_change,$event_input'
}

export default carousel