export default {
	data() {
		return {
			nodeMap: {},
			lineMap: {},
			pointMap: {},
			currentObj: null,
			currentNode: null, //被拖动的对象
			point: {
				x: 0,
				y: 0,
				offsetX: 0, //在元素内的偏移量
				offsetY: 0,
				startX: 0,
				startY: 0,
				nodeX: 0,
				nodeY: 0,
				width: 0, //元素的宽度，高度
				height: 0,
			},
			toolBar: {
				positionX: 5,
				positionY: 50,
				style: {
					left: '5px',
					top: '50px'
				}
			},
			drawMode: null,
			drawLine: {
				show: false,
				path: null
			},
			toolItems: [{
					key: 'select',
					label: '鼠标选择操作模式',
					icon: 'far fa-mouse-pointer',

				},
				{
					key: 'line',
					type: 'flowline',
					tip: '创建连线模式',
					icon: 'far fa-horizontal-rule rotate45'
				},
				{
					key: 'single',
					type: 'single',
					label: '单人',
					tip: '普通单签节点，任何一人完成即完成',
					icon: 'far fa-user',
					nodeIcon: 'fas fa-user',
					mode: 'task'
				},
				{
					key: 'multi',
					type: 'multi',
					label: '多人',
					tip: '会签节点,所有人全部完成才完成',
					icon: 'far fa-user-friends',
					nodeIcon: 'fas fa-user-friends',
					mode: 'task'
				},
				/* 				{
									key: 'group',
									type: 'group',
									label: '多组',
									tip: '多组会签节点，每组内至少有一个参与,所有人全部完成才完成',
									icon: 'far fa-users-class',
									nodeIcon: 'fas fa-users-class',
									mode: 'task'
								}, */
				{
					key: 'robot',
					type: 'robot',
					label: '机器人',
					tip: '机器人执行节点，系统交互对接操作，如写数据库、API调用等',
					icon: 'far fa-robot',
					nodeIcon: 'fas fa-robot',
					mode: 'task'
				},
				{
					key: 'subflow',
					type: 'subflow',
					label: '子流程',
					tip: '子流程节点，将创建并开启子流程,子流程完成后才完成此节点',
					icon: 'far fa-project-diagram',
					nodeIcon: 'fas fa-project-diagram',
					mode: 'task'
				},
				{
					key: 'merge',
					type: 'merge',
					label: '合并',
					tip: '多路径合并节点,一直等待所有分支都已到达时向后流转',
					icon: 'far fa-parking-circle',
					nodeIcon: 'fad fa-parking-circle',
					mode: 'auto',
					color: '#00BABD'
				},


				{
					key: 'router',
					type: 'router',
					label: '路由',
					tip: '路由节点,用于多分支线路的连接',
					icon: 'far fa-diamond',
					nodeIcon: 'fad fa-diamond',
					mode: 'auto',
					color: '#C71585'
				},
				{
					key: 'end',
					type: 'end',
					label: '结束',
					tip: '结束节点，一个流程可有多个结束节点，到达任何一个结束节点且没有活动任务时流程即完成',
					icon: 'far fa-stop-circle',
					nodeIcon: 'fad fa-stop-circle',
					mode: 'auto',
					color: '#FF4500'
				},
				{
					key: 'alignV',
					tip: '所选对象垂直方向,最顶端的中心点对齐',
					icon: 'far fa-align-center  fa-rotate-270',
					command: 'alignV'
				},
				{
					key: 'alignH',
					tip: '所选对象水平方向，最左侧中心点对齐',
					icon: 'far fa-align-center',
					command: 'alignH'
				},
				{
					key: 'delete',
					tip: '删除当前选择的元素',
					icon: 'far fa-times',
					command: 'delete'
				},
			],

		}
	},
	methods: {
		setMode(mode) {
			if (mode.command) {
				if (mode.command === 'alignV' || mode.command === 'alignH') {
					this.objsAlign(mode.command)
				} else if (mode.command === 'delete') {
					if (this.selectedObjs.length > 0) {
						this.deleteObj(this.selectedObjs)
						this.selectedObjs = []
					} else if (this.currentObj) {
						this.deleteObj([this.currentObj])
					} else {
						this.$logic.tip.error('请选择需要删除的目标')
					}
				}
			} else {
				this.drawMode = mode
			}

		},
		deleteObj(objs) {
			this.$logic.pop.confirm('确认', '是否确定删除当前：' + objs.length + '个对象', () => {
				for (let obj of objs) {
					if (obj.objClass === 'node') { //删除关联的线，删除母的同时删除节点
						this.deleteNode(obj)
					} else if (obj.objClass === 'line') {
						this.deleteLine(obj)
					} else if (obj.objClass === 'point') {
						this.deletePoint(obj, false)
					}

				}
			})
		},
		deleteNode(node) { //删除点前删除线
			if (node.nodeType === 'start') { //保留节点不允许删除
				node.selected = false
				this.$logic.tip.error('不允许删除开始节点')
				return
			}
			let lines = [].concat(this.model.lineList) //使用数组副本，防止循环迭代已删除的元素异常
			for (let line of lines) {
				if (node.id === line.fromId || node.id === line.toId) {
					this.deleteLine(line)
				}
			}
			let idx = this.model.items.indexOf(node)
			if (idx > -1) {
				this.model.items.splice(idx, 1)
			}
			delete this.nodeMap[node.id]


		},
		deleteLine(line) { //删除线之前删除点 
			for (let point of line.points) {
				this.deletePoint(point, true)
			}
			let idx = this.model.lineList.indexOf(line)

			if (idx > -1) {
				this.model.lineList.splice(idx, 1)
			}
			delete this.lineMap[line.id]


		},
		deletePoint(point, skipLine) { //删除点之前删除点关联的线中的点,skipLine是否从线个移除
			let line = this.lineMap[point.lineId]

			let idx = this.model.pointList.indexOf(point)

			if (idx > -1) {
				this.model.pointList.splice(idx, 1)
			}

			if (!skipLine && line) {
				idx = line.points.indexOf(point)
				if (idx > -1) {
					line.points.splice(idx, 1)
				}
				this.updateLinePath(line)
			}

			delete this.pointMap[point.id]
			/* 	for (let i = 0; i < line.points.length; i++) {
					let pt = line.points[i]
					if (pt === point) {
						

					}

				} */

		},
		objsAlign(type) {
			let param = type === 'alignV' ? 'positionY' : 'positionX' //以最左或最上为基准
			let styleKey = type === 'alignV' ? 'top' : 'left'
			let position = 1000000
			let nodes = []
			for (let obj of this.selectedObjs) { //获取基准值
				if (obj.objClass === 'point' || obj.objClass === 'node') { //只处理节点
					let react = this.$refs['node' + obj.id].getBoundingClientRect()
					let cx = obj.positionX + react.width / 2
					let cy = obj.positionY + react.height / 2
					let center = type === 'alignV' ? cy : cx
					if (position > center) {
						position = center
					}
					nodes.push({
						obj: obj,
						offsetX: react.width / 2,
						offsetY: react.height / 2
					})
				}
			}
			for (let item of nodes) {
				let location = type === 'alignV' ? position - item.offsetY : position - item.offsetX
				item.obj[param] = location
				item.obj.style[styleKey] = location + 'px'
			}
			for (let line of this.model.lineList) {
				this.updateLinePath(line)
			}



		},
		nodeMouseUp(node) {
			//画线
			if (!this.drawMode || this.drawMode.key !== 'line' || this.currentNode === node) {
				return
			}
			for (let line of this.model.lineList) {
				if (line.from === this.currentNode && line.to === node) {
					return
				}
			}
			let line = this.getLine(this.currentNode, node)
			this.lineMap[line.id] = line
			this.model.lineList.push(line)
			setTimeout(() => { //父容器的鼠标事件中会变更当前对象，在下一个时间切换
				this.objSelect(line)
			}, 100)


		},
		updateLinePath(line) { //重新计算线条路径 
			if (!line) {
				return
			}
			let fromNode = this.nodeMap[line.fromId]
			let toNode = this.nodeMap[line.toId]
			let obj1 = this.$refs['node' + fromNode.id]
			let obj2 = this.$refs['node' + toNode.id]
			let react1 = obj1.getBoundingClientRect()
			let react2 = obj2.getBoundingClientRect()
			let x1 = fromNode.positionX + react1.width / 2
			let y1 = fromNode.positionY + react1.height / 2
			let x2 = toNode.positionX + react2.width / 2
			let y2 = toNode.positionY + react2.height / 2
			let path = 'M ' + x1 + ',' + y1
			for (let point of line.points) {
				x1 = point.positionX + this.joinPointSize / 2
				y1 = point.positionY + this.joinPointSize / 2
				path = path + ' L ' + x1 + ',' + y1
			}
			let endPoint = this.getLineEndPoint(x1, y1, x2, y2, react2.width, react2.height, 15)
			path = path + ' L ' + endPoint.x + ',' + endPoint.y
			line.path = path
			return {
				x1,
				y1,
				x2,
				y2
			}
		},
		getLine(node1, node2) {

			let line = this.elementConfig.create('flowline', null, this.page.formData)
			line.parent = this.model
			line.fromId = node1.id
			line.toId = node2.id
			let react = this.updateLinePath(line)
			let label = line.label
			label.lineId = line.id
			label.positionX = (react.x1 + react.x2) / 2
			label.positionY = (react.y1 + react.y2) / 2
			label.style.left = label.positionX + 'px'
			label.style.top = label.positionY + 'px'

			return line


		},
		getLineEndPoint(x1, y1, x2, y2, width, height, arrowLength) { //目标对象宽度、高度

			//先算出直线角度，再计算角度区间，用于判断是竖边相交还是横边相交
			let x = x2 - x1
			let y = y2 - y1
			//let l=Math.sqrt(x*x + y*y) 
			let angle = Math.atan(y / x) //Math.asin(y/l)  

			let angleRect = Math.atan(height / width) //对角线角度 
			let angleMin = -angleRect //与竖线相交的最小角度
			let angleMax = angleRect //与竖线相交的最大角度
			//在此区间即是与竖线相交，采用宽度的一半用反算出斜边长度，再算箭头长度的偏移长度
			let w = 0,
				h = 0,
				l = 0
			if (angle > angleMin && angle < angleMax) {
				l = Math.abs(width / 2 / Math.cos(angle))
			} else {
				l = Math.abs(height / 2 / Math.sin(angle))
			}
			l = l + arrowLength
			x = Math.abs(Math.cos(angle) * l)
			y = Math.abs(Math.sin(angle) * l)

			x = x2 > x1 ? x2 - x : x2 + x
			y = y2 > y1 ? y2 - y : y2 + y

			return {
				x: x,
				y: y
			}

			//this.angle = angle / (Math.PI * 2) * 360



		},
		mouseUp() {
			if (!this.isedit) {
				return
			}
			this.currentNode = null //被拖动的对象
			this.drawLine.show = false
			if (this.dragBox.show) { //框选状态，将区域内对象收集进来
				let box = this.dragBox
				box.show = false
				if (this.selectedObjs.length > 0) {
					for (let obj of this.selectedObjs) { //先清除原有的
						obj.selected = false
						if (obj.objClass === 'point') {
							obj.style.opacity = 0
						}
					}
					this.selectedObjs = []
				}
				if (box.width > 1 && box.height > 1) { //有效的拖动才处理框选对象
					let right = box.left + box.width
					let bottom = box.top + box.height
					for (let node of this.model.items) {
						if (node.positionX >= box.left && node.positionY >= box.top && node.positionX < right && node
							.positionY < bottom) {
							node.selected = true
							this.selectedObjs.push(node)
						}
					}
					for (let point of this.model.pointList) {
						if (point.positionX >= box.left && point.positionY >= box.top && point.positionX < right &&
							point
							.positionY < bottom) {
							point.selected = true
							point.style.opacity = 1
							this.selectedObjs.push(point)

						}
					}
				}
			}

		},
		mouseMove(e) {
			if (!this.isedit) {
				return
			}
			if (this.dragBox.show) { //框选的情况下
				let box = this.dragBox
				let px = e.pageX
				box.width = e.pageX - box.x //左角位置加偏移量
				box.height = e.pageY - box.y
				if (box.width >= 0) { //换算顶点坐标
					box.left = box.x1
				} else {
					box.width = -box.width
					box.left = box.x1 - box.width
				}
				if (box.height >= 0) {
					box.top = box.y1
				} else {
					box.height = -box.height
					box.top = box.y1 - box.height

				}

				return
			}


			if (!this.currentNode) {
				return
			}

			let point = this.point
			let node = this.currentNode
			let offsetX = e.pageX - this.point.x
			let offsetY = e.pageY - this.point.y
			let x = this.point.nodeX + offsetX
			let y = this.point.nodeY + offsetY
			if (this.drawMode.key === 'select') {
				if (this.selectedObjs.length > 0) { //整体移动
					for (let obj of this.selectedObjs) {
						if (obj.objClass !== 'point' && obj.objClass !== 'node') {
							continue
						}
						let ox = obj.nodeX + offsetX
						let oy = obj.nodeY + offsetY
						obj.positionX = ox
						obj.positionY = oy
						obj.style.left = ox + 'px'
						obj.style.top = oy + 'px'
						if (obj.objClass === 'point') {
							this.updateLinePath(this.lineMap[obj.lineId])
						} else if (obj.objClass === 'node') {
							for (let line of this.model.lineList) { //如果是流程节点，更新相关联的所有线条
								if (line.fromId === obj.id || line.toId === obj.id) {
									this.updateLinePath(line)
								}
							}
						}
					}

				} else { //单个移动
					node.positionX = x >= 0 ? x : 0
					node.positionY = y >= 0 ? y : 0
					node.style.left = node.positionX + 'px'
					node.style.top = node.positionY + 'px'
					if (node.objClass === 'point') { //当前移动的是连接点
						this.updateLinePath(this.lineMap[node.lineId])
					} else { //移动的节点

						for (let line of this.model.lineList) { //如果是流程节点，更新相关联的所有线条
							if (line.fromId === node.id || line.toId === node.id) {

								//let temp = this.getLine(line.from, line.to)
								//line.path = temp.path
								this.updateLinePath(line)
							}
						}
					}
				}

			} else if (this.drawMode.key === 'line') { //连线操作
				let px = this.point.offsetX + offsetX // 线段长度减掉箭头开度
				let py = this.point.offsetY + offsetY
				let endPoint = this.getLineEndPoint(this.point.startX, this.point.startY, px, py, 1, 1, 15)
				px = endPoint.x
				py = endPoint.y
				this.drawLine.path = 'M ' + this.point.startX + ',' + this.point.startY + ' L ' + px + ',' + py


			}


		},
		dragStart(event, node) {
			if (!this.isedit) {
				return
			}
			this.point.x = event.pageX
			this.point.y = event.pageY
			this.point.offsetX = event.offsetX
			this.point.offsetY = event.offsetY
			this.point.nodeX = node.positionX
			this.point.nodeY = node.positionY
			node.nodeX = node.positionX //鼠标点击时的当前位置
			node.nodeY = node.positionY
			for (let obj of this.selectedObjs) {
				obj.nodeX = obj.positionX
				obj.nodeY = obj.positionY
			}

			this.currentNode = node
			if (node === this.toolBar) { //工具栏点击
				this.setMode(this.toolItems[0])
				return
			} else if (this.drawMode.key === 'select' && node.objClass === 'point') { //点击了边线上的连接点，把当前线条作为当前对象
				this.currentObj = node.line
			} else if (node.nodeMode && this.drawMode.key === 'line') { //连线模式
				let obj = this.$refs['node' + node.id]
				let react = obj.getBoundingClientRect()
				this.point.offsetX = this.point.offsetX + this.point.nodeX //鼠标相对于当前容器的位置
				this.point.offsetY = this.point.offsetY + this.point.nodeY
				this.point.startX = this.point.nodeX + react.width / 2
				this.point.startY = this.point.nodeY + react.height / 2
				this.point.width = react.width
				this.point.height = react.height
				this.drawLine.show = true
			}

		},
		initObject() {
			this.drawMode = this.toolItems[0]
			if (this.isedit && (!this.model.items || this.model.items.length < 1)) {
				this.model.items = []
				let obj = this.elementConfig.create('flownode', this.model, this.page.formData)
				this.nodeMap[obj.id] = obj
				obj.nodeType = 'start'
				obj.name = '开始'
				obj.nodeMode = 'task'
				obj.nodeIcon = 'fad fa-circle'
				obj.nodeFontColor = '#67C23A'
				obj.positionX = 100
				obj.positionY = 100
				obj.style.left = obj.positionX + 'px'
				obj.style.top = obj.positionY + 'px'
				obj.style.color = obj.nodeFontColor
				//this.model.nodeList.push(obj)
				
				//模拟点击工具栏创建一个结束节点
				let endItem=this.$logic.arrayUtil.itemByKey(this.toolItems,'key','end')
				this.setMode(endItem)				
				this.toCreate({offsetX:200,offsetY:200})
				this.mouseUp()
				this.setMode(this.$logic.arrayUtil.itemByKey(this.toolItems,'key','select'))
			}
			let nodeMap = this.nodeMap
			let lineMap = this.lineMap
			let pointMap = this.pointMap
			for (let node of this.model.items) {
				node.selected = false
				node.parent = this.model
				nodeMap[node.id] = node
			}
			for (let line of this.model.lineList) {
				line.selected = false
				line.parent = this.model
				if (!line.label) {
					line.label = {
						lineId: line.id,
						positionX: 0,
						positionY: 0,
						style: {}
					}
				}
				lineMap[line.id] = line
			}
			for (let point of this.model.pointList) {
				point.selected = false
				pointMap[point.id] = point
			}
			for (let line of this.model.lineList) {
				/* line.from = nodeMap[line.from.id]
				line.to = nodeMap[line.to.id] */
				let points = []
				for (let point of line.points) {
					let pointRef = pointMap[point.id]
					pointRef.style.opacity = 0
					points.push(pointRef)
				}
				line.points = points
			}
			/* for (let point of this.model.pointList) {
				point.line = lineMap[point.id]
			} */


		}



	},



}