<template>
	<div>
★bug:弹出对话框，底部默认按钮(确定\取消）都关闭显示后还占个高度，希望不占高度
★bug:组件“颜色输入框”的输入提示“点击选中图标”不对
★bug:表单容器，日期组件，无默认值，提交后（发送的sql参数是''），表里保存的是1900-01-01。期望是null
★bug：通用报表getList, setList给打印容器里的报表2后，报表1里的自定义列里内容消失不见了！
★弹出模态对话框能否open加个关闭后回调函数参数，可接受close()里返回的值。以便做一些处理（如保存并新增，总不能每保存一条都刷新主页面数据吧，想着在open回调里刷新一次即可）。
★折叠报表，开启“数据汇总”后，父节点数值为0，也多出来“父分类\*”的数据行。能否为null或0的父节点都不要显示*这一行
★当前登录用户信息，目前事件里action.system.user能获取到! 期望：page页面对象里也能取到，如page.system.user.id

●表单域的“标签区”现在没有任何事件！希望加个”鼠标单击“事件，或许有用呢
●希望”通用报表“或“子表编辑器”提供一个，显示/隐藏指定列的方法
●bug： 通用报表的sql里有[dbo].表名   时报错
●能否引入第三方js库，这样海量资源可用了
●用户编辑界面，希望增加几个自定义项，如udf1,2,3...，在 action.system.user.udf1或page.system.user.udf1可取到，以进行跟用户有关的判断

	</div>
</template>

<script>

	export default {
		data() {
			return {

			}
		},

		methods: {

		},
		created() {


		}
	}
</script>

<style scoped>

</style>