<template>
	<div class="img-bar" :style="barStyle" @click="model.tipShow='N'">
		<div v-for="(item,index) in model.imgList" :class="model.showName==='Y'?'img-item img-info' : 'img-item'"
			:title="item.name">
			<template v-if="model.showName==='Y'">
				<el-image :src="item.url" :zoom-rate="1.2" :max-scale="7" :min-scale="0.2" :preview-src-list="srcList"
					:initial-index="index" :fit="model.fit" :style="imgStyle" />
				<div style="overflow: hidden;text-align: center;height: 26px;">

					<input v-if="editMode" type="text" v-model="item.name" style="width:calc(100% - 10px);"
						class="input-text" />
					<span v-else>{{item.name}}</span>
				</div>
			</template>
			<!-- baseUrl+item.url -->
			<el-image v-else :src="item.url" :zoom-rate="1.2" :max-scale="7" :min-scale="0.2"
				:preview-src-list="srcList" :initial-index="index" :fit="model.fit" :style="imgStyle" />

			<i v-if="editMode" @click="deleteItem(index)" class="fas fa-minus-circle icon-button">

			</i>
		</div>

		<form ref="fileForm" style="display: none;">
			<input type="file" ref="fileCamera" @change="handleImageChange('fileCamera',$event)" accept="image/*"
				capture="camera" style="display: none;">
			<input type="file" ref="fileSelect" @change="handleImageChange('fileSelect',$event)" accept="image/*"
				style="display: none;">
		</form>
		<div class="img-item img-tool linear" v-if="!editMode && (isedit || model.status !='disabled')"
			@click="toEdit()">
			<i class="far fa-plus"></i>
			<!-- <div>编辑</div> -->

		</div>
		<template v-if="model.allowMulti==='Y' || (model.imgList && model.imgList.length<1)">
			<div class="img-item img-tool linear" v-if=" editMode && model.allowCamera==='Y'"
				@click="$refs.fileCamera.click()" title="拍照上传,只支持移动端">
				<i class="far fa-camera"></i>
				<!-- 	<div>拍照</div> -->
			</div>
			<div class="img-item img-tool linear" v-if="editMode && model.allowSelect==='Y'"
				@click="$refs.fileSelect.click()" title="选取本地图片">
				<i class="far fa-folder-open"></i>
				<!-- <div>上传</div> -->
			</div>
		</template>

		<div class="img-item img-tool linear" v-if="editMode " @click="editMode=false" title="退出操作">
			<i class="far fa-reply"></i>
			<!-- <div>取消</div> -->
		</div>

		<!-- 	<div>
			<video ref="video" autoplay></video>
			<button @click="startCamera">启动摄像头</button>
		</div>
 -->

	</div>
</template>

<script>
	import formBase from '../../../formbase.js'
	import CommEvent from '../../../commevent.js'
	import logicUtil from '../../../../../plugins/logicutil.js'
	export default {
		mixins: [formBase, CommEvent],
		data() {
			return {
				editMode: false,
				baseUrl: '',
				deleteItems: []


			}
		},

		methods: {
			/* async startCamera() {
				try {
					const stream = await navigator.mediaDevices.getUserMedia({
						video: {
							facingMode: "environment"
						},
						audio: false
					});
					this.$refs.video.srcObject = stream;
				} catch (error) {
					console.error('无法访问摄像头', error);
				}
			}, */

			clear() {
				this.setData('imgList', [])
			},
			toCamera() {
				this.$refs.fileCamera.click()
			},
			toPick() {
				this.$refs.fileSelect.click()
			},
			toEdit() {
				if (this.isedit) {
					return
				}
				this.editMode = true
			},
			deleteItem(index) {
				let item = this.model.imgList.splice(index, 1)[0]
				if (item.url.indexOf('/temp/') < 0) { // 非临时文件,服务器删除文件
					this.deleteItems.push(item)
				}
			},
			handleImageChange(obj, event) {
				const fileItem = event.target.files[0]; // 获取用户选择的文件  
				if (!fileItem) {
					return
				}
				this.$refs.fileForm.reset() //清除文件上传的内容
				/* 	if (file) {
					// 在这里，你可以预览图片、上传到服务器等  
					console.log(file); // 打印文件信息到控制台  

					// 示例：创建一个FileReader来预览图片  
					const reader = new FileReader();
					reader.onload = (e) => {
						const img = document.createElement('img');
						img.src = e.target.result;
						// 你可以将img添加到DOM中，或者进行其他处理  
						document.body.appendChild(img); // 示例：将图片添加到body中  
					};
					reader.readAsDataURL(file);
 */
				let srcName = fileItem.name
				let x = srcName.indexOf('.')
				if (x < 1) {
					this.$logic.tip.error('未知的文件类型,请上传图片类型文件')
					return
				}
				let filePath = this.model.fixDir || '' //指定上传文件保存的服务器路径，在当前应用实例下创建此文件夹保存,一般需配套文件名指定使用
				let serverPath = this.model.shortUrl === 'Y' ? '' : this.$logic.http.baseUrl //url是否保存相对路径，否则为绝对路径 
				filePath = filePath.trim()
				if (this.model.shortUrl != 'Y' && !filePath) {
					this.$logic.tip.error('已设置为绝对URL的地址，必须指定文件上传的指定目录')
					return
				}
				let before = this.doEvent({
					eventName: 'uploadBefore',
					fileItem: fileItem,
					namePart1: srcName.substring(0, x),
					namePart2: srcName.substring(x)
				}, '$comp_uploadBefore')
				if (before === false) {
					return
				}
				let fileName = ''
				if (before && typeof(before) === 'string') { //指定文件名,否则服务器自动创建随机文件名
					fileName = before
				}
				const formData = new FormData();
				formData.append('file', fileItem); // 'file'是后端期望的字段名

				this.$logic.http.post(true, '/api/editor/imgupload/', {
					appId: this.project ? this.project.id : 0,
					groupName: '',
					quality: this.model.quality || 0,
					maxWidth: this.model.maxWidth || 0,
					fileName: fileName,
					filePath: filePath,
					//serverPath: serverPath //组成完整图片地址的服务器前缀
					/* 			reList: false,
								fileHome: '', //作为临时文件上传
								folderPath: '/',
								saveName: '', */
				}, formData, res => {

					let resItem = res.data.data[0]

					if (this.model.files && this.model.files.length > 0) {
						this.model.files = this.model.files + ',' + resItem.url
					}
					let imgItem = {
						name: resItem.fileName,
						url: serverPath + resItem.url

					}
					//	let infoItems = this.model.imgList
					let list = this.model.imgList.concat([imgItem])
					this.setData('imgList', list)
					//	this.model.imgList.push(imgItem)
					this.doEvent({
						eventName: 'uploadAfter',
						fileItem: fileItem
					}, '$comp_uploadAfter')

				})

				//console.log(file, obj)
			},
			$value(value) {
				let model = this.model
				if (typeof(value) === 'undefined') { //读取值	

					return this.model.imgList
				} else {
					if (value) {
						//初始化列表数据转换成json数据
						if (typeof(value) === 'string') {
							try {
								let list = JSON.parse(value)
								//this.model.imgList = list
								this.setData('imgList', list)
							} catch (ex) {
								this.$logic.tip.error('错误的的赋值：' + value)
							}

						} else if (Array.isArray(value)) {
							//this.model.imgList = []
							this.setData('imgList', [])
						} else {
							this.$logic.tip.error('非法的赋值')
						}

					} else {
						//this.model.imgList = []
						this.setData('imgList', [])
					}

				}
			},
			$deleteList() {
				return this.deleteItems
			}
		},
		watch: {
			/* 'model.imgList'(newValue, oldValue) {
				console.log('Changed')
				this.$value(newValue)
			} */
		},
		computed: {

			imgStyle() {
				let css = {
					width: '100%',
					height: '100%'
				}
				if (this.model.showName === 'Y') {
					css.height = 'calc(100% - 22px)'
				}
				return css
			},
			srcList() {
				let list = []
				for (let item of this.model.imgList) {
					list.push(item.url)
				}
				return list

			},
			barStyle() {
				let css = this.compStyle
				if (this.model.showBox == 'Y') {
					css['--bar-box'] = '1px'
				} else {
					css['--bar-box'] = '0px'
				}
				/* 	css.minHeight = 'calc('
					this.model.imgHeight + ' + 10px)' */
				return css
			}
		},
		created() {
			this.model.$value = this.$value
			this.model.$deleteList = this.$deleteList
			this.model.$camera = this.toCamera
			this.model.$pick = this.toPick
			this.model.$clear = this.clear
			this.model.$validate = this.validate
			this.model.imgList = []
			this.model.imgUrl = ''
			this.baseUrl = this.$logic.http.baseUrl

		}
	}
</script>

<style scoped>
	.img-bar {
		border: solid var(--bar-box) #cccccc;
		border-radius: 5px;
		/* padding: 5px; */
		overflow: auto;
		display: flex;
		flex-wrap: wrap;
		min-width: 20px;
		min-height: calc(var(--img-height) + 15px);

	}

	.img-item {
		position: relative;
		width: var(--img-width);
		height: var(--img-height);
		border: solid 1px #cccccc;
		border-radius: 5px;
		margin: 4px;
		/* 	padding: 4px; */

	}

	.img-info {
		align-items: space-between;
	}

	.icon-button {
		position: absolute;
		top: 0px;
		right: 2px;
		cursor: pointer;
		font-size: 18px;
		color: #FF4500;
	}

	.icon-button:hover {
		font-size: 20px;
	}


	.img-tool {
		border-radius: 5px;
		border: solid 1px #cccccc;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		cursor: pointer;

		background-color: #f8f8f8;
	}

	.img-tool i {
		font-size: 20px;
	}

	.img-tool:hover {
		border: solid 1px #1E90FF;
	}
</style>