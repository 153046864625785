<template>

	<inputsuggest v-model="inputValue" :options="options" @query="onQuery" @choose="onChoose" @input="onInput"
		@change="onChange" @clear="onClear" @blur="onBlur" @focus="onFocus" @keydown="onKeyDown" @keyup="onKeyUp"
		@keypress="onKeyPress" :table-menu="model.inputOption.load.tableMenu==='Y'"
		:menu-width="model.inputOption.load.menuWidth" :menu-height="model.inputOption.load.menuHeight"
		:table-style="model.inputOption.load.tableStyle" :placeholder="model.tip" :size="model.size"
		:icon-ih="model.iconIH" :icon-it="model.iconIT" :icon-oh="model.iconOH" :text-oh="model.textOH"
		:icon-ot="model.iconOT" :text-ot="model.textOT" :disabled="model.status=='disabled'" :status="model.status"
		:clearable="model.clearable=='Y'" />


</template>

<script>
	//import InputSuggest from '../../../../comm/input/suggest/InputSuggest.vue'
	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase],
		data() {
			return {
				inputValue: '',

				options: [],
				tableStyle: null

			}
		},
		methods: {
			onClear() {
				let item = {
					id: null,
					label: null
				}
				this.onChoose(item)
				//console.log(this.model.idValue, this.model.labelValue)
				/* this.setData('value', item)
				this.model.value = item
				this.textValue = item
				this.onChange(item, 'value') */
			},
			onChoose(item) {
				this.setData('idValue', item.id)
				this.setData('labelValue', item.label)
				this.doEvent({
					eventName: 'choose',
					item: item
				}, '$comp_choose')
			},
			onQuery(keyWord) {
				let query = this.getSql(keyWord)
				let items = []
				if (!this.isedit && query && query.sql) {
					let sql = this.$logic.db.getParamSql(query.sql, this.page.$params || {}, this.page)
					//console.log(sql)
					this.options = null
					let maxSize = this.model.inputOption.load.maxSize > 0 ? this.model.inputOption.load.maxSize > 100 ?
						100 : this.model.inputOption.load.maxSize :
						20

					this.$logic.http.sqlQuery(query.sourceKey, sql, query.params || [], {
						isJson: true,
						size: maxSize
					}).then(res => {


						for (let item of res.data.data.dataList) {
							items.push({

								label: item.label ? item.label.replace(keyWord,
									'<span style="color:#F56C6C;">' + keyWord + '</span>') : '无label',
								data: item
							})
						}
						/* items.push({
							label: keyWord,
							data: {}
						}) */
						this.options = items
					}).catch(err => {
						this.options = []
						this.$logic.tip.error('数据请求异常：' + err.info)
					})

				} else { //本地数据筛选
					let list = this.dataList || []
					for (let item of list) {
						if (item.label && item.label.indexOf(keyWord) > -1) {
							let data = {
								...item
							}
							data.label = data.label.replace(keyWord, '<span style="color:#F56C6C;">' + keyWord + '</span>')
							items.push(data)
						}
					}
					this.options = items
				}
			},
			getSql(keyWord) {

				let query = null
				try {
					let obj = null //支持json格式或字符串格式
					let newSql = this.doEvent({
						eventName: 'querybefore',
						word: keyWord
					}, '$comp_queryBefore')
					//如果有函数返回的有效查询对象，以返回值为准
					if (newSql) {
						if (typeof(newSql) === 'string') {
							obj = {
								sql: newSql,
								params: []
							}
						} else if (typeof(newSql) === 'object' && newSql.sql && newSql.params) {
							obj = newSql
						}

					} else {
						let sql = this.model.inputOption.load.sql
						obj = {
							sql: sql,
							params: []
						}

					}
					obj.sql = obj.sql.replace(/\r\n/g, '').replace(/\n/g, '').replace(/\$keyword/g, keyWord)


					if (!obj.params) {
						obj.params = []
					}
					for (let i = 0; i < obj.params.length; i++) {
						let item = obj.params[i]
						if (typeof(item) === 'string') {
							obj.params[i] = item.replace('$keyword', keyWord)
						}
					}
					query = obj
					query.sourceKey = this.model.inputOption.sourceKey ? this.model.inputOption.sourceKey : this.project
						.dataSource.sourceKey

				} catch (e) {
					this.$logic.tip.error('获取SQL失败，请确认格式正确')
				}

				return query
				/* return {
					appId: this.project.id,
					sql: "select * from treetest where label like ?",
					params: ['%'+keyWord+'%']
				} */
			},
			$value(value) {
				let model = this.model
				if (typeof(value) === 'undefined') { //读取值					 
					return {
						id: model.idValue,
						label: model.labelValue
					}
				} else {
					if (value) {
						if ('id' in value && 'label' in value) {
							model.idValue = value.id
							model.labelValue = value.label
						} else {
							this.$logic.tip.error('组件：' + model.name + '赋值无效，错误的数据格式')
						}

					} else {
						this.dataInit()
					}

				}
			},
			/* 	setTableStyle() {
					try {
						let cols = JSON.parse(this.model.tableHead)
						this.tableStyle = {
							heads: cols
						}
					} catch (err) {
						this.tableStyle = null
						this.$logic.tip.error('选项表格样式的设置数据格式错误，请使用标准的Json格式')
					}
				}, */
			/* 	dataInit() {
					this.model.labelValue = ''
					this.inputValue = this.model.labelValue
					this.model.idValue = null
				} */

		},
		watch: {
			'model.labelValue': {
				handler: function(nv, ov) {
					this.inputValue = nv
				}
			},

		},
		computed: {

		},
		created() {
			if (!this.isedit) {
				this.inputValue = this.model.labelValue
				this.model.$value = this.$value
				this.model.$validate = this.validate
				//this.model.$init = this.dataInit
				//	this.setTableStyle()
			}
		},
		components: {
			//inputsuggest: InputSuggest
		},
	}
</script>


<style scoped>

</style>