export default {
	methods: {

		async queryData() {

			let model = this.model
			let check = await this.doEvent({
				eventName: 'requestBefor',
				type: 'API',
				url: model.timerUrl,
				sql: model.timerSql
			}, '$timer_queryBefor')
			if (check === false) {
				return
			}

			if (model.timerUrl && model.timerUrl.length > 10) {

				await this.$logic.http.get(model.timerLayer == 'Y',
					model.timerUrl, {}, res => {
						this.flushData({ //调用公共数据更新函数，具体组件自行覆盖方法,create事件中为model设置$flushData方法
							eventName: 'requestAfter',
							type: 'API',
							result: res.data.data
						}, '$timer_queryAfter')


						//this.dataSet = new Date().getTime()

					}, err => {
						this.flushData({
							eventName: 'requestError',
							type: 'API',
							result: null,
							error: {
								code: err.code,
								msg: err.info
							}
						}, '$timer_queryError')
					})
			} else if (model.dataOption && model.dataOption.jsonArray.load === 'Y' && model.dataOption.jsonArray
				.sql && model.dataOption.jsonArray.sql.length > 10
				) { //(model.timerSql && model.timerSql.length > 10) {
				let option = {
					size: model.timerSqlLimit || 10000,
					pageNo: 0,
					isJson: model.timerSqlFormat == 'N',
					showLayer: model.timerLayer == 'Y'
				}
				let sql = this.$logic.db.getParamSql(model.dataOption.jsonArray.sql, this.page.$params || {}, this
					.page)
				let sourceKey = model.dataOption.sourceKey ? model.dataOption.sourceKey : this.project.dataSource
					.sourceKey

				await this.$logic.http.sqlQuery(sourceKey,
					sql, [], option).then(
					res => {
						this.flushData({
							eventName: 'requestAfter',
							type: 'Sql',
							result: res.data.data
						}, '$timer_queryAfter')
					}, err => {
						this.flushData({
							eventName: 'requestError',
							type: 'Sql',
							result: null,
							error: {
								code: err.code,
								msg: err.info
							}
						}, '$timer_queryError')

					})
			} else { //没有可指定的

			}


		},
		///////////////////////定时任务
		async timeTask() { //

			let model = this.model
			let check = true
			try {
				check = await this.doEvent({
					eventName: 'timerbefor'
				}, '$timer_befor')
				if (check !== false) {
					await this.queryData() //至少执行一次
					this.doEvent({
						eventName: 'timerafter'
					}, '$timer_after')
				}
			} catch (ex) {
				this.$logic.tip.error('自动加载数据异常：' + ex)
			}

			if (!this.isedit && model.loadAllow === 'Y' && model.timerSplit > 0) { //未开始或未设置周期退出,设计模式只运行一次
				this.timer = setTimeout(() => {

					this.timeTask()

				}, model.timerSplit * 1000)
			}
		}

	},
	watch: {
		'model.loadAllow': {
			handler: function(newV, oldV) {
				if (!this.isedit && newV === 'Y') { //如果重新设置了启用定时器执行定时任务
					this.timeTask()
				}

			}

		},
	},
}