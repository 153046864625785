import options from '../../public/options.js'
import reportHead from './reportcol.js'
import compBase from '../../public/comp.js'

let heads=[]
for(let i=1;i<6;i++){
	let item={}
	for(let cfg of reportHead.base){
		if(cfg.type){//排除掉标题
		item[cfg.key]=cfg.init	
		}		
	}
	item.type='reportcol'
	item.show='show'
	item.label=item.label+i
	item.name=item.label
	heads.push(item)
}
let dataSet=options.getDataSet(heads)  //{dataSourceKey:'',sql:'',headItems:heads,queryItems:[],enumItems:[],rangItems:[],rangUse:false,rangField:null,orderItems:[],tick:0}

let list=[
	{key:'dataSet',text:'查询选项',type:'report',init:dataSet,target:'',bursh:true,bind:true,tip:'',list: null},
	{key:'autoQuery',text:'自动查询',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'加载后自动查询，否则需手工点击查询按钮执行查询',list: options.YON},
	{key:'customSet',text:'自定义模式',type:'switch',init:'N',target:'',bursh:true,bind:true,tip:'',list: options.YON},
	{key:'allowCheck',text:'开启选择 ',type:'select',init:'N',target:'',bursh:true,bind:true,tip:'',list: options.toOptions('不启用=N,单选模式=S,多选模式=M')},//是否启用列表数据选择功能,如果开启了任何一个操作功能，自动开启选择功能

	{key:'allowFold',text:'开启折叠',type:'switch',init:'N',target:'',bursh:true,bind:true,tip:'',list: options.YON},

	{key:'headShow',text:'显示标题栏',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list: options.YON},

	{key:'headCustom',text:'定制标题栏',type:'switch',init:'N',target:'',bursh:true,bind:true,tip:'',list: options.YON},
	{key:'allowQuery',text:'允许查询',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list: options.YON},
	{key:'queryItemWidth',text:'查询项宽度',type:'text',init:'100px',target:'',bursh:true,bind:true,tip:'文本框等输入元素的宽度，日期时间输入框不受此影响',list: options.YON},
	{key:'allowOrder',text:'允许排序',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list: options.YON},


	{key:'advanceQuery',text:'可高级查询',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list: options.YON},
	{key:'pageDataCount',text:'当前页数量',type:'number',init:0,target:'',bursh:true,bind:true,read:true,tip:'当前页的数据个数，只读数据，可用于配合其它组件进行逻辑绑定',list: options.YON},

	{key:'allowPage',text:'允许分页',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list: options.YON},
	{key:'pageType',text:'分页类型',type:'select',init:'num',target:'',bursh:true,bind:true,tip:'数据量大的建议上下翻页，页码翻页需统计结果总数消耗性能',list: options.toOptions('上下翻页=next,页码翻页=num')},
	{key:'pageSize',text:'每页大小',type:'number',init:10,target:'',bursh:true,bind:true,tip:'0表示默认为上限10000,请谨慎使用，以防数量过大时消耗性能',list: options.YON},
	{key:'pageSmall',text:'小号页码',type:'switch',init:'N',target:'',bursh:true,bind:true,tip:'页码翻页类型时生效',list: options.YON},
	{key:'pageBackground',text:'块状页码',type:'switch',init:'N',target:'',bursh:true,bind:true,tip:'页码翻页类型时生效',list: options.YON},
	{key:'pageAlign',text:'分页位置',type:'select',init:'start',target:'--table-pagebar-align',bursh:true,bind:true,tip:'',list: options.toOptions('左=start,中=center,右=end')},

	{key:'colMinWidth',text:'列最小宽度',type:'text',init:'100px',target:'--col-min-width',bursh:true,bind:true,tip:'各数据列在此最小宽度的基础上自动拉伸,如需固定宽度请在列属性中设置列宽度',list: null},

	{key:'showNum',text:'显示序号',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list: options.YON},
	{key:'indexWidth',text:'序号列宽度',type:'text',init:'50px',target:'--index-width',bursh:true,bind:true,tip:'',list: null},

	{key:'width',text:'宽度',type:'text',init:'calc(100% - 2px)',target:'width',bursh:true,bind:true,tip:'',list: null},
	{key:'height',text:'高度',type:'text',init:'',target:'height',bursh:true,bind:true,tip:'',list: null},

	{key:'headHeight',text:'表头行高',type:'text',init:'40px',target:'--head-height',bursh:true,bind:true,tip:'',list: null},
	{key:'rowHeight',text:'表体行高',type:'text',init:'30px',target:'--row-height',bursh:true,bind:true,tip:'',list: null},
	{key:'rowPaddingTop',text:'格内上边距',type:'text',init:'5px',target:'--row-padding-top',bursh:true,bind:true,tip:'',list: null},
	{key:'rowPaddingBottom',text:'格内下边距',type:'text',init:'5px',target:'--row-padding-bottom',bursh:true,bind:true,tip:'',list: null},
	{key:'rowPaddingLeft',text:'格内左边距',type:'text',init:'5px',target:'--row-padding-left',bursh:true,bind:true,tip:'',list: null},
	{key:'rowPaddingRight',text:'格内右边距',type:'text',init:'5px',target:'--row-padding-right',bursh:true,bind:true,tip:'',list: null},
	{key:'lineShow',text:'显示网格线',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list: options.YON},
	{key:'tableLineColor',text:'边框线颜色',type:'colorA',init:'#cccccc',target:'--table-line-color',bursh:true,bind:true,tip:'',list: options.YON},
	{key:'headBgColor',text:'标题背景色',type:'colorA',init:'#e7e7e7',target:'--title-back-color',bursh:true,bind:true,tip:'',list: options.YON},
	{key:'headTextColor',text:'标题文本色',type:'color',init:'#303133',target:'--title-text-color',bursh:true,bind:true,tip:'',list: options.YON},
	{key:'textColor',text:'表格文本色',type:'color',init:'#303133',target:'--table-text-color',bursh:true,bind:true,tip:'',list: options.YON},

	{key:'splitColor',text:'斑马线颜色',type:'colorA',init:'#f8f8f8',target:'--row-split-color',bursh:true,bind:true,tip:'',list: options.YON},
	{key:'hoverColor',text:'悬浮背景色',type:'colorA',init:'#ffff8f',target:'--row-hover-color',bursh:true,bind:true,tip:'',list: options.YON},

	{key:'clickColor',text:'点击背景色',type:'colorA',init:null,target:'',bursh:true,bind:true,tip:'数据背景色优先于勾选背景色，勾选背景色优先于点击背景色',list: options.YON},
	{key:'checkColor',text:'勾选背景色',type:'colorA',init:'#d8ecff',target:'',bursh:true,bind:true,tip:'数据背景色优先于勾选背景色，勾选背景色优先于点击背景色',list: options.YON},
 

//	{key:'tabletdP',text:'内间距',type:'text',init:'10px',target:'',bursh:true,bind:true,tip:'',list: null},
////	{key:'tabletdV',text:'格内纵向',type:'select',init:'middle',target:'',bursh:true,bind:true,tip:'',list: options.TMB},
//	{key:'lineSize',text:'网格线粗',type:'number',init:'1',target:'',bursh:true,bind:true,tip:'',list: null},
//	{key:'lineColl',text:'线条折叠',type:'select',init:'collapse',target:'border-collapse',bursh:true,bind:true,tip:'',list: []},
//	{key:'queryItems',text:'查询条件',type:'json',init:heads,target:'',bursh:true,bind:true,tip:'',list:null},
//	{key:'overFlow',text:'滚动条',type:'text',init:'auto',target:'overflow',bursh:true,bind:true,tip:'',list:null},

	{key:'headConfig',text:'表头配置',type:'json',init:null,target:'',bursh:true,bind:true,tip:'',list:null},
	
] 
let buttons =JSON.stringify( [
	//['文本','图标', '样式','状态' ], 
	{key:'query',text:'查询', icon:'far fa-search',type:'primary',enabled:'Y',loading:'N'},
	{key:'super',text:'高级', icon: 'far fa-search-plus',type: 'success',enabled:'Y',loading:'N' },
	{key:'head',text:'列表', icon: 'far fa-list',type: 'primary',enabled:'Y',loading:'N' },
	{key:'order',text:'排序', icon: 'far fa-sort-amount-up-alt',type: 'primary' ,enabled:'Y',loading:'N'},
	
	{key:'add',text:'创建', icon:'far fa-plus',type:'success',enabled:'Y',loading:'N'},
	{key:'view',text:'查看', icon: 'far fa-file-search',type: 'default',enabled:'Y',loading:'N' },
	{key:'edit',text:'编辑', icon: 'far fa-edit',type: 'primary',enabled:'Y',loading:'N' },
	{key:'del',text:'删除', icon: 'far fa-times',type: 'danger' ,enabled:'Y',loading:'N'},
	{key:'excel',text:'导出', icon: 'far fa-file-excel',type: 'success' ,enabled:'Y',loading:'N'},
	{key:'upload',text:'自定义', icon: 'far fa-upload', type:'info',enabled:'Y',loading:'N'  },
])
let inputOption=options.getInputOption()
let idConfig=compBase.getDataConfig()
inputOption.jsonArray.heads=[
	{key:'key',label:'按钮标识',type:'text',default:'',list:[],field:'',width:'16%',unique:true},
	{key:'text',label:'按钮文本',type:'text',default:'',list:[],field:'',width:'16%',unique:false},
	{key:'icon',label:'按钮图标',type:'icon',default:'',list:[],field:'',width:'16%',unique:false},
	{key:'type',label:'按钮样式',type:'select',default:'',list:compBase.colorTypes,field:'',width:'16%',unique:false},
	{key:'enabled',label:'可用状态',type:'switch',default:'Y',list:[],field:'',width:'16%',unique:false},
	{key:'loading',label:'加载状态',type:'switch',default:'N',list:[],field:'',width:'16%',unique:false},
]

let special=[
 
	{key:'title',text:'内置功能按钮'},
	{key:'queryButton',text:'启用查询',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'内置功能key值为query的按钮',list: options.YON},
	{key:'superButton',text:'启用高级',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'内置功能key值为super的按钮',list: options.YON},
	{key:'headButton',text:'启用列表',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'内置功能key值为head的按钮',list: options.YON},
	{key:'orderButton',text:'启用排序',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'内置功能key值为order的按钮',list: options.YON},
	
	{key:'addButton',text:'自动创建',type:'switch',init:'N',target:'',bursh:true,bind:true,tip:'开启后点击key值为add的扩展按钮将新建数据',list: options.YON},
	{key:'viewButton',text:'自动查看',type:'switch',init:'N',target:'',bursh:true,bind:true,tip:'开启后点击key值为view的扩展按钮将打开选中的第一行',list: options.YON},
	{key:'editButton',text:'自动修改',type:'switch',init:'N',target:'',bursh:true,bind:true,tip:'开启后点击key值为edit的扩展按钮将修改选中的第一行',list: options.YON},
	{key:'delButton',text:'自动删除',type:'switch',init:'N',target:'',bursh:true,bind:true,tip:'开启后点击key值为del的扩展按钮将删除选中的多行',list: options.YON},

	{key:'excelButton',text:'自动导出',type:'switch',init:'N',target:'',bursh:true,bind:true,tip:'开启后点击key值为exel的扩展按钮将导出当前查询的结果数据到EXCEL',list: options.YON},
	{key:'excelSize',text:'导出量阀值',type:'number',init:65000,target:'',bursh:true,bind:true,tip:'限定最大允许导入的记录总数，以防数量过大时消耗性能',list: options.YON},
	
	//{key:'title',text:'自动数据编辑'},
	{key:'allowButton',text:'按钮扩展',type:'switch',init:'N',target:'',bursh:true,bind:true,tip:'是否附加自定义按钮',list: options.YON},
	 

	{key:'foldButton',text:'折叠按钮',type:'switch',init:'N',target:'',bursh:true,bind:true,tip:'按钮数量两个以上时，折叠以菜单方式显示',list: options.YON},
	
	{key:'pageKey',text:'编辑页面',type:'page',init:'',target:'',bursh:true,bind:true,tip:'如果开启增加、删除、修改、查看的任何功能，必须设定数据编辑的表单页面',list: null},

	{key:'editHeight',text:'编辑框高度',type:'text',init:'80vh',target:'',bursh:true,bind:true,tip:'',list: null},
	{key:'editWidth',text:'编辑框宽度',type:'text',init:'90vw',target:'',bursh:true,bind:true,tip:'',list: null},
	{key:'dblClick',text:'数据行双击',type:'select',init:'none',target:'',bursh:true,bind:true,tip:'',list: options.toOptions('无动作=none,开启查看=view,开启编辑=edit,执行删除=delete,开启行编辑=rowedit')},
	
	{key:'confirmInfo',text:'提交前提示',type:'text',init:'是否确定提交',target:'',bursh:true,bind:true,tip:'如果不设置提示信息将直接提交表单',list: null},
	{key:'cancelInfo',text:'取消前提示',type:'text',init:'是否确定取消',target:'',bursh:true,bind:true,tip:'如果不设置提示信息将直接退出编辑',list: null},
	{key:'deleteInfo',text:'删除前提示',type:'text',init:'是否确定删除',target:'',bursh:true,bind:true,tip:'如果不设置提示信息将直接删除数据',list: null},

    {key:'title',text:'表格行内数据编辑'},
	{key:'rowEdit',text:'开启行编辑',type:'switch',init:'N',target:'',bursh:true,bind:true,tip:'开启此选项后，调用编辑页面的功能将失效，改为表格行内直接编辑,单元格内元素可绑定内置数据$edit(true/false)进行组件定制',list: options.YON},	
    {key:'dbTable',text:'数据库表名',type:'table',init:'',target:'',bursh:true,bind:false,tip:'指定保存到数据库的表名',list: null},
    {key: 'idField',text: 'Id字段',type: 'field',init: idConfig,	target: '',	bursh: true,bind: false,tip: '指定保存到数据表的ID字段名',host:'dbTable'},
	
	
	{key:'title',text:'自定义按钮'},
	{key:'dataOption',text:'选项设置',type:'inputset',init:inputOption,target:'',bursh:true,bind:false,tip:'',list: null},
	{key: 'buttons',text: '自定义按钮',type: 'jsonArray',init: buttons,	target: '',bursh: true,bind: true,tip: '',attribute:'dataOption'},// ,treeMap:{id:'key',label:'text',icon:'icon'},dataMap: {key:'唯一标识',text:'按钮文本',icon:'图标',type:'外观样式',enabeld:'可用状态',loading:'加载状态'}


	//{key:'buttons',text:'自定义按钮',type:'json',init:buttons,target:'',bursh:true,bind:true,tip:'',list:null},
	
]

let events=[
	//{key: '$comp_filter',text: '数据过滤',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '修改或增加数据项，干预处理当前所有数据',	list: null	},

	{key: '$comp_getSql',text: '查询执行前',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '如无返回值执行正常查询，如果返回false取消本次查询，如返回SQL字符串可替换设置的SQL，以实现动态SQL查询逻辑,注意返回数据结果中的字段应与设定中的SQL结果一致',	list: null	},
	{key: '$comp_dataLoad',text: '数据加载后',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '数据加载(包括其它组件向此组件传值)完成后触发，可对数据内容进行干预处理',	list: null	},
	{key: '$comp_buttonClick',text: '按钮点击后',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '点击扩展按钮后触发，可对数据内容进行干预处理',	list: null	},
	{key: '$comp_rowCheck',text: '行勾选后',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '数据行上勾选或取消勾选后触发,事件参数row为当前行,如果是表头的批量勾选操作，row为null',	list: null	},
//	{key: '$comp_listCheck',text: '单行勾选后',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '表头的批量勾选或取消勾选后触发',	list: null	},
	{key: '$comp_delBefore',text: '数据删除前',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '自动数据删除前可进行拦截，如果返回false将取消本次操作，请注意如果需从后端查询数据验证，必须使用$.sql的同步方法',	list: null	},
	{key: '$comp_dataClick',text: '数据项点击',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '点击数据单位格触发',	list: null	},
	{key: '$comp_rowClick',text: '数据行点击',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '点击数据行时触发',	list: null	},
	{key: '$comp_rowDblClick',text: '数据行双击',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '点击数据行时触发',	list: null	},

 
]
let commtable= {
	data:{
		type:'commtable',display:'',
	},
	delete:'',
	hide:'headConfig,lineShow,dataOption', //width,height,
	base:list,  //cs.concat(nodeFont).concat(dataFont) ,
	special: special,

	action:events
} 
export default commtable