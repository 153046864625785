<template>
	<div @click="mouseClick" @mousedown="mouseDown" @mouseover="mouseOver" @mouseleave="mouseLeave" @mouseup="mouseUp"
		:style="layoutstyle" :class="layoutcss">
		<el-carousel height="100%" @change="itemChange" :type="model.face" :direction="model.direction"
			:autoplay="model.autoplay==='Y' && !isedit" :interval="model.interval" :loop="model.loop==='Y'"
			:pause-on-hover="model.pause==='Y'" initial-index:="model.initIndex" :trigger="isedit?'click':model.trigger"
			:indicator-position="model.indicator" :arrow="model.arrow">


			<!-- 动态模式只出来第一页 -->
			<template v-if="isedit && model.same==='Y'">
				<el-carousel-item>
					<container :model="model.items[0]" :project="project" :page="page" :host="host" :isedit="isedit"
						:datas="null" />
				</el-carousel-item>
			</template>
			<template v-else>
				<el-carousel-item v-for="item in modelList" :key="item">
					<container :model="item.$model" :project="project" :page="page" :host="host" :isedit="isedit"
						:datas="item.data" />
				</el-carousel-item>
			</template>


		</el-carousel>
	</div>
</template>

<script>
	import layoutBase from './layoutBase.js'


	export default {
		mixins: [layoutBase],
		data() {
			return {
				bindData: null,

			}
		},

		methods: {
			itemChange(idx) {
				if (this.isedit) {

				} else {
					this.doEvent({
						eventName: 'change',
						index: idx,
						dataItem: this.modelList[idx].data,
					}, '$comp_change')
				}

			}

		},
		computed: {
			modelList() {

				let list = []
				let idx = 0
				for (let item of this.jsonList) {
					idx++
					let row = {
						data: {
							...item
						}
					}
					if (this.model.same === 'Y') { //相同模板模式以第一页为模板
						row.$model = this.$logic.clone.cloneModel(this.model.items[0], row.data)
					} else { //静态模式，如果页面少于数据量，生成对应的新页
						if (this.model.items.length < idx) {
							let sub = this.getConfig().create('row', this.model, this.page.formData)
							sub.name = '编辑区'
							sub.$hold = this.model.id
							sub.height = '100%'
							row.$model = sub
						} else {
							row.$model = this.model.items[idx - 1]
						}
					}

					list.push(row)
				}

				return list
			}
		},
		created() {
			this.createInit()
			if (!this.isedit) {
				this.dataLoad()
			}

		}
	}
</script>

<style scoped>
	:deep(.el-carousel) {
		width: 100%;
		height: 100%;
	}
</style>