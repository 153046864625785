import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import font from '../../public/font.js'
import button from './button.js'
import deepClone from '../../../../../plugins/deepClone.js'
let buttonBase=deepClone.deepClone(button.base)
let special=deepClone.deepClone(button.special)
for(let i=buttonBase.length-1;i>-1;i--){
	let item=buttonBase[i]
	if( ',mode,dataOption,listData'.indexOf(item.key)>-1){
		buttonBase.splice(i,1)
	}
 
}

let base=[
	{key: 'title',text: 'Excel导入'	},
//	{key: 'allowExcel',text: '启用导入',type: 'switch',init: 'Y',	target: '',	bursh: true,bind: true,tip:'',list: null },
//	{key: 'auto',text: '数据回调',type: 'switch',init: 'Y',	target: '',	bursh: true,bind: true,tip:'启用此选项后将不自动写入数据表，上传EXCEL文件后提取内容到前端，并触发数据加载后的事件',list: null },
	{key: 'importSet',text: '导入设置',type: 'importset',init: {sourceKey:'',sheets:[],recall:'N'},	target: '',	bursh: true,bind: true,tip: '自动加载当前用户姓名，一般不需要设置默认值'},
	
	
].concat(buttonBase)

let excelImport = {
	data: {
		type: 'excelimport',ico:'far fa-file-excel',value:'Excel导入'
	},
	delete: '',
	hide: '',
	base: base.concat(compBase.base),
	special: special,//{key: 'jsonData',text: 'JSON数据',type: 'json',init: datas,	target: '',	extend: false,tip: '',list: null},
	action: [
		{key:'title',text:'组件业务事件'},
		{key: '$comp_uploadBefore',text: '导入上传前',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '返回false将阻止上传，如果需要向服务器做数据交互处理务必使用同步方法',list: null	},
		
		{key: '$comp_loadAfter',text: '数据加载后',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '如果导入设置中启用回调模式，不自动进行数据库操作，读取EXCEL文件数据内容成功后触发此事件,返回EXCEL表集体数据',list: null	},
		{key: '$comp_importAfter',text: '导入完成后',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '如果导入设置中不启用回调模式，将自动进行数据库写入操作，数据库执行成功后触发此事件',list: null	},
		
	].concat(options.getEvents(null,'$hook'))
}

export default excelImport
