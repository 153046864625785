import options from "../../public/options.js"
import font from '../../public/font.js'
import layout from '../../public/layout.js'
import special from '../../public/layoutspecial.js'
/* let page = function config() { //使用函数方式，避免多实例污染
	let data = {
		...base
	}
	return data

}

export default page()
 */
let formAccess=[
	{key:'accessGroups',text:'页面权限',type:'json',init:null,target:null,bursh:true,bind:true,tip:'',list:null},
	{key:'accessGroup',text:'权限场景',type:'access',init:'',target:null,bursh:true,bind:true,tip:'',list:null},
	
	
]
let allowContextMenu=[
	{key:'contextMenu',text:'弹右键菜单',type:'select',init:'Y',target:'zIndex',bursh:true,bind:true,tip:'是否可弹出浏览器的右键菜单',list:options.YON},

]
let page= {
	data:{
		type:'page',accessRule:null,position:'relative',width:'100%',height:'100%',alignV:'flex-start',minH:'80px',overH:'auto',overV:'auto',backColor:'#FFFFFF',paddingT:'30px',paddingB:'30px',paddingL:'15px',paddingR:'15px'
	},
	delete:'',//height,
	hide:'display,flexW,position,marginT,marginB,marginL,marginR,accessGroups',//width,minH,
	base:layout.concat([
	{key:'realWidth',text:'实际宽度',type:'number',init:500,target:'',bursh:true,bind:true,tip:'',list: null},
	{key:'realHeight',text:'实际高度',type:'number',init:200,target:'',bursh:true,bind:true,tip:'',list: null},
	{key:'scaleSize',text:'最大缩放',type:'number',init:5,target:'',bursh:true,bind:true,tip:'移动端通过手势缩放页面大小的倍数，当前页面是顶级页面时此属性生效',list: null},
		
		
	]).concat(formAccess).concat(font),// .concat(allowContextMenu)
	special :[].concat(special).concat([
		{key:'user',text:'当前用户',type:'text',init:null,target:'',bursh:true,bind:true,read:true,tip:'当前登录的用户对象，可进行联动绑定',list: null},
		{key:'dataBag',text:'共享数据包',type:'text',init:{},target:'',bursh:true,bind:true,read:true,tip:'用于页面内组件共享公共数据，可进行联动绑定',list: null},
		{key:'events',text:'共享函数',type:'text',init:{},target:'',bursh:true,bind:true,read:true,tip:'用于页面内组件共享公共数据，可进行联动绑定',list: null},
		{key:'data1',text:'共享数据1',type:'text',init:null,target:'',bursh:true,bind:true,read:true,tip:'用于页面内组件共享公共数据，可进行联动绑定',list: null},
		{key:'data2',text:'共享数据2',type:'text',init:null,target:'',bursh:true,bind:true,read:true,tip:'用于页面内组件共享公共数据，可进行联动绑定',list: null},
		{key:'data3',text:'共享数据3',type:'text',init:null,target:'',bursh:true,bind:true,read:true,tip:'用于页面内组件共享公共数据，可进行联动绑定',list: null},
		{key:'data4',text:'共享数据4',type:'text',init:null,target:'',bursh:true,bind:true,read:true,tip:'用于页面内组件共享公共数据，可进行联动绑定',list: null},
		{key:'data5',text:'共享数据5',type:'text',init:null,target:'',bursh:true,bind:true,read:true,tip:'用于页面内组件共享公共数据，可进行联动绑定',list: null},
		{key:'data6',text:'共享数据6',type:'text',init:null,target:'',bursh:true,bind:true,read:true,tip:'用于页面内组件共享公共数据，可进行联动绑定',list: null},
		{key:'data7',text:'共享数据7',type:'text',init:null,target:'',bursh:true,bind:true,read:true,tip:'用于页面内组件共享公共数据，可进行联动绑定',list: null},
		{key:'data8',text:'共享数据8',type:'text',init:null,target:'',bursh:true,bind:true,read:true,tip:'用于页面内组件共享公共数据，可进行联动绑定',list: null},
		{key:'data9',text:'共享数据9',type:'text',init:null,target:'',bursh:true,bind:true,read:true,tip:'用于页面内组件共享公共数据，可进行联动绑定',list: null},
 
	]),
	action:[
		{key: 'title',text: '组件事件'},
		{key: '$comp_sizeChange',text: '尺寸变化后',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '页面宽高发生变化后',list: null	},
		
	].concat( options.getEvents(null,'$hook','$hook_create,$hook_show,$hook_hide,$hook_destroy'))//,'$timer,'$event_change,$event,$event_input'
}

export default page
