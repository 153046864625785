import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import font from '../../public/font.js'
import extend from '../../public/extend.js'
let dataConfig=compBase.getDataConfig()
let validate=compBase.getValidate('datetime') 
validate.validateSet.exclude='input'
let base = [
	{key: 'title',text: '日期时间'	},
	{key: 'init',text: '初始值方式',type: 'select',init: 'now',target: '',bursh: true,bind: false,tip: '',list:[{label:'无默认值',value:'none'},{label:'当前日期',value:'now'},{label:'设定日期',value:'set'}]},
	{key: 'value',text: '日期时间值',type: 'text',init: '',	target: '',	bursh: true,bind: true,tip: '',list: null},
	{key: 'fs',text: '格式化结果',type: 'text',init: '',	target: '',	bursh: true,bind: true,read:true,tip: '',list: null},

	{key: 'save',text: '提交保存',type: 'switch',init: 'Y',target: '',bursh: true,bind: true,tip: '是否将此组件数据提交写入到数据库',list: null},
	{key: 'fieldName',text: '数据表字段',type: 'field',init: dataConfig,	target: '',	bursh: true,bind: false,tip: '',attribute:'value'},
 	{key: 'validateSet',text: '校验时机',type: 'triger',init: validate.validateSet,	target: '',	bursh: true,bind: false,tip: '',list:null},
	{key: 'validateRule',text: '校验规则',type: 'validate',init: validate.validateRule,	target: '',	bursh: true,bind: false,tip: '',attribute:'dateTime'},
	{key: 'ignoreHide',text: '隐藏时忽略',type: 'switch',init: 'N',target: '',bursh: true,bind: true,tip: '元素为不可见状态时不进行数据校验',list: null},

	{key: 'dType',text: '输入类型',type: 'text',init: 'datetime',	target: '',	bursh: true,bind: true,tip: '',list: compBase.textTypes},
	 
	{key: 'size',text: '大小样式',type: 'select',init: 'default',target: '',	bursh: true,bind: true,tip: '',list:[{label:'较大',value:'large'}].concat( compBase.sizes)},
    {key: 'tip',text: '提示信息',type: 'text',init: '请选择',	target: '',	bursh: true,bind: true,tip: '',list: null},
	{key: 'clearable',text: '清空按钮',type: 'select',init: 'Y',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},
   {key:'width',text:'宽度',type:'text',init:'100%',target:'width',	bursh: true,bind: true,tip:'象素、百分比、函数类型',list:null},
   
	 compBase.status2,
  
    
]
	

let datetime = {
	data: {
		type: 'datetime',isInput:'S',
	},
	delete: 'fontName,fontColor,fontBold,fontItalic,fontHeight',
	hide: 'dType,width',
	base: base.concat(font).concat(compBase.base),
	special: extend.tip,
	action: options.getEvents(null,'$event_change,$event_focus,$event_blur').concat(options.getEvents(null,'$hook'))
}

export default datetime
