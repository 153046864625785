import font from '../../public/font.js'
import layout from '../../public/layout.js'
import special from '../../public/layoutspecial.js'
import options from '../../public/options.js'
import extend from '../../public/extend.js'
let base=[
	{key:'title',text:'定位'},
    {key:'left',text:'左侧',type:'text',init:null,target:null,bursh:true,bind:true,tip:'',list:null},
	{key:'right',text:'右侧',type:'text',init:'10px',target:null,bursh:true,bind:true,tip:'',list:null},
	{key:'top',text:'顶部',type:'text',init:'20px',target:null,bursh:true,bind:true,tip:'',list:null},
	{key:'bottom',text:'底部',type:'text',init:null,target:null,bursh:true,bind:true,tip:'',list:null},
	{key:'allowMove',text:'允许拖动',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list:options.YON},
	
].concat(layout).concat(font)
/* 
let cfg1= {key:'left',text:'左侧',type:'text',init:null,target:null,bursh:true,bind:true,tip:'',list:null}
let cfg2= {key:'right',text:'右侧',type:'text',init:'0px',target:null,bursh:true,bind:true,tip:'',list:null}
let cfg3= {key:'top',text:'顶部',type:'text',init:'20px',target:null,bursh:true,bind:true,tip:'',list:null}
let cfg4= {key:'bottom',text:'底部',type:'text',init:null,target:null,bursh:true,bind:true,tip:'',list:null}
base.splice( 0,0,cfg4)
base.splice( 0,0,cfg3)
base.splice( 0,0,cfg2)
base.splice( 0,0,cfg1)
base.splice( 0,0,{key:'title',text:'方位偏移'})
 */
let hover= {//悬浮容器
	data:{
		type:'hover',height:'100px',width:'200px',alignV:'flex-start',minW:'20px',minH:'20px',overH:'visible',overV:'visible',paddingT:'0px',paddingB:'0px',paddingL:'0px',paddingR:'0px',
	},
	delete:'',
	hide:'display,flexW,position',
	base:base ,
	special :[].concat(special),
	action:[
		{key:'enabled',text:'可用状态',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list:options.YON},
		
	].concat( options.getEvents(null,'$event_click,$event_mousedown,$event_mouseup,$event_mouseleave,$event_mouseover',null)).concat(extend.link) // options.getEvents(null,'$timer,$event,$hook','$event_change,$event_input')
}

export default hover