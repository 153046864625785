import options from '../../../../../public/options.js'
import polarConfig from '../polarConfig.js'
let base = polarConfig.base
 let initData=JSON.stringify( [
 	['日期', 'A类','B类','C类'],
 	['周一', 50,120,150],
//	['周二', 120],
//	['周三', 150],
 
 ]).replaceAll('],','],\r\n')

let special = [  

	 {key:'title',text:'柱条系列'},
	 {key:'seriesType',text:'图形类型',type:'text',init:'bar',path:'_series.type',bursh:true,bind:true,tip:'',list:null},
	 {key: 'coordinateSystem',text: '坐标类型',type: 'text',init: 'polar',path: '_series.coordinateSystem',bursh: true,bind: true,tip: '',list: null},
	 
	 {key:'seriesBarWidth',text:'柱条宽度',type:'text',init:null,path:'_series.barWidth',bursh:true,bind:true,tip:'可指定柱条宽度，直接输入数值为象素值，支持百分比如10%',list:null},
	 {key:'seriesBarborderWidth',text:'描边粗细',type:'number',init:1,path:'_series.itemStyle.borderWidth',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesBarborderColor',text:'描边颜色',type:'color',init:null,path:'_series.itemStyle.borderColor',bursh:true,bind:true,tip:'',list:null},

	 {key:'seriesBarLabelShow',text:'显示标签',type:'switch',init:'N',path:'_series.label.show',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'seriesBarLabelColor',text:'标签颜色',type:'color',init:options.initColor.text.dark,path:'_series.label.color',bursh:true,bind:true,tip:'',list:options.YON},
	{key:'seriesBarLabelFontSize',text:'标签字号',type:'number',init:'12',path:'_series.label.fontSize',bursh:true,bind:true,tip:'',list:null},
	{key:'seriesBarLabelFontFamily',text:'标签字体',type:'select',init:'',path:'_series.label.fontFamily',bursh:true,bind:true,tip:'',list:options.fonts},

	 {key:'seriesBarLabelRotate',text:'标签角度',type:'number',init:0,path:'_series.label.rotate',bursh:true,bind:true,tip:'',list:null}, 
	 {key:'seriesBarLabelPosition',text:'标签位置',type:'select',init:'top',path:'_series.label.position',bursh:true,bind:true,tip:'',list:options.toOptions('起始=start,中间=inside,尾部=end,柱条起始=insideStart,柱条中间=middle,柱条末端=insideEnd')},
	 {key: 'seriesBarLabelFormat',text: '标签模板',type: 'text',init: '{c}',path: '_series.label.formatter',bursh: true,bind: true,tip: "字符串模板 模板变量有：{a}：系列名。{b}：数据名。{c}：数据值。{@xxx}：数据中名为 'xxx' 的维度的值，如 {@product} 表示名为 'product' 的维度的值。{@[n]}：数据中维度 n 的值，如 {@[3]} 表示维度 3 的值，从 0 开始计数。",list: null},

	// {key:'seriesBarStack',text:'柱条堆叠',type:'select',init:'none',bursh:true,bind:true,tip:'',list:options.toOptions('不堆叠=none,全部堆叠=all,第一个之后=after,最后一个之前=befor')},

	 {key:'seriesBarColorBy',text:'取色方式',type:'select',init:'series',path:'_series.colorBy',bursh:true,bind:true,tip:'',list:options.toOptions('按系列取色=series,按数据项取色=data')},
	 {key:'seriesShowBackground',text:'启用背景色',type:'switch',init:'N',path:'_series.showBackground',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'seriesBackgroundColor',text:'柱条背景色',type:'colorA',init:'rgba(180, 180, 180, 0.2)',path:'_series.backgroundStyle.color',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesBackgroundBorderColor',text:'背景描边色',type:'colorA',init:null,path:'_series.backgroundStyle.borderColor',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesBackgroundBorderType',text:'描边线类型',type:'select',init:'solid',path:'_series.backgroundStyle.borderType',bursh:true,bind:true,tip:'',list:options.BorderType},
	 {key:'seriesBackgroundBorderWidth',text:'描边线粗细',type:'number',init:0,path:'_series.backgroundStyle.borderWidth',bursh:true,bind:true,tip:'',list:null},
//	 {key:'seriesBackgroundBorderRadius',text:'圆角大小',type:'number',init:0,path:'_series.backgroundStyle.borderRadius',bursh:true,bind:true,tip:'',list:null},
//	 {key:'seriesRealtimeSort',text:'实时排序',type:'switch',init:'N',path:'_series.realtimeSort',bursh:true,bind:true,tip:'',list:options.YON},

//	 {key:'initData',text:'初始数据',type:'json',init:initData,bursh:true,bind:true,tip:'',list:null},
 {key:'title',text:'径向'},
 {key:'radiusAxisTickShow',text:'显示刻度',type:'switch',init:'N',path:'radiusAxis.axisTick.show',bursh:true,bind:true,tip:'',list:options.YON},
 {key:'radiusAxisLineShow',text:'显示起始线',type:'switch',init:'N',path:'radiusAxis.axisLine.show',bursh:true,bind:true,tip:'',list:options.YON},
 {key:'radiusAxisLabelShow',text:'显示标签',type:'switch',init:'N',path:'radiusAxis.axisLabel.show',bursh:true,bind:true,tip:'',list:options.YON},
 {key:'radiusAxisLabelColor',text:'标签颜色',type:'color',init:options.initColor.text.dark,path:'radiusAxis.axisLabel.color',bursh:true,bind:true,tip:'',list:options.YON},		
 
	

].concat(polarConfig.special)
let action = polarConfig.action

let actionComp = []


let cfg = {
	data: {
		type: 'chart_barR',initData:initData,seriesBarColorBy:'series',color:options.chartColor.bar3,titleAlign:'left',polarRadiusAxis:'category',
		chartClan: 'polar',legendOrient:'vertical'//默认垂直方向图例
	},
	delete: '',
	hide: 'width,height,seriesType,seriesPieRadiusI,coordinateSystem,polarRadiusAxis,dataOption',
	base:[].concat(base) ,
	special: special,
	action: polarConfig.action.timer.concat(polarConfig.action.comm)
}
export default cfg
