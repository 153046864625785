import options from '../../public/options.js'

let types= [
	{label:'标签',value:'border-card'},{label:'卡片',value:'card'}
	 	 
]
let tabs=JSON.stringify([
		{key:'tab1',label:'标签一',icon:'fas fa-home-lg-alt',url:'' },
		{key:'tab2',label:'标签二',icon:'fas fa-analytics',url:''},
		{key:'tab3',label:'标签三',icon:'fas fa-chalkboard-teacher',url:'' },
])
/*公共样式计算属性中只处理在layout和font在存在的属性名称，如增加自定义属性需从这两者中取key值 或自行覆盖样式计算属性*/
let cs=[
{key:'title',text:'标签页容器属性'},
{key:'tabValue',text:'当前标签页',type:'text',init:'tab1',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'tabType',text:'样式风格',type:'select',init:'card',target:'',bursh:true,bind:true,tip:'',list: types},
{key:'showIcon',text:'显示图标',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list:  options.YON},

{key:'closeable',text:'允许关闭',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list:  options.YON},
{key:'addable',text:'允许新增',type:'select',init:'N',target:'',bursh:true,bind:true,tip:'',list:  options.YON},
{key:'tabLabelColor',text:'标签颜色',type:'color',init:'#303133',target:'--tab-label-label-color',bursh:true,bind:true,tip:'',list:  options.YON},
{key:'cardBackColor',text:'标签背景色',type:'color',init:'#f8f8f8',target:'--tab-card-back-color',bursh:true,bind:true,tip:'',list:  options.YON},
//{key:'tabIconlColor',text:'图标颜色',type:'color',init:'',target:'',bursh:true,bind:true,tip:'默认与文本相同的颜色',list:  options.YON},

{key:'selLabelColor',text:'选中颜色',type:'color',init:'#409eff',target:'--tab-sel-label-color',bursh:true,bind:true,tip:'',list:  options.YON},
{key:'selLineColor',text:'选中线颜色',type:'color',init:'#409eff',target:'--tab-sel-line-color',bursh:true,bind:true,tip:'',list:  options.YON},

{key:'selBackColor',text:'选中背景色',type:'color',init:'#90EE90',target:'--tab-sel-back-color',bursh:true,bind:true,tip:'',list:  options.YON},
{key:'linear',text:'启用渐变',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list:  options.YON},


{key:'labelMargin',text:'标签间距',type:'text',init:'-1px',target:'--tab-label-margin',bursh:true,bind:true,tip:'',list:  options.YON},
{key:'labelPadding',text:'标签内空白',type:'text',init:'20px',target:'--tab-label-padding',bursh:true,bind:true,tip:'',list:  options.YON},
{key:'firstPadding',text:'首标签缩进',type:'text',init:'2px',target:'',bursh:true,bind:true,tip:'',list:  options.YON},

{key:'width',text:'宽度',type:'text',init:'100%',target:'width',bursh:true,bind:true,tip:'',list: null},
{key:'height',text:'高度',type:'text',init:'100%',target:'height',bursh:true,bind:true,tip:'',list: null},
{key:'minH',text:'最小高度',type:'text',init:'200px',target:'minHeight',tip:'象素、百分比、函数类型',bursh:true,bind:true,list:null},	
{key:'showBorder',text:'显示页边框',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list:  options.YON},
{key:'borderWidth',text:'页边框粗细',type:'text',init:'1px',target:'--tab-border-width',bursh:true,bind:true,tip:'',list:  options.YON},
{key:'borderColor',text:'页边框颜色',type:'color',init:'#cccccc',target:'--tab-border-color',bursh:true,bind:true,tip:'',list:  options.YON},
{key:'pageRadius',text:'页底边圆角',type:'text',init:'0px',target:'--tab-page-radius',bursh:true,bind:true,tip:'',list:  options.YON},

{key:'tabPaddingT',text:'页顶端边距',type:'text',init:'0px',target:'--tab-padding-top',tip:'象素、百分比、函数类型',bursh:true,bind:true,list:null},	
{key:'tabPaddingLR',text:'页左右边距',type:'text',init:'1px',target:'--tab-padding-lr',tip:'象素、百分比、函数类型',bursh:true,bind:true,list:null},	

//{key:'tabs',text:'外部赋值标签集合',type:'text',init:null,target:'',tip:'',bursh:true,bind:true,list:null},	
]

let tabPage= {
	data:{
			type: 'tabpage',
	},
	delete:'',
	hide:'contentD,width,height,tabs,jsonData',//width,minW',
	base:cs ,
	special:[	{key: 'jsonData',text: 'JSON数据',type: 'json',init: tabs,	target: '',bursh:false,bind:false,tip: '',treeMap:{id:'key',label:'label',icon:'icon'},dataMap: {key:'唯一标识',label:'标题文本',icon:'图标',url:'链接地址',color:'颜色'}},],
	action:[]
}

export default tabPage