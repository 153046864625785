import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import font from '../../public/font.js'
import extend from '../../public/extend.js'
//dataName指向属性名，fieldName字段名，unionType,refuseNull 这三个从数据表对话框中回写; check多值类型暂时以逗号隔的字符串返回，后期可扩展增加设置返回值方式，数组还是逗号隔的字符串
let dataConfig=compBase.getDataConfig()
let validate=compBase.getValidate() 
validate.validateSet.exclude='change,input,blur'
let base = [
	{key: 'title',text: '隐藏数据域'	},
	{key: 'value',text: '数据内容',type: 'text',init: '',	target: '',	bursh: true,bind: true,tip: '',list: null},
	{key: 'save',text: '提交保存',type: 'switch',init: 'Y',target: '',bursh: true,bind: true,tip: '是否将此组件数据提交写入到数据库',list: null},
	{key: 'fieldName',text: '数据表字段',type: 'field',init: dataConfig,	target: '',	bursh: true,bind: false,tip: '',attribute:'value'},
 	{key: 'validateSet',text: '校验时机',type: 'triger',init: validate.validateSet,	target: '',	bursh: true,bind: false,tip: '',list:null},
	{key: 'validateRule',text: '校验规则',type: 'validate',init: validate.validateRule,	target: '',	bursh: true,bind: false,tip: '',attribute:'value'},
   {key: 'ignoreHide',text: '隐藏时忽略',type: 'switch',init: 'N',target: '',bursh: true,bind: true,tip: '元素为不可见状态时不进行数据校验',list: null},
   
]
	
//compBase.appendDbConfig(base,1)
let hidden = {
	data: {
		type: 'hidden',isInput:'S',
	},
	delete: '',
	hide: 'ignoreHide',
	base: base,
	special: [], //extend.tip,
	action:   options.getEvents(null,'$event_change,$hook')
}

export default hidden
