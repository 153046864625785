<template>
	<div v-if="isedit">
		<div v-if="model.type=='modal_dialog'" @click="mouseClick" class="dialog" @mouseover="mouseOver"
			:style="dialogStyle" :class="layoutcss">
			<div v-if="model.showTitle==='Y'" class="dialog-head">

				<div>
					<i :class="model.dialogIcon"></i>
					{{model.dialogTitle}}
				</div>
				<div @click="dialogShow" class="title-button" title="点击预览">
					<i class="far fa-bring-forward"></i>
				</div>
			</div>
			<div class="dialog-body">
				<container :model="model.items[0]" :project="project" :page="page" :host="host" :isedit="isedit"
					:datas="bindData" />
			</div>
			<div class="dialog-foot" :style="footStyle">
				<el-button v-if="model.showCancel==='Y'" :type="model.cancelType"><i
						:class="model.cancelIcon"></i>{{model.cancelText}}</el-button>
				<el-button v-if="model.showOK==='Y'" :type="model.oKType"><i
						:class="model.okIcon"></i>{{model.okText}}</el-button>

			</div>



		</div>
		<div v-else-if="model.type=='modal_drawer'" @click="mouseClick" class="dialog" @mouseover="mouseOver"
			:style="dialogStyle" :class="layoutcss">
			<div class="dialog-head">

				<div>
					<i :class="model.dialogIcon"></i>
					{{model.dialogTitle}}
				</div>
				<div @click="dialogShow" class="title-button" title="点击预览">
					<i class="far fa-bring-forward"></i>
				</div>
			</div>
			<div class="dialog-body">
				<container :model="model.items[0]" :project="project" :page="page" :host="host" :isedit="isedit"
					:datas="bindData" />
			</div>
			<div class="dialog-foot" :style="footStyle">
				<el-button v-if="model.showCancel==='Y'" :type="model.cancelType"><i
						:class="model.cancelIcon"></i>{{model.cancelText}}</el-button>
				<el-button v-if="model.showOK==='Y'" :type="model.oKType"><i
						:class="model.okIcon"></i>{{model.okText}}</el-button>

			</div>



		</div>

	</div>
	<logicdialog v-if="model.type=='modal_dialog'" ref="dialog" :show-title="model.showTitle==='Y'" :title="model.dialogTitle" @close="dialogClose" @ok="dialogOK" :auto-close="false"
	:titlebg="model.titleBgColor" :dialog-top="model.dialogTop"	:dialog-height="model.dialogHeight" :dialog-width="model.dialogWidth" :icon="model.dialogIcon"
		:button-layout="model.buttonType" :show-cancel="model.showCancel==='Y'" :cancel-icon="model.cancelIcon"
		:cancel-text="model.cancelText" :cancel-type="model.cancelType" :show-ok="model.showOK==='Y'"
		:ok-icon="model.okIcon" :ok-text="model.okText" :ok-type="model.oKType">
		<container :model="model.items[0]" :project="project" :page="page" :host="host" :isedit="isedit"
			:datas="bindData" />

	</logicdialog>

	<logicdrawer v-if="model.type=='modal_drawer'" ref="drawer" :title="model.dialogTitle" @close="dialogClose" @ok="dialogOK" :auto-close="false"
		:dialog-height="model.dialogHeight" :dialog-width="model.dialogWidth" :icon="model.dialogIcon"
		:direction="model.direction" :button-layout="model.buttonType" :show-cancel="model.showCancel==='Y'" :cancel-icon="model.cancelIcon"
		:cancel-text="model.cancelText" :cancel-type="model.cancelType" :show-ok="model.showOK==='Y'"
		:ok-icon="model.okIcon" :ok-text="model.okText" :ok-type="model.oKType">
		<container :model="model.items[0]" :project="project" :page="page" :host="host" :isedit="isedit"
			:datas="bindData" />
	</logicdrawer>
</template>

<script>
	import layoutBase from './layoutBase.js'
	//import LogicDrawer from '../../../../../comm/dialog/Drawer.vue'
	export default {
		mixins: [layoutBase],
		data() {
			return {
				bindData: null,
				drawerShow: false
			}
		},

		methods: {
			dialogOK() {
				this.doEvent({
					eventName: 'clickOK',
				}, '$comp_clickOK')
			},
			dialoghide() {
				if (this.model.type == 'modal_dialog') {
					this.$refs.dialog.hide()
				} else {
					this.$refs.drawer.hide()
				}

			},
			doClose() {
				let check = this.doEvent({
					eventName: 'closeBefore',
				}, '$comp_closeBefore')
				if (check === false) {
					return
				} else {
					this.doEvent({
						eventName: 'clickCancel',
					}, '$comp_clickCancel')
					this.dialoghide()
				}
			},
			dialogClose(confirm=false) {
				if (this.model.cancelConfirm === 'Y' || confirm) {
					this.$logic.pop.confirm('确认', this.model.confirmText, () => {
						this.doClose()
					})
				} else {
					this.doClose()
				}
			},
			dialogShow() {
				if (this.model.type == 'modal_dialog') {
					this.$refs.dialog.show()
				} else {
					this.$refs.drawer.show()
				}
				this.$nextTick(() => { //弹出后再触发事件，以免元素还未被创建
					this.doEvent({
						eventName: 'openBefore',
					}, '$comp_openBefore')
				})

			}

		},
		computed: {
			dialogStyle() {
				let css = this.layoutstyle
				css['--dialog-radius'] = this.model.type == 'modal_drawer' ? '0px' : '10px'
				return css
			},
			footStyle() {
				let css = {}
				css['--button-layout'] = this.model.buttonLayout
				return css
			}
		},
		created() {
			if (!this.isedit) {
				this.model.$open = this.dialogShow
				this.model.$close = this.doClose
			}

		}
	}
</script>

<style scoped>
	.dialog {

		width: var(--dialog-width);
		height: var(--dialog-height);
		border: solid 1px #cccccc;
		border-radius: var(--dialog-radius);
		margin-bottom: 20px;
	}

	.dialog-head {
		height: 50px;
		font-size: 18px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 5px 10px;
		background-color: var(--title-bg-colore);
		border-radius: var(--dialog-radius) var(--dialog-radius) 0px 0px;
	}

	.dialog-body {
		width: 100%;
		height: calc(100% - 120px);
		min-height: 30px;
		overflow: auto;
	}

	.dialog-foot {
		height: 60px;
		padding: 15px 20px;
		border-top: solid 1px #cccccc;
		border-radius: 0px 0px 10px 10px;
		text-align: var(--button-layout);
	}

	.title-button {
		cursor: pointer;
	}

	.title-button:hover {
		color: #ff0000;
	}
</style>