import ReportCfg from '../../../../../comm/report/ReportCfg.js'
import Clone from '../../../../../../plugins/deepClone.js'
let todo = {
	headItems: [],
	queryItems: [],
	enumItems: [],
	rangeUse: false,
	rangeField: null,
	rangeItems: [],
	sql: '',
	dataSourceKey: ''

}
let taskCol = ReportCfg.getNewHead({
	fieldName: 'taskid',
	label: '任务ID',
	typeGroup: 'num',
	unionType: 'int8'
} ) 
todo.headItems.push(taskCol)
todo.headItems.push(ReportCfg.getNewHead({
	fieldName: 'flowname',
	label: '流程名称',
	typeGroup: 'text',
	unionType: 'varchar'
}))

todo.headItems.push(ReportCfg.getNewHead({
	fieldName: 'createtime',
	label: '开始时间',
	typeGroup: 'time',
	unionType: 'datetime'
}))
todo.headItems.push(ReportCfg.getNewHead({
	fieldName: 'overtime',
	label: '完成时限',
	typeGroup: 'time',
	unionType: 'datetime'
}))
todo.headItems.push(ReportCfg.getNewHead({
	fieldName: 'nodename',
	label: '工作节点',
	typeGroup: 'text',
	unionType: 'varchar'
}))
todo.headItems.push(ReportCfg.getNewHead({
	fieldName: 'applyusername',
	label: '发起人',
	typeGroup: 'text',
	unionType: 'varchar'
}))
todo.headItems.push(ReportCfg.getNewHead({
	fieldName: 'applyorgpath',
	label: '发起组织',
	typeGroup: 'text',
	unionType: 'varchar'
}))

todo.headItems.push(ReportCfg.getNewHead({
	fieldName: 'applytime',
	label: '发起时间',
	typeGroup: 'time',
	unionType: 'datetime'
}))

todo.queryItems.push(ReportCfg.getNewQuery({
	table: 'f',
	fieldName: 'applyuserid',
	label: '发起人',
	unionType: 'int4',
	typeGroup: 'num'
}, {
	width: '120px',
	input: 'user'
}))
todo.queryItems.push(ReportCfg.getNewQuery({
	table: 'f',
	fieldName: 'applyorgid',
	label: '发起组织',
	unionType: 'int4',
	typeGroup: 'num'
}, {
	width: '300px',
	input: 'org'
}))
todo.queryItems.push(ReportCfg.getNewQuery({
	table: 't',
	fieldName: 'createtime',
	label: '到达时间',
	unionType: 'datetime',
	typeGroup: 'time'
}, {
	compare: 'bt'
})) 

let apply = {
	headItems: [],
	queryItems: [],
	enumItems: [{
		"id": 1702293132907,
		"label": "流程状态",
		"key": "",
		"static": true,
		"list": [{
			"label": "进行中",
			"value": "0"
		}, {
			"label": "已完成",
			"value": "10"
		}, {
			"label": "已取消",
			"value": "20"
		}]
	}],
	rangeUse: false,
	rangeField: null,
	rangeItems: [],
	sql: '',
	dataSourceKey: ''
}

apply.headItems.push(ReportCfg.getNewHead({
	fieldName: 'flowid',
	label: '流程ID',
	typeGroup: 'num',
	unionType: 'int8'
}))

apply.headItems.push(ReportCfg.getNewHead({
	fieldName: 'flowname',
	label: '流程名称',
	typeGroup: 'text',
	unionType: 'varchar'
}))

apply.headItems.push(ReportCfg.getNewHead({
	fieldName: 'versioncode',
	label: '流程版本',
	typeGroup: 'num',
	unionType: 'int4'
}))

apply.headItems.push(ReportCfg.getNewHead({
	fieldName: 'statusinfo',
	label: '流程状态',
	typeGroup: 'text',
	unionType: 'varchar'
}))


apply.headItems.push(ReportCfg.getNewHead({
	fieldName: 'applyusername',
	label: '发起人',
	typeGroup: 'text',
	unionType: 'varchar'
}))

apply.headItems.push(ReportCfg.getNewHead({
	fieldName: 'applyorgpath',
	label: '发起组织',
	typeGroup: 'text',
	unionType: 'varchar'
}))

apply.headItems.push(ReportCfg.getNewHead({
	fieldName: 'editusername',
	label: '制单人',
	typeGroup: 'text',
	unionType: 'varchar'
}))

apply.headItems.push(ReportCfg.getNewHead({
	fieldName: 'applytime',
	label: '发起时间',
	typeGroup: 'time',
	unionType: 'datetime'
}))

apply.headItems.push(ReportCfg.getNewHead({
	fieldName: 'finishtime',
	label: '完成时间',
	typeGroup: 'time',
	unionType: 'datetime'
}))

apply.queryItems.push(ReportCfg.getNewQuery({
	table: 'f',
	fieldName: 'id',
	label: '流程ID',
	unionType: 'int8',
	typeGroup: 'num'
}, {
	width: '180px',
	input: 'input'
}))

apply.queryItems.push(ReportCfg.getNewQuery({
	table: 'f',
	fieldName: 'applytime',
	label: '发起时间',
	unionType: 'datetime',
	typeGroup: 'time'
}, {
	compare: 'bt'
}))

apply.queryItems.push(ReportCfg.getNewQuery({
	table: 'f',
	fieldName: 'flowstatus',
	label: '流程状态',
	unionType: 'int4',
	typeGroup: 'num'
}, {
	width: '100px',
	input: 'single',
	enum: 1702293132907

}))

let exist = Clone.deepClone(apply)
exist.queryItems.push(ReportCfg.getNewQuery({
	table: 'f',
	fieldName: 'applyuserid',
	label: '发起人',
	unionType: 'int4',
	typeGroup: 'num'
}, {
	width: '120px',
	input: 'user',

}))
exist.queryItems.push(ReportCfg.getNewQuery({
	table: 'f',
	fieldName: 'applyorgid',
	label: '发起组织',
	unionType: 'int4',
	typeGroup: 'num'
}, {
	width: '300px',
	input: 'org',

}))
let doing = Clone.deepClone(exist)
for (let i = 0; i < doing.queryItems.length; i++) {
	if (doing.queryItems[i].fieldName == 'flowstatus') {
		doing.queryItems.splice(i, 1)
		break
	}
}
let finish = Clone.deepClone(doing)
let cancel = Clone.deepClone(doing)

let set = {
	todo,
	apply,
	exist,
	doing,
	finish,
	cancel

}
export default set