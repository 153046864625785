<template>
	<el-button v-if="isedit" @click="mouseClick($event,null)" :type="model.color" :plain="model.plain=='Y'"
		:round="model.round=='Y'" :circle="model.circle=='Y'" :loading="model.loading=='Y'" :size="model.size"
		:closable="model.closable=='Y'" :link="model.link=='Y'" :disabled="model.enabled=='N'" :style="compStyle">
		<i v-if="model.loading=='N' && model.ico" :class="model.ico"></i>
		<!-- -->
		{{model.value}}


	</el-button>
	<div v-else ref="importButton">
		<file-upload ref="uploadObj" home="/" :api-url="apiUrl" :auto-upload="true" :project="project"
			@success="fileUploaded" :before-recall="beforeRecall" :data-ex="importData" :show-success="null">
			<el-button :type="model.color" :plain="model.plain=='Y'" :round="model.round=='Y'"
				:circle="model.circle=='Y'" :loading="model.loading=='Y'" :size="model.size"
				:closable="model.closable=='Y'" :link="model.link=='Y'" :disabled="model.enabled=='N'"
				:style="compStyle">
				<i v-if="model.loading=='N' && model.ico" :class="model.ico"></i>
				<!-- @click="mouseClick($event,null)" -->
				{{model.value}}
			</el-button>
		</file-upload>
	</div>



</template>

<script>
	import FileUpload from '../../../../comm/file/FileUpload.vue'
	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase],
		data() {
			return {

			}
		},

		methods: {
			fileUploaded(a, rs, c, d) {
				let res = rs.data
				if (this.model.type === 'excelimport') {
					if (this.model.importSet.recall === 'Y') {
						for (let sheet of res.sheets) {
							let headMap = {}
							for (let head of sheet.heads) {
								headMap[head.label] = head.location
							}
							sheet.headIndex = headMap

						}

						this.doEvent({
							eventName: 'loadAfter',
							result: res,
						}, '$comp_loadAfter')
					} else {
						this.doEvent({
							eventName: 'importAfter',
							result: res,
						}, '$comp_importAfter')
						let info = '导入成功，其中 '
						for (let sheet of res.sheets) {
							info = info + sheet.sheetName + '(' + sheet.rowCount + '),'
						}
						info = info + '共' + res.rowCount + '条数据'
						this.$logic.tip.success(info)
					}
				} else {
					if (this.model.autoJson === 'Y') {
						try {
							res = JSON.parse(res)

						} catch (ex) {
							this.$logic.tip.error('文件内容解析JSON异常：' + ex)
							return
						}

					}
					this.doEvent({
						eventName: 'loadAfter',
						result: res,
					}, '$comp_loadAfter')
				}

			},
			beforeRecall(file) {
				if (this.isedit) {
					return false
				} else {
					return this.doEvent({
						eventName: 'uploadBefore',
						file: file,
					}, '$comp_uploadBefore')
				}

			},
			mouseClick() {
				//	console.log(this.$refs.uploadObj.upload)
				//	this.$refs.uploadObj.upload()
			},
			toImport() {
				let div = this.$refs.importButton
				let button = div.querySelector('button')
				if (button) {
					button.click()
				}			 
			}

		},
		computed: {
			importData() {
				if (this.model.type === 'excelimport') {
					let sourceKey = this.model.importSet.sourceKey
					sourceKey = sourceKey ? sourceKey : this.project.dataSource.sourceKey
					this.model.importSet.sourceKey = sourceKey //运行时态变更数据源ID
					let ds = JSON.stringify(this.model.importSet)
					return {
						importSet: ds
					}
				} else {

					return {
						codeType: this.model.codeType
					}
				}

			},
			apiUrl() {
				let url = '/api/ds/importxls/'
				if (this.model.type === 'fileimport') {
					url = '/api/ds/importfile/'
				}
				return url
			}

		},
		components: {
			FileUpload: FileUpload,
		},
		created() {

			this.model.$toImport = this.toImport

		}
	}
</script>

<style scoped>

</style>