import PageUtil from './pageutil.js'
import configOptin from '../components/form/config/public/options.js'
class DataBase {
	#dbTypes = null
	#fieldTyps = null
	#fieldOptions = null
	#typeGroup = null
	constructor() {
		this.dbTypes = [{
				icon: 'iconfont icon-mysql',
				type: "MySql",
				title: "MySql内核",
				version:'8.0以上',
				desc: "阿里:AnalyticDB、PolarDB、OceanBase,腾讯:TDSQL,巨杉,达梦,TiDB,其它",
				subTypes:configOptin.toOptions('MySql=MySql,阿里AnalyticDB=AnalyticDB,阿里PolarDB=PolarDB,阿里OceanBase=OceanBase,腾讯TDSQL=TDSQL,巨杉=SequoiaDB,达梦=DaMeng,TiDB=TiDB'),
				port: "3306",
				user: "root",
				pool: 20,
				param: "characterEncodeing=utf-8&serverTimezone=GMT+8" //connectTimeout=3000&
			},
			{
				icon: 'iconfont icon-postgresql',
				type: "PostgreSql",
				title: "PostgreSql内核",
				version:'10以上',
				desc: "华为:openGauss、GaussDB、MogDB,人大金仓,南大通用,其它",
				subTypes:configOptin.toOptions('PostgreSql=PostgreSql,华为openGauss=openGauss,华为GaussDB=GaussDB,华为MogDB=MogDB,人大金仓=KingbaseES,南大通用=GBase'),
				port: "5432",
				user: "postgres",
				pool: 20,
				param: "" //connectTimeout=3
			},

			{
				icon: 'iconfont icon-microsoft',
				type: "SqlServer",
				title: "SqlServer",
				version:'2012以上',
				desc: "版本要求：2012以上",
				subTypes:configOptin.toOptions('SqlServer=SqlServer'),
				port: "1433",
				user: "sa",
				pool: 20,
				param: "encrypt=false;" //encrypt=false;trustServerCertificate=false;   socketTimeout=3000
			},
			{
				icon: 'far fa-database',
				type: "Sqlite",
				title: "Sqlite",
				version:'3.36,平台提供的本地数据库仅用于开发测试',
				desc: "平台提供的本地数据库，建议只用于开发测试",
				subTypes:configOptin.toOptions('Sqlite=Sqlite'),
				port: "0",
				user: "",
				pool: 20,
				param: "" //oracle.net.CONNECT_TIMEOUT=3000
			},
			{
				icon: 'iconfont icon-Oracle',
				type: "Oracle",
				title: "Oracle",
				version:'12g以上',
				desc: null,
				subTypes:configOptin.toOptions('Oracle=Oracle'),
				port: "1521",
				user: "",
				pool: 20,
				param: "" //oracle.net.CONNECT_TIMEOUT=3000
			},
		]
		let types = 'int4=整数,int8=长整数,num=小数,char=文本,text=大文本,datetime=日期时间,date=日期,time=时间'.split(',')
		let list = []
		for (let item of types) {
			let parts = item.split('=')
			list.push({
				fieldType: parts[0],
				typeName: parts[1]
			})
		}
		let options = [{
			label: '请选择数据类型',
			value: ''
		}]
		for (let item of list) {
			options.push({
				label: item.typeName,
				value: item.fieldType
			})
		}
		this.fieldTyps = list
		this.fieldOptions = options
		//各数据库的字段类型与类型分类的对应关系
		let typeGroup = 'num=num'.split(',')
		let group = {}
		for (let item of typeGroup) {
			let parts = item.split('=')
			let tg = {}
			tg[parts[0]] = parts[1]
			list.push(tg)
		}
		this.typeGroup = group



	}
	getDbType(typeName) {
		for (let item of this.dbTypes) {
			if (item.type == typeName) {
				return item
			}
		}
		return null //'far fa-database'

	}
	getFieldTypeConfig(fieldTypeGroup) { //结构化处理数据库字段类型
		let tree = []
		let fieldMap = {}
		let fieldUnionMap = {}
		for (let typeGroup of fieldTypeGroup) {
			let group = {
				value: 'g_' + typeGroup.groupName,
				label: typeGroup.groupText,
				children: []
			}
			for (let item of typeGroup.items) {
				let field = {
					label: item.typeText,
					value: item.typeName,
					children: null
				}
				group.children.push(field)
				fieldMap[item.typeName] = item
				if (item.unionName && item.unionName.length > 0) {
					fieldUnionMap[item.unionName] = item
				}
			}
			tree.push(group)
		}
		return {
			tree,
			fieldMap,
			fieldUnionMap
		}
	}

	getSqlVars(sql) {
		//提取{ } 分块内容
		let rs = []
		let cs = sql.split('')
		let part = ''
		let parts = []
		let start = false
		for (let i = 0; i < cs.length; i++) {
			let chr = cs[i]
			if (chr === '{') {
				start = true
				part = part + chr
			} else if (chr === '}') {
				start = false
				part = part + chr
				parts.push(part)
				part = ''
			} else {
				if (start) {
					part = part + chr
				}
			}
		}
		if (part.length > 0) {
			throw new Error('sql param error!')
		}
		for (let part of parts) {
			let word = part.substring(1, part.length - 1).trim() //去掉两边的括号
			let x = word.indexOf(':')
			if (x < 1) {
				throw new Error('param format error:' + part)
			}
			let key = word.substring(0, x).trim()
			let value = word.substring(x + 1).trim()
			rs.push({
				key: key,
				value: value,
				part: part
			})
		}
		return rs
	}
	///////验证SQL中引用表单元素的有效性，返回有值的字符串即为验证失败
	validateSql(sql, page) {
		let errorInfo = null
		let comRefs = null
		try {
			comRefs = this.getSqlVars(sql)
		} catch (ex) {
			errorInfo = '错误的表达式 ' + ex
			return errorInfo
		}


		for (let item of comRefs) {
			let key = item.key
			if (key.indexOf('@') == 0) {
				let part = key.substring(1)
				let x = part.indexOf('.')
				if (x < 1 || x == part.length - 1) {
					errorInfo = 'SQL从页面元素取值,但缺少元素的属性,请修正：' + item.part
					break
				}
				let objId = part.substring(0, x)
				//let objs = PageUtil.getElements([page], objId, true)
				//let obj = objs && objs.length > 0 ? objs[0] : null
				let obj = PageUtil.findFirstElementById([page], objId, true)
				if (!obj) {

					errorInfo = 'SQL从页面元素取值,但不存在此ID[' + objId + ']的元素,请修正：' + item.part
					break
				} else {
					let pn = part.substring(x + 1)
					if (!pn) {
						errorInfo = 'SQL从页面元素取值,但缺少元素的属性名称，请修正：' + item.part
						break
					} else {
						if (!(pn in obj)) {
							errorInfo = 'SQL从页面元素取值,但元素不存在此属性[' + pn + ']，请修正：' + item.part
							break
						}
					}
				}

			}
		}
		return errorInfo
	}
	//////////////////////////////////////////SQL根据参数作替换,如果参数中不存的使用SQL中自带的默认值
	getParamSql(sql, params = {}, page = null, targetModel = null) { //targetModel执行查询的元素
		let user = this.$logic.getUser() || {}
		sql = this.clearSql(sql)
		let sqlRs = sql
		let parts = this.getSqlVars(sql)
		for (let part of parts) {
			let key = part.key
			let value = part.value
			let pv = value
			let numType = value.startsWith("'") ? false : true //如果没有明确指定，使用字符串类型
			if (key.startsWith('@')) { //如果是页面元素取值
				let objId = key.substring(1)
				let x = objId.indexOf('.')
				if (x > 0 && page) {

					let objKey = objId.substring(x + 1)
					objId = objId.substring(0, x)
					let model = page.$(objId)
					if (!model && targetModel) { //页面里没取到值，尝试模板中取
						if (targetModel.$bindData) {
							model = PageUtil.findElementFromBindData(targetModel.$bindData, objId)
						}
					}
					if (model) {
						let mv = model[objKey]
						//console.log(model,objKey,mv,Array.isArray(mv))
						if (mv && Array.isArray(mv)) { //数组类型数据转成多值，用于in的比较
							let ds = ''
							for (let item of mv) {
								if (numType) {
									ds = ds + "," + item
								} else {
									ds = ds + ",'" + item + "'"
								}
							}
							if (ds) {
								ds = ds.substring(1)
							} else { //没有选项时使用默认值
								ds = pv
							}
							pv = ds
						} else {
							if (numType) {
								if (mv === '' || mv === null) { //数值类数据如果没有值就用默认值

								} else { //有值用输入值
									pv = mv
								}

							} else {
								pv = "'" + mv + "'"
							}
						}


					} else {
						alert('SQL:' + sql + ' 设置从' + objId + '元素中取属性[' + objKey + ']值,不存在此元素，请确认Id是否正确')
					}
				}


			} else if (key.startsWith('$')) { //用户属性取数
				let userParam = key
				if (key === '$userId') { //兼容以前的写法
					userParam = '$id'
				}
				userParam = userParam.substring(1) //.toLocaleLowerCase()
				for (let pn in user) {
					if (pn === userParam || pn.toLocaleLowerCase() === userParam.toLocaleLowerCase()) {
						if (value.startsWith("'")) {
							pv = "'" + user[pn] + "'"
						} else {
							pv = user[pn]
						}
						break
					}
				}


			} else { //当前元素是否在父元素的自定义数据中的动态元素,优先取内部数据
				if (targetModel && targetModel.$bindData && (key in targetModel.$bindData)) {
					if (value.startsWith("'")) {
						pv = "'" + targetModel.$bindData[key] + "'"
					} else {
						pv = targetModel.$bindData[key]
					}

				} else if (key in params) {
					if (value.startsWith("'")) {
						pv = "'" + params[key] + "'"
					} else {
						pv = params[key]
					}
				}
			}

			sqlRs = sqlRs.replace(part.part, pv)
		}

		return sqlRs
	}
	////////////////////////SQL解析
	sqlSplit(sql) { //将当前字符串按第一层作分解
		let parts = []
		let stack = []
		let tag = false //是引号开头后的内容
		let word = ''
		let chars = Array.from(sql)
		for (let i = 0; i < chars.length; i++) {
			let chr = chars[i]
			if (chr === ' ' || chr === ',') { //遇到单词分隔符判断关键字
				if (tag || stack.length > 0) { //不是第一层的内容忽略
					word = word + chr
				} else if (word.length > 0) { //判断word,忽略前面的连续空格
					parts.push({
						content: word,
						start: i - word.length,
						end: i
					})
					if (chr === ',') { //逗号分隔符将自身添加到集合
						parts.push({
							content: chr,
							start: i,
							end: i + 1
						})
					}
					word = ''
				}

			} else {
				if (chr === "'") {
					tag = !tag
				} else if (chr === '(') {
					stack.push(chr)
				} else if (chr === ')') {
					stack.pop()
				}
				word = word + chr
			}

		}
		if (word.length > 0) {
			parts.push({
				content: word,
				start: sql.length - word.length,
				end: sql.length
			})
		}
		//console.log(parts)
		return parts
	}
	clearSql(sql) {//排除结尾的;
		return sql.trim().replace(/\n/g, ' ').replace(/\r/g, ' ').replace(/\t/g, ' ').replace(/;$/, '').replace(/;/g, '; ')
	}
	explainSql(sql) { //提取最外层的各部分			
		/* 			sql =
						"select id , 'abc' as ss from (select * from (select * from a1 union select * from a2 ) ) t Left join b on t.id=b.pid where b.id>0 group by a.id,b.id union all select * from table1,tatble2 order by t.id"
				 */
		//sql=this.getParamSql(sql,{})//转化成不带参数的SQL
		//console.log(sql)
		sql = this.clearSql(sql)
		let cs = this.sqlSplit(sql)
		let parts = []
		let sqls = []
		let idx = 0
		for (let item of cs) {
			if (item.content.toLowerCase() === 'union') {
				sqls.push(sql.substring(idx, item.start))
				idx = item.end
			}
		}
		sqls.push(sql.substring(idx, sql.length))
		//分解union子语句
		for (let item of sqls) {
			parts.push(this.explainSubSql(item))
		}

		return parts

	}
	explainSubSql(sql) {
		let sqlPart = {
			with: {
				idx: -1,
				content: null,
				ctes: [] //cte 虚拟表集合
			},
			unionType: '', //union类型，跟在原始sql union后面的关键字 union all
			select: {
				idx: -1,
				content: null
			},
			from: {
				idx: -1,
				content: null,
				tables: []
			},
			where: {
				idx: -1,
				content: null
			},
			group: {
				idx: -1,
				content: null
			},
			order: {
				idx: -1,
				content: null
			},
			limit: {
				idx: -1,
				content: null
			}
		}
		let cs = this.sqlSplit(sql)
		for (let item of cs) {
			let key = item.content.toLowerCase()
			if (sqlPart[key]) {
				sqlPart[key].idx = item.end
			}
		}

		if (sqlPart.select.idx > 6) {
			sqlPart.unionType = sql.substring(0, sqlPart.select.idx - 6).trim()
		}
		let keys = ['with', 'select', 'from', 'where', 'group', 'order', 'limit']
		for (let key of keys) {
			if (sqlPart[key].idx > -1) {
				sqlPart[key].content = sql.substring(sqlPart[key].idx, this.getSqlPartIndex(sqlPart, key, sql))
			}
		}
		let idx = 0
		let fs = sqlPart.from.content
		cs = this.sqlSplit(fs) //提取表名、别名键值对
		for (let item of cs) { //join 或 逗号 两侧为表
			let key = item.content.toLowerCase()
			if (key === 'join' || key === ',') { //只取前面的
				let sub = fs.substring(idx, item.start)
				let sub2 = sub.toLowerCase()
				for (let join of ('inner,left,right').split(',')) { //是否有
					idx = sub2.indexOf(join)
					if (idx > 0) {
						break
					}
				}
				//console.log(sub,idx)
				if (idx > 0) { //取表名 可能会有 table1 t1 inner join 的这种情况 ,提取出table1 t1 
					sub = sub.substring(0, idx)
				}
				this.getFromPartTable(sqlPart.from, sub)
				idx = item.end
			}
		}
		//取最后一段内容中的表名
		let sub = fs.substring(idx, fs.length)
		this.getFromPartTable(sqlPart.from, sub)

		//解析with中的成员,语法结构: cte表名 as (子查询)
		if (sqlPart.with && sqlPart.with.idx > 0) {
			let ctes = []
			let wps = this.sqlSplit(sqlPart.with.content)
			for (let i = 0; i < wps.length; i++) {
				let key = wps[i]
				if (key.content.toLowerCase() == 'as') {
					ctes.push({
						table: wps[i - 1].content,
						subSql: wps[i + 1].content
					})
				}
			}
			sqlPart.with.ctes = ctes
		}

		return sqlPart


	}
	getFromPartTable(fromObj, fromPart) { //提取取表名、别名
		let ps = this.sqlSplit(fromPart)
		let x = ps.length
		for (let i = 0; i < ps.length; i++) {
			if (ps[i].content.toLowerCase() === 'on') { //取关键字on 之前的为表名，on之后的是条件
				x = i
				break
			}
		}

		//排除join 前面有可能存在的left/right
		x = ps[x - 1].content.toLowerCase() === 'left' || ps[x - 1].content.toLowerCase() === 'right' ? x - 1 : x

		let item = { //完整的应用是三段 table as t
			tableName: ps[0].content,
			asName: ps[x - 1].content
		}
		 
		if(item.asName){//别名中如果含用. 取.后面的内容
			x=item.asName.indexOf('.')
			if(x>0){
				item.asName=item.asName.substring(x+1)
			}
		}
		item.type = item.tableName.indexOf(' ') > -1 ? 'sub' : 'table' //含有空格的一定是子查询
		fromObj.tables.push(item)
	}
	getSqlPartIndex(sqlPart, key, sql) {
		let keys = ['with', 'select', 'from', 'where', 'group', 'order', 'limit']
		let idx = -1
		for (let i = 0; i < keys.length; i++) {
			let ck = keys[i]
			if (ck === key) {
				idx = i
			} else {
				if (idx > -1 && sqlPart[ck] && sqlPart[ck].idx > -1) {
					return sqlPart[ck].idx - ck.length
				}
			}
		}
		return sql.length
	}
	getSql(sqlParts) {
		let rs = ''
		for (let i = 0; i < sqlParts.length; i++) {
			let item = sqlParts[i]
			let sql = i > 0 ? ' union ' + item.unionType + ' ' : ''
			if (item.with && item.with.idx > 0) {
				sql = sql + 'with ' + item.with.content + ' '
			}
			sql = sql + 'select ' + item.select.content + ' from ' + item.from.content
			if (item.where.content) {
				sql = sql + ' where ' + item.where.content
			}
			if (item.group.content) {
				sql = sql + ' group ' + item.group.content
			}
			if (item.order.content) {
				sql = sql + ' order ' + item.order.content
			}
			rs = rs + sql
		}
		return rs
	}
	setLogic(logic) {
		this.$logic = logic
	}

}

export default new DataBase()