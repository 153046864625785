<template>

	<div class="model-container">
		<template v-if="flowInsId">

			<flow-form @cancel="flowInsId=0" :model="{flowId:0,insId:flowInsId,taskId:0} " :project="project"
				:page="page" :host="host" :isedit="isedit" :datas="datas" />
		</template>
		<el-tabs v-if="tabName" v-show="!flowInsId" v-model="tabName" :type="model.tabType" style="height:100%;"
			@tab-change="tabChange">


			<template v-for="tab in tabList" :key="tab.key"><!-- tabItems -->
				<template v-if="tab.key=='add'  ">
					<el-tab-pane name="add" class="addtab-hide">
						<template #label>
							<span class="tab-label">
								<i class="far fa-edit"></i>
								<span>{{tab.tabLabel}}</span>
							</span>
						</template>
						<flow-form v-if="flowVersion" ref="flowFormAdd" @cancel="flowAddCancel"
							@success="flowAddSuccess" :model="{flowId:flowVersion.flowId,insId:0,taskId:0}"
							:project="project" :page="page" :host="host" :isedit="isedit" :datas="datas" />

					</el-tab-pane>
				</template>
				<el-tab-pane v-else-if="tab.key=='todo'" :name="tab.key">
					<template #label>
						<el-badge v-if="todoTotal && todoTotal >0" type="danger" :is-dot="false" :value="todoTotal"
							:max="3" :style="{'--badge-top-offset':'10px','--badge-right-offset':'10px'}">
							<span class="tab-label">
								<i :class="tab.icon"></i>
								<span>{{tab.tabLabel}}</span>
							</span>
						</el-badge>
						<span v-else class="tab-label">
							<i :class="tab.icon"></i>
							<span>{{tab.tabLabel}}</span>
						</span>


					</template>
					<template v-if="taskId">

						<flow-form @cancel="taskCancel" @success="taskCancel" :model="{flowId:0,insId:0,taskId:taskId} "
							:project="project" :page="page" :host="host" :isedit="isedit" :datas="datas" />
					</template>

					<commtable v-show="taskId==0" ref="todoList" @click.stop="setCurrentElement(tab.model)"
						@dataload="toDoload" :model="tab.model" :project="project" :page="page" :host="host"
						:isedit="isedit" :datas="datas" />


				</el-tab-pane>
				<el-tab-pane v-else :name="tab.key">
					<template #label>
						<span class="tab-label">
							<i :class="tab.icon"></i>
							<span>{{tab.tabLabel}}</span>
						</span>
					</template>

					<commtable @click.stop="setCurrentElement(tab.model)" :model="tab.model" :project="project"
						:page="page" :host="host" :isedit="isedit" :datas="datas" />


				</el-tab-pane>
			</template>


		</el-tabs>
		<!-- 
		<div v-if="! model.flowId" class="unset-info"> 请设置此组件绑定的流程 </div>
 -->

	</div>




</template>

<script>
	import flowType from './moduleset/flowType.js'
	import FlowForm from './FlowPage.vue'
	import FlowView from './FlowView.vue'
	//	import SubPage from '../layout/sub/SubPage.vue'
	import CommTable from '../report/CommTable.vue'
	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase],
		data() {
			return {
				todoTotal: 0,
				flowOpration: null,
				flowModel: null,
				//formConten:null,
				flowVersion: null,
				//	startNode: null,
				//	taskNode: null,
				//	flowIns: null,
				taskId: 0,
				flowInsId: 0,
				tabName: 'add',
				applyTab: {},
				tabMap: {},
				tabItems: [ //预设的标签项
					{
						key: 'add',
						name: '创建',
						icon: 'far fa-edit',
						model: null,
					},
					{
						key: 'todo',
						name: '待办',
						icon: 'far fa-user-clock',
						model: null,
						sql: 'select  f.id flowid,f.flowname,applyusername,applyorgpath,applytime,t.id taskid,t.nodename,t.typename,t.username,t.createtime,t.overtime from sys_flowtask t join sys_flowins f on t.flowinsid=f.id where f.classid=0 and t.userid={$userId:0} and t.taskstatus<10 order by t.id desc',
						queryed: 1 //如果值大于0，切换tab不自动查询，以减少不必要的查询次数
					},
					{
						key: 'apply',
						name: '我发起',
						icon: 'far fa-user-edit',
						sql: 'select f.id flowid,f.flowname,f.versioncode,f.flowstatus,f.statusinfo,f.applyuserid,f.applyusername,f.applyorgpath,f.edituserid,f.editusername,f.applytime,f.finishtime from sys_flowins f where f.classid=0 and (f.applyuserid={$userId:0} or f.edituserid={$userId:0} ) order by id desc',
						model: null,
						flowInsId: 0, //预留
						queryed: 0
					},
					{
						key: 'exist',
						name: '我参与',
						icon: 'far fa-user-check',
						sql: 'select f.id flowid,f.flowname,f.versioncode,f.flowstatus,f.statusinfo,f.applyuserid,f.applyusername,f.applyorgpath,f.edituserid,f.editusername,f.applytime,f.finishtime from sys_flowins f where f.classid=0 and f.id in (select flowinsid from sys_flowtask where userid={$userId:0}) order by f.id desc',
						model: null,
						flowInsId: 0,
						queryed: 0
					},
					{
						key: 'doing',
						name: '进行中',
						icon: 'far fa-hourglass-half',
						sql: 'select f.id flowid,f.flowname,f.versioncode,f.flowstatus,f.statusinfo,f.applyuserid,f.applyusername,f.applyorgpath,f.edituserid,f.editusername,f.applytime,f.finishtime from sys_flowins f where f.classid=0 and f.flowstatus<10  order by f.id desc',
						model: null,
						flowInsId: 0,
						queryed: 0
					},
					{
						key: 'finish',
						name: '已完成',
						icon: 'far fa-check',
						sql: 'select f.id flowid,f.flowname,f.versioncode,f.flowstatus,f.statusinfo,f.applyuserid,f.applyusername,f.applyorgpath,f.edituserid,f.editusername,f.applytime,f.finishtime from sys_flowins f where f.classid=0 and f.flowstatus=10 order by f.id desc',
						model: null,
						flowInsId: 0,
						queryed: 0
					},
					{
						key: 'cancel',
						name: '已取消',
						icon: 'far fa-ban',
						sql: 'select f.id flowid,f.flowname,f.versioncode,f.flowstatus,f.statusinfo,f.applyuserid,f.applyusername,f.applyorgpath,f.edituserid,f.editusername,f.applytime,f.finishtime from sys_flowins f where f.classid=0 and f.flowstatus=20 order by f.id desc',
						model: null,
						flowInsId: 0,
						queryed: 0
					},
					/* 	{
						key: 'err',
						name: '异常',
						icon: 'far fa-exclamation-triangle',
						model: null
					},
 */

				],

			}
		},

		methods: {
			/* toAddFlow() {
				this.flowOpration = "add"

			}, */
			flowAddSuccess(insId, flowNode) {
				this.$refs.flowFormAdd.loadPage()
			},
			flowAddCancel(insId, flowNode) {
				this.$logic.pop.confirm('确认', '将重置并清空所有填写的内容，是否确定', () => {
					this.$refs.flowFormAdd.loadPage()
				})

			},
			toDoload(data) {
				this.todoTotal = data.totalAll
			},
			taskCancel(id) {

				this.taskId = 0

				if (id == 0) { //点击取消按钮				

				} else { //提交成功后刷新列表
					this.$refs.todoList.doQuery()
				}
			},
			flowView(flowInsId) {
				this.flowInsId = flowInsId
			},
			workDo(taskId) {
				//切换选项卡
				this.tabName = 'todo'
				let user = this.$logic.getUser()
				//this.taskNode = null //置为空后 FlowForm组件即可 利用v-if 初始化重置
				this.$logic.http.get(true, '/api/flow/getflowtask/', {
					taskId: taskId,
					appId: this.project.id,
					sourceKey: this.project.dataSource.sourceKey,
				}, res => {
					let task = res.data.data

					if (task.userId == user.id) {
						if (task.finishTime) {
							this.$logic.tip.error('此工作项已于:' + task.finishTime +' 由 '+ task.userName + ' 处理结束')
						} else {
							this.taskId = taskId
							/*  let node = this.$logic.arrayUtil.itemByKey(this.flowModel.items, 'id', task
															.nodeKey)
														if (!node) {
															this.$logic.tip.error('此工作项所在的流程节点已失效，请刷新数据重试')
															return
														}
														this.$logic.http.get(true, '/api/flow/getflowins/', {
															flowInsId: task.flowInsId, //'10376000000000055', //
															dbSourceKey: this.project.dataSource.sourceKey,
														}, res => {
															this.flowIns = res.data.data
															this.taskNode = node
															this.taskNode.id = node.id
															this.taskNode.task = task
															this.taskNode.flowInsId = task.flowInsId
															this.taskNode.formId = task.formId
														})
							 */
							//this.flowOpration = 'task'
						}

					} else {
						this.$logic.tip.error('此工作项已失效，非您本人有所有，请刷新数据重试')
					}


				})
			},
			/* 			view(action, param) { //弹出流程处理对话框，流程Id或任务ID
							let user = this.$logic.getUser()

							switch (action) {
								case 'task':


									break
								default:
									this.flowOpration = null
									this.$logic.tip.error('错误的指令参数')
							}
							console.log(action, param)

						},
			 */
			tabChange(tabName) {
				if (this.tabMap[tabName]) {
					let model = this.tabMap[tabName].model
					if (this.isedit) {
						if (model) {
							setTimeout(() => {
								this.setCurrentElement(model)
							}, 200)
						}

					} else { //卡片切换刷新查询 
						if (model && model.$query && this.tabMap[tabName].queryed == 0) {
							model.$query()
							this.tabMap[tabName].queryed = 1
						}
					}

				}


			},
			updateQuerySet(model, dataSet) {
				for (let key in dataSet) {
					model.dataSet[key] = dataSet[key]
				}
			},
			setTabQuery(tabName, linkColName) {
				let model = this.model
				let objSet = this.$logic.clone.deepClone(flowType[tabName])
				let tab = this.tabMap[tabName]
				if (model.flowId) {
					objSet.sql = tab.sql.replace('f.classid=0 and ', 'f.classid=' + model.flowId + ' and ')
				} else {
					objSet.sql = tab.sql.replace('f.classid=0 and ', '')
				}
				this.updateQuerySet(tab.model, objSet)
				if (linkColName) {
					let item = this.$logic.arrayUtil.itemByKey(tab.model.dataSet.headItems, 'fieldName', linkColName)
					let panel = this.elementConfig.create('col', null, this.page.formData)
					panel.name = '单元格容器'
					panel.width = "100%"
					panel.alignH = 'center'
					panel.alignV = 'center'
					item.panel = panel
					item.mode = 'custom'

					let link = this.elementConfig.create('link', panel, this.page.formData)
					link.$DataMap = {
						value: {
							dataName: linkColName,
							setType: 'S'
						}
					}
					if (linkColName == 'taskid') {
						link.$events.$event_click.code = "page." + this.model.id + ".toTask(action.bindData.taskid)"

					} else {
						link.$events.$event_click.code = "page." + this.model.id + ".toView(action.bindData.flowid)"

					}
					link.$events.$event_click.status = 'Y'
				}
			},
			flushTabs() {
				//let tabName = this.tabName
				this.tabName = null
				setTimeout(() => {

					this.setTabQuery('todo', 'taskid')

					this.setTabQuery('apply', 'flowid')
					this.setTabQuery('exist', 'flowid')
					this.setTabQuery('doing', 'flowid')
					this.setTabQuery('finish', 'flowid')
					this.setTabQuery('cancel', 'flowid')

					this.tabName = 'todo'
				}, 100)
			},
			flowLoad() {
				let model = this.model
				this.flowModel = null
				this.flowVersion = null
				//this.startNode = null

				if (!model.flowId) {

					//this.$logic.tip.error('组件未绑定流程')
					return
				}
				this.$logic.http.flowModelLoad(this.elementConfig, !this.isedit, this.project, model.flowId, 0,
					res => {

						this.flowModel = res.flowModel
						/* this.startNode = res.startNode
						this.startNode.task = {
							id: 0
						} */
						this.flowVersion = res.flowVersion

						//this.applyTab.model.$loadContent(res.startNode.nodeForm.items[0],{flowDefId:model.flowId,flowVersionId:res.flowVerion.id,flowVersionCode:res.flowVerion.versionCode,flowInsId:0})

					})
				//console.log(flow)
			}

		},
		computed: {
			tabList() {
				let items = []
				for (let item of this.tabItems) {
					item.tabLabel = this.model[item.key + 'Label']
					if (this.model[item.key] === 'Y') { //开启了的情况下加入此分栏
						if (this.model.flowId && item.key == 'add') {
							items.push(item)
						} else if (item.model && 'add' != item.key) {
							items.push(item)
						}

					}

				}
				if (items.length > 0) {
					this.tabName = items[0].key
				}
				return items
			}
		},
		watch: {
			'model.flowId'(newValue, oldValue) {
				this.flowLoad()
				this.flushTabs()
			}
		},
		components: {
			commtable: CommTable,
			flowView: FlowView,
			flowForm: FlowForm,
			//	subPage: SubPage

		},
		mounted() {
			if (this.isedit) {
				//编辑模式下如果没有手工设置过查询配置，进行初始化
				if (!this.tabMap.todo.model.dataSet.sql) {
					this.flushTabs()
				}
			}
		},
		created() {
			if (this.model.flowId) {
				this.tabName = 'add'
			} else {
				this.tabName = 'todo'
			}
			if (!this.isedit) {
				this.model.$toView = this.flowView
				this.model.$toTask = this.workDo
			}
			let model = this.model

			if (!model.items) {
				model.items = []
			}

			for (let tab of this.tabItems) {
				let target = null
				for (let item of model.items) {
					if (tab.key === item.$hold) {
						target = item
						break;
					}
				}
				if (!target && this.isedit) { //设计模式才初始化新组件
					if (tab.key == 'add') {
						/* target = this.elementConfig.create('subpage', model, this.page.formData)
						target.$hold = tab.key
						target.name = '流程页面' */
					} else {
						target = this.elementConfig.create('commtable', model, this.page.formData)
						target.$hold = tab.key
						target.name = tab.name + '列表'
						target.autoQuery = tab.key === 'todo' ? 'Y' : 'N'
					}

				}
				tab.model = target
				this.tabMap[tab.key] = tab

			}

			this.applyTab = this.$logic.arrayUtil.itemByKey(this.tabItems, 'key', 'add')

			this.flowLoad()

		}
	}
</script>

<style scoped>
	.model-container {
		height: 100%;
		position: relative;

	}

	.flow-form {
		/* position: absolute;  */
		position: fixed;
		left: 0px;
		top: 0px;
		width: 100vw;
		height: 100vh;
		padding: 10vh 10vw 10vh 10vw;
		background-color: rgba(213, 213, 213, 0.5);
		z-index: 10;
		display: flex;
		/* justify-content: center;
		align-items: center; */

	}

	.form-title {
		height: 40px;
		padding: 0px 20px;
		line-height: 40px;
		/* background-color: #f8f8f9; */
		border-bottom: solid 1px #cccccc;
		display: flex;
		justify-content: space-between;
	}

	.form-dialog {
		/* position: absolute;  */

		width: 80vw;
		height: 80vh;
		background-color: #ffffff;
		border: 1px solid #cccccc;
		border-radius: 10px;


	}

	.form-dialog-title {
		height: 40px;
		padding: 0px 20px;
		line-height: 40px;
		background-color: #f4f4f5;
		border-radius: 10px 10px 0px 0px;
		display: flex;
		justify-content: space-between;
	}

	.form-dialog-close {
		font-size: 16px;
		cursor: pointer;
	}

	.form-dialog-close:hover {
		color: #ff0000;
	}


	.form-dialog-body {
		height: calc(100% - 40px);
		overflow: auto;
	}


	.tab-label {
		padding: 0px 20px 0px 20px;

	}

	.tab-label i {
		margin-right: 5px;
		font-size: 16px;
	}

	.unset-info {
		padding: 15px;
		font-size: 20px;
		color: #409EFF;
		text-align: center;

	}

	.addtab-show {
		display: none;
	}

	/* 	:deep(#tab-add) {
		display: var(--tabadd-show);
		height: 40px;
		line-height: 40px;
	}
 */
	/* 	:deep(.el-tabs__item.is-active) {
		border-bottom: 2px red solid;
	} */

	/* 	:deep(.el-tabs__nav-scroll) {
		display: flex;
		flex-direction: row-reverse;
	} */

	:deep(.el-tabs__item:nth-child(2)) {
		/* 消除组件自带的样式 */
		padding-left: 0px !important;
	}
</style>