import config from '../../../../config/config.js'
import BaseElement from '../../BaseElement.vue'
import CommEvent from '../../../../commevent.js'
import elementBase from '../../../../elementBase.js'
export default {
	mixins: [CommEvent, elementBase],
	props: {
		model: {
			type: Object,
			default: null,
		},
		datas: {
			type: Object,
			default: null,
		},
		isedit: {
			type: Boolean,
			default: false,
		},
		host: { //父页面
			type: Object,
			default: null,
		},
		page: {
			type: Object,
			default: null,
		},
		project: {
			type: Object,
			default: null,
		},


	},
	methods: {
		dataLoad() {
			let model = this.model 
			if ('listData' in model && model.dataOption && model.dataOption.jsonArray && model.dataOption.jsonArray
				.load == 'Y') {
				model.listData = []
			} else {
				return
			}
			let dataOption = model.dataOption ? model.dataOption : model.inputOption
			let sqlParam = dataOption.jsonArray.sql
			if (!sqlParam) { //SQL为空不作处理
				return
			}
			let isArray = dataOption.jsonArray.dataType = 'array'
			//let head=isArray?dataOption.jsonArray.heads
			 
			sqlParam = this.$logic.db.getParamSql(sqlParam, this.page.$params || {}, this.page, model)
	 
			let heads = dataOption.jsonArray.heads
			let sourceKey = dataOption.sourceKey ? dataOption.sourceKey : this.project.dataSource.sourceKey
			this.$logic.http.sqlQuery(sourceKey, sqlParam, [], {}).then(res => {
				let list = res.data.data.dataList
				let headMap = {}
				for (let i = 0; i < res.data.data.heads.length; i++) {
					let head = res.data.data.heads[i]
					headMap[head.label] = i
				}
				let items = []


				for (let row of list) {
					let rowData = {} //带回原始数据
					for (let key in headMap) {
						rowData[key] = row[headMap[key]]
					}
					let item = {
						$data: rowData
					}
					for (let head of heads) {
						let value = row[headMap[head.field]]
						if (isArray) {
							item[head.label] = value
						} else {
							item[head.key] = value
						}

					}
					items.push(item)
				}
		 
				if ('listData' in model) {
					this.doEvent({
						eventName: 'listLoadAfter',
						list: items,
					}, '$comp_listLoadAfter')

					//model.listData = items
					this.setData('listData', items)
				}


			}).catch(err => {
				this.$logic.tip.error('数据加载异常：' + err.info)
			})
		},


		initFormField(formField, comConfig = null, pageModel = null) {
			let formModel = null
			let p = formField.parent
			while (p != null) {
				if (p.type == 'form') {
					formModel = p
					break
				}
				p = p.parent
			}
			formField.minH = formModel.fieldMinHeight
			formField.marginT = formModel.fieldTop
			formField.marginB = formModel.fieldBottom
			let config = comConfig || this.getConfig()
			let page = pageModel || this.page.formData

			let labelArea = config.create('formlabel', formField, page)
			labelArea.width = formModel.labelWidth
			let compArea = config.create('formcomp', formField, page)
			compArea.minH = formModel.minHeight
			compArea.autoWidth = {
				...formModel.compWidth
			}
			//labelArea.group='layout'
			//compArea.group='layout' 
			labelArea.$hold = formField.type
			compArea.$hold = formField.type
			return compArea
		},
		getParentForm() { //根据当前元素向上查找form组件
			let p = this.model
			while (p != null) {
				if (p.type == 'form') {
					break
				}
				p = p.parent
			}
			return p
		},
		beforCreate(newComp) {
			let result = true
			let model = this.model
			let form = null
			if (newComp.type == 'tabitem' && this.model.type != 'tabpage') {
				this.$logic.tip.error('子标签页只可在标签页容器中创建')
				return false
			} else if (newComp.type === 'formfield') {
				if (this.model.type !== 'form') {
					form = this.getParentForm()
					if (!form) {
						this.$logic.tip.error('表单元素必须放置于表单容器内')
						return false
					}
				}

			}
			if (model.type == 'subpage') {
				this.$logic.tip.error('操作无效，子页面容器内不可创建表单元素')
				return false
			}
			if (newComp.type == 'form') {
				let pform = this.getParentForm()
				if (pform) {
					this.$logic.tip.error('不允许表单内创建表单')
					return false
				}

			}
			if (newComp.type == 'inputlist') {
				let p = model
				while (p != null) {
					if (p.type == 'form') {
						return true
					} else if (p.type == 'formcomp') {

					}
					p = p.parent
				}
				this.$logic.pop.message('警告', '子表编辑器未在表单容器中创建，将不能实现数据的自动处理')
				//this.$logic.tip.warning('警告：子表编辑器未在表单容器中创建，将不能实现数据的自动处理')
				return true
			}
			if (newComp.type == 'formfield') {
				let formModel = false
				let p = model
				while (p != null) {
					if (p.type == 'form') {
						formModel = true
						break
					} else if (p.type == 'formcomp') {
						this.$logic.tip.error('表单项元素容器内不允许创建表单项元素容器')
						return false
					}
					p = p.parent
				}
				if (!formModel) {
					this.$logic.tip.error('只允许在表单容器内创建表单元素')
					return false
				}
			}

			if (newComp.type == 'tabitem' && model.type != 'tabpage') {
				this.$logic.tip.error('标签页子项只能在标签页容器中创建')
				return false
			}

			return result
		},
		afterCreate(newObj) { //pageObj用于外部调用 ,pageObj=null
			if (newObj.type == 'formfield' && newObj.parent != null) { //增加父判断，防止由自动创建表单项产生递归调用
				this.initFormField(newObj)
			} else if ('isInput' in newObj && newObj.type != 'inputlist' && newObj.parent.type ==
				'form') { //表单下创建表单元素自动创建表单项

				newObj.parent.items.splice(newObj.parent.items.length - 1, 1) //清除表单下的新元素
				let config = this.getConfig()
				let formField = config.create('formfield', newObj.parent, this.page.formData) //创建无父节点的表单，为防止递归调用本函数
				//formField.parent=newObj.parent
				//newObj.parent.items.push(formField)
				let comp = this.initFormField(formField)
				newObj.parent = comp
				if ('width' in newObj) { //如果有宽度的元素默认撑满容器
					newObj.width = '100%'
				}
				if ('tipAllow' in newObj && 'validateSet' in newObj) { //如果有提示功能且元素具有校验功能，表单元素自动启用提示
					newObj.tipAllow = 'Y'
				}
				comp.items.push(newObj)

			} else if (newObj.type.indexOf('panel_') == 0 || newObj.type.indexOf('dv_') == 0) {
				let row = this.getConfig().create('row', newObj, this.page.formData)
				row.name = '编辑区'
				row.$hold = newObj.id
				row.height = '100%'
				if (newObj.type.indexOf('dv_') == 0) {
					row.paddingL = '10px'
					row.paddingR = '10px'
					row.paddingT = '10px'
					row.paddingB = '10px'
				}
				if (newObj.borderRadius) { //有外边框内边框设置为小一半的内圆边
					row.borderRadius = Math.round((parseInt(newObj.borderRadius) / 2)) + 'px'
				}
			} else if (newObj.type.indexOf('modal_') == 0 || newObj.type === 'carousel') {
				let row = this.getConfig().create('row', newObj, this.page.formData)
				row.name = '编辑区'
				row.$hold = newObj.id
				row.height = '100%'
			} else if (newObj.type == 'inputlist') { //初始化列 
				newObj.items = []
				for (let i = 0; i < 6; i++) {
					let head = this.getConfig().create('inputhead', newObj, this.page.formData)
					head.headName = String.fromCharCode(65 + i)
					head.headText = ''
					let foot = this.getConfig().create('listfoot', head, this.page.formData)
					head.foot = foot
				}
			} else if (newObj.type == 'submit') { //设置提交按钮的默认formId值
				let node = newObj.parent
				while (node) {
					if (node.type === 'form') {
						newObj.formId = '' + node.id
						break
					}
					node = node.parent
				}
			} else {
				this.createTabel(newObj)
			}
			if ('tipAllow' in newObj && 'validateSet' in newObj && newObj.parent.type == 'formcomp') {
				newObj.tipAllow = 'Y' //如果有提示功能且元素具有校验功能，并且在表单组件区中创建的元素默认开启提示
			}


		},
		createTabel(newObj) { //创建表格时需指定行列数
			if (newObj.type == 'table') {
				this.$logic.pop.input('插入表格容器', '请设定表格行数、列数,以逗号分隔', res => {
					//拆解两段数字，作容错处理

					let ns = ['', '']
					let idx = 0
					let cs = res.split('')
					for (let c of cs) {
						if (c >= '0' && c <= '9') { //0 – 9 ： 48 – 57
							ns[idx] = ns[idx] + c
						} else {
							idx++
							if (idx > 1) {
								break
							}
						}
					}

					let n1 = ns[0].length > 0 ? parseInt(ns[0]) : 1
					let n2 = ns[1].length > 0 ? parseInt(ns[1]) : 1
					//默认平均分布列宽
					let width = Math.floor(1000 / n2) / 10 + '%'
					for (let i = 0; i < n1; i++) {
						let tr = config.create('tr', newObj, this.page.formData)
						for (let j = 0; j < n2; j++) {
							let td = config.create('td', tr, this.page.formData)
							if (i == 0 && j < n2 - 1) {
								td.tdW = width
							}
							td.sel = false
							td.span = [
								[i, i],
								[j, j]
							] //占位行列位置，二维数组，占每行的列位置，多行的情况下每行列位置必定是相同的,因此数据简化为第一行为所占行号，起始、截止位置，第二行为列数起始、截止位置
						}
					}
					/* 
										let ts = this
										 setTimeout(()=>{
											 ts.$forceUpdate()
										 },100)
											 */

				})
			}
		},
		createObj(newComp, parent) {
			let checkResult = this.beforCreate(newComp)
			if (checkResult) {
				let p = parent || this.model
				let newObj = config.create(newComp.type, p, this.page.formData)
				if (newObj == null) {
					this.$logic.tip.error('未注册的元素类型，请在config文件中引用并初始化')
					return null
				}
				//this.$emit('create',p,newObj)////////////////

				this.afterCreate(newObj, checkResult)
				this.$store.commit('setEditElementObj', {
					parent: p,
					element: newObj,
					act: 'add'
				})
				this.$store.commit('setElement', newObj)
				//	console.log(newObj)
				return newObj
			} else {
				return null
			}

		},

		mouseUp(e, target) {
			if (this.isedit && this.model.group === 'layout') {
				//e.stopPropagation() //不阻止冒泡,以不影响组件的一些取消类操作
				let newComp = this.newComp
				if (!newComp.show) {
					return
				}
				this.$store.commit('clearCreateStatus') //清除待创建状态
				if (newComp.act == 'create') {
					let newObj = this.createObj(newComp, target, this.page.formData)
					if (newObj == null) {
						//alert('不存的元素类型：' + newComp.type)
						//alert('创建失败：' + newComp.text)
						return
					}
				} else if (newComp.act == 'move') {

					let obj = newComp.obj
					/* if (this.model.type == 'page' && !obj.items) { //表单元素拖到page上
						this.$logic.tip.error('表单元素必须放置在容器内')
						return
					} */
					let x = this.$logic.arrayUtil.getItemIndex(obj.parent.items, obj)
					obj.parent.items.splice(x, 1)

					let container = target || this.model
					container.items.push(obj)
					obj.parent = container //this.model
					this.$store.commit('setEditElementObj', {
						parent: null,
						element: null,
						act: 'flush'
					})
				}
			} else if (!this.isedit) {
				this.doEvent(e, '$event_mouseup')

			}
		},
		mouseDown(e, target) {
			if (this.isedit) {
				e.stopPropagation() //阻止冒泡
			} else {
				this.doEvent(e, '$event_mousedown')
			}
		},
		mouseClick(e, target) {
			if (this.isedit) {
				e.stopPropagation() //阻止冒泡
				let el = this.model
				if (el.parentSlot) {
					el = el.parent
				}
				this.$store.commit('setElement', el)
			} else {
				if (('enabled' in this.model) && this.model.enabled === 'N') {
					return
				}
				this.doEvent(e, '$event_click')
				this.toLink()
			}
		},
		mouseOver(e, target) {
			if (this.isedit) {
				e.stopPropagation() //阻止冒泡
				this.$store.commit('setMouserInbody', true)
				this.$store.commit('setHoverElement', this.model)
			} else {
				this.doEvent(e, '$event_mouseover')
			}
		},
		mouseLeave(e, target) {
			if (this.isedit) {
				e.stopPropagation() //阻止冒泡
				this.$store.commit('setMouserInbody', false)
				this.$store.commit('setHoverElement', null)
			} else {
				this.doEvent(e, '$event_mouseleave')
			}
		},
		setCurrentElement(element) {
			if (this.currentElement === element) {

			} else {
				this.$store.commit('setElement', element)
			}

		},
		dataTo(targets, data) { //targets:#1,#2,#3
			if (!data || !targets) {
				return
			}
			if (targets.length > 0) {
				let ids = targets.split(',')
				for (let key of ids) {
					if (!(key && key.length > 0)) {
						continue
					}
					if (key.indexOf('#') !== 0) { //容错处理
						key = '#' + key
					}
					let obj = this.page.$(key)
					if (obj && obj.$bind) {
						obj.$bind(data)
					}
				}
			}
		},
		bind(data, isDeep = true) { //是否向下传导
			if (isDeep) {
				this.bindData = data

				this.setBindData()
			} else {
				this.setBindData(data)
			}

		},
		/* 		setBindData(bindData) {
					let model = this.model
					let data = bindData || this.bindData
					let map = model.$DataMap
					if (data && map) {
						for (let key in map) {
							let cfg = map[key]
							if (cfg && cfg.dataName) {
								let dataName = cfg.dataName
								if (cfg.setType == 'S') { //直接赋值
									if (dataName in data) {
										this.model[key] = data[dataName]
									} else { //容错处理key值
										for (let dataKey in data) {
											if (dataKey.toLowerCase() == dataName.toLowerCase()) {
												this.model[key] = data[dataKey]
												break
											}
										}

									}
								} else { //表达式
									try {
										let js = ''
										let dataMap = data
										let result = null
										for (let dataKey in data) {
											js = js + 'let ' + dataKey + '=dataMap["' + dataKey + '"];'
										}
										js = js + 'result= ' + dataName + ';'
									
										eval(js)
										this.model[key] = result
									} catch (ex) {								
										//this.model[key] = ex
									}


								}

							}
						}
					}
				}, */
		createInit() {

			this.model.$bind = this.bind
			if (this.model.type === 'page' && this.page.$params) {
				this.bindData = {
					...this.page.$params
				}

			} else {
				this.bindData = this.datas
			}

			this.setBindData()
			if (!this.isedit && this.model.$hook_create) {
				this.doEvent({
					eventName: 'create'
				}, '$hook_create')
			}
		},
		getConfig() {
			return config
		},

	},

	computed: {


		layoutstyle() {
			let model = this.model
			///*公共样式计算属性中只处理在layout和font在存在的属性名称，如增加自定义属性需从这两者中取key值 或自行覆盖样式计算属性*/
			let css = this.$logic.getStyle(this.model, this
				.project) //,this.mouserInbody || this.isCurrent)//鼠标悬停和被选中时都有边框
			if (this.model.show !== 'show') { //如果是非显示状态，覆盖替换display的属性
				css.display = this.model.show
			} else { //显示模式下恢复display：flex		
				if ('display' in this.model) {
					css.display = this.model.display
				}
			}
			//css['outline'] ='solid 1px red' //'unset' //不显示焦点边框

			if (model.rotate && this.model.seconds) {
				css['--rotate-time'] = (this.model.seconds || 4) + 's'
			}
			if (model.ede) {
				css['transform'] = 'rotate(' + model.ede + 'deg)'
			}
			return css
		},
		layoutcss() {
			let css = null
			let model = this.model
			if (model.autoWidth) { //弹性宽度中允许用象素、比例、函数方式定义宽度值，值设置到容器元素变量，公共样式中引用变量
				let width = model.autoWidth
				css = [ //'col-xs-'+width.xs,'col-sm-'+width.sm,'col-md-'+width.md,'col-lg-'+width.lg,
					'col-xs-width', 'col-sm-width', 'col-md-width', 'col-lg-width'
				]
			} else {
				css = []
			}
			if (model.autoAlign) {
				css.push('col-xs-align')
				css.push('col-sm-align')
				css.push('col-md-align')
				css.push('col-lg-align')
			}
			if (model.rotate) {
				css.push(model.rotate)
			}
			if (this.isedit) {
				css.push('layout')
				if (this.page.showLayout) {
					css.push('layout-show')
				}

				/* if (this.model.type == 'formrow') { //编辑状态增加虚线
					css.push('layout-show')
				} */
				if (this.isHover) {
					css.push('chover')
				}
				if (this.isCurrent) {
					css.push('ccurrent')
				}
			} else {
				/* if (this.hasKeyEvent) {
					css.push('layoutkeybord') //允许接受键盘的事件时聚集是显示框线
				} */
			}
			return css
		},
		mouserInbody() {
			return this.$store.state.mouserInbody
		},
		hoverElement() {
			return this.$store.state.hoverElement
		},
		newComp() {
			return this.$store.state.newComp
		},

		isCurrent() {
			return this.currentElement === this.model
		},
		isHover() {
			return this.hoverElement === this.model
		},
		currentElement() {
			return this.$store.state.element
		},
		jsonList() { //数组转JSON对象数据，数组的第一行表头为键名
			let datas = this.jsonDataList
			if (datas.length > 0) { //如果本身就是对象，不作转换直接返回
				if (!Array.isArray(datas[0])) {
					return datas
				}
			}
			if (datas.length < 2) {
				return []
			}
			let rs = []
			let head = datas[0]
			for (let i = 1; i < datas.length; i++) {
				let row = datas[i]
				let data = {}
				for (let j = 0; j < head.length; j++) {
					let key = head[j]
					data[key] = row[j]
				}
				rs.push(data)

			}
			return rs
		},
		jsonDataList() { //用计算属性使得既可前端静态设置又兼容API取值
			let data = this.model.listData || this.model.jsonData;
			if (data) {
				if ((typeof data) == 'string') {
					try {
						return JSON.parse(data)
					} catch (e) {
						setTimeout(() => { //下一个周期弹出提示，否则VUE会出异常
							this.$logic.tip.error(this.model.name + '[' + (this.model.value || '') +
								']JSON数据格式错误')
						}, 100)
						return []
					}

				} else {
					return data
				}
			} else {
				return []
			}

		},
	},
	watch: {
		datas(newValue, oldValue) { //此方法只在基类中调用 ，否则实现类组件又会再次触发
			this.bindData = newValue
			if (this.superComp) { //防止被父组件与子组件多次调用此方法
				return
			}
			this.setBindData(newValue)
		},

	},
	components: {
		baseElement: BaseElement
	},

}