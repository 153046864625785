<template>
	<div v-if="flowNode "><!-- 流程创建 -->

		<template v-if="! flowIns || flowIns.id==0 ">

		</template>
		<template v-else-if="flowNode.task.id==0  ">
			<!-- 没有待办ID查看流程无操作选项 -->
		</template>
		<template v-else-if="flowNode.nodeType=='start'  ">
			<div class="decide-node">
				<span>
					<i class="far fa-map-marker-alt"></i>
					重新提交
				</span>

			</div>
			<div class="decide-bar">
				<div class="decide-row">
					<div class="decide-label">表决 </div>
					<div class="decide-comp">
						<el-radio-group v-model="decide">
							<el-radio label="R" value="R">继续提交</el-radio>
							<el-radio label="ZZ" value="ZZ">终止流程</el-radio>
						</el-radio-group>

					</div>

				</div>
				<div class="decide-row">
					<div class="decide-label">意见 </div>
					<div class="decide-comp">
						<el-input v-model="comments" :rows="3" type="textarea" placeholder="请填写意见" />
					</div>

				</div>
			</div>
		</template>
		<template v-else-if="flowNode.nodeType=='subflow'  ">
			<div class="decide-node">
				<span>
					<i class="far fa-map-marker-alt"></i>
					{{flowNode.name}}
				</span>

			</div>
			<div class="decide-bar">
				<div class="decide-row">
					<div class="decide-label">表决 </div>
					<div class="decide-comp">
						<el-radio-group v-model="decide">
							<el-radio label="C" value="C">发起流程</el-radio>
							<el-radio label="X" value="X">取消流程</el-radio>
							<el-radio label="ZJ" value="ZJ">工作转交</el-radio>
						</el-radio-group>

					</div>

				</div>
				<div v-show="allowSetUser " class="decide-row">
					<div class="decide-label">参与人 </div>
					<div class="decide-comp">
						<el-tag v-for="user in targetUsers" :key="user.key" :title="user.tip" closable
							@close="removeUser(user,targetUsers)">
							{{user.label}} </el-tag>
						<el-button type="success" size="small" @click="toSelectUser(targetUsers)">
							<i class="far fa-user-plus"></i>添加
						</el-button>
						<el-button type="default" size="small" @click="toClearUsers(targetUsers)">
							<i class="far fa-times"></i>清空
						</el-button>

					</div>
				</div>
				<div class="decide-row">
					<div class="decide-label">意见 </div>
					<div class="decide-comp">
						<el-input v-model="comments" :rows="3" type="textarea" placeholder="请填写意见" />
					</div>

				</div>
			</div>
		</template>
		<template v-else-if="flowNode.nodeType=='robot'  ">
			<div class="decide-node">
				<span>
					<i class="far fa-map-marker-alt"></i>
					{{flowNode.name}}重新执行
				</span>

			</div>
			<div class="decide-bar">
				<div class="decide-row">
					<div class="decide-label">表决 </div>
					<div class="decide-comp">
						<el-radio-group v-model="decide">
							<el-radio label="C">重新执行</el-radio>
							<el-radio label="B">终止执行</el-radio>

						</el-radio-group>

					</div>

				</div>

				<div class="decide-row">
					<div class="decide-label">意见 </div>
					<div class="decide-comp">
						<el-input v-model="comments" :rows="3" type="textarea" placeholder="请填写意见" />
					</div>

				</div>
			</div>
		</template>
		<template v-else-if="'single,multi'.indexOf(flowNode.nodeType)>-1">
			<div class="decide-bar">
				<div class="decide-node">
					<span>
						<i class="far fa-map-marker-alt"></i>
						{{flowNode.name}}

						<span v-if="flowNode.task && flowNode.task.overTime"
							style="margin-left:20px;">计划完成时间：{{flowNode.task.overTime}}</span>
					</span>

					<div v-if="otherTasks.length>0">
						<!-- <span style="margin:0px 10px 0px 30px;">同时还有：</span> -->
						<template v-for="item in otherTasks">

							<el-tag v-if="item.taskStatus<10" :effect="item.nextId>0?'light':'dark'" type=""
								class="user-item">
								<i :class="item.taskStatus==1?'far fa-user-check': 'far fa-user-clock'"></i>
								{{item.userName}}

							</el-tag>
							<template v-else><!-- 排除掉非表决型工作项 -->
								<el-tag v-if="item.decide=='Y' || item.decide=='N'" type="info" effect="plain"
									:title="decideMap[item.decide]+ '  ' +item.comments" class="user-item">
									{{item.userName}}
									<i
										:class="item.decide=='Y'?'far fa-check user-decide-yes':item.decide=='N'?'fas fa-times user-decide-no':''"></i>

								</el-tag>
							</template>


						</template>


					</div>

				</div>
				<div v-if="flowNode.task.comments" class="task-comments">
					<i class="fas fa-exclamation-triangle"></i>
					<span>
						{{flowNode.task.comments}}
					</span>
				</div>
				<div v-if="flowNode.task.taskType==0" class="decide-row">
					<div class="decide-label">表决 </div>
					<div class="decide-comp">
						<el-radio-group v-model="decide">
							<el-radio label="Y">同意</el-radio>
							<el-radio label="N">不同意</el-radio>
						</el-radio-group>
						<span class="decide-more"> </span>
						<el-radio-group v-model="decide">
							<el-radio v-for="item in decideAllow" :label="item.key"
								:title="item.title">{{item.name}}</el-radio>

						</el-radio-group>

					</div>

				</div>
				<div v-show="decide==='QS' " class="decide-row">
					<div class="decide-label">预计完成时间 <span v-if="flowNode.nodeRule.timeLock=='Y'">[已锁定]</span> </div>
					<div class="decide-comp">
						<el-date-picker v-model="overTime" :disabled="flowNode.nodeRule.timeLock=='Y'" type="datetime"
							value-format="YYYY-MM-DD HH:mm:ss" placeholder="请设置完成时间" />
					</div>
				</div>
				<div v-show="allowSetUser " class="decide-row">
					<div class="decide-label">参与人 </div>
					<div class="decide-comp">
						<el-tag v-for="user in targetUsers" :key="user.key" :title="user.tip" closable
							@close="removeUser(user,targetUsers)">
							{{user.label}} </el-tag>
						<el-button type="success" size="small" @click="toSelectUser(targetUsers)">
							<i class="far fa-user-plus"></i>添加
						</el-button>
						<el-button type="default" size="small" @click="toClearUsers(targetUsers)">
							<i class="far fa-times"></i>清空
						</el-button>

					</div>
				</div>
				<div v-show="decide==='TL' || decide==='TR'" class="decide-row">
					<div class="decide-label">选择流程节点 </div>
					<div class="decide-comp">

						<el-radio-group v-model="skipNodeKey">
							<template v-for="node in skipNodes">
								<el-radio :label="node.nodeKey" :value="node.nodeKey">{{node.nodeName}}</el-radio>
							</template>

						</el-radio-group>

					</div>
				</div>
				<div class="decide-row" style="align-items: start;">
					<div class="decide-label" style="display: flex;justify-content: end;">
						<div style="width:60px;">
							<el-dropdown @command="autoSetComments" trigger="click">
								<el-link type="primary">意见<i class="fas fa-caret-down"
										style="margin-left: 5px;font-size: 16px;"></i>

								</el-link>

								<template #dropdown>
									<el-dropdown-menu>
										<template v-for="item in defaultComments">
											<el-dropdown-item :command="item">{{item.comments}}</el-dropdown-item>
										</template>
									</el-dropdown-menu>
								</template>

							</el-dropdown>
						</div>
					</div>
					<div class="decide-comp">
						<el-input v-model="comments" :rows="3" type="textarea" placeholder="请填写意见" />
					</div>

				</div>

				<div v-show="flowNode && flowNode.nodeRule && (flowNode.nodeRule.sign ==='use' || flowNode.nodeRule.sign==='need') && (decide=='Y' ||
					decide=='N' )" class="decide-row">
					<div class="decide-label">签字 </div>
					<div class="decide-comp">
						<div class="sign-pad-audit">
							<sign-pad ref="signPad" @sign="sign" :option="padOption" :allow-edit="true" pad-width="90vw"
								padHeight="90vh" :designMode="false"></sign-pad>
						</div>

					</div>

				</div>
				<div v-show="flowNode && flowNode.nodeRule && (flowNode.nodeRule.upload ==='use' || flowNode.nodeRule.upload==='need') "
					class="file-panel">

					<el-tag v-for="(file,index) in fileList" :key="file" size="large" type="info" closable
						effect="plain" @close="toDeleteFile(file,index)" class="file-item">
						<div style="display: flex;">
							<i v-if="index>0" @click="fileSort(index,-1)" class="far fa-arrow-from-right file-move"></i>
							<el-link :href="file.fullUrl" target="_blank"
								type="primary">{{ file.fileName }}({{file.humanSize}})</el-link>
							<i v-if="index<fileList-1" @click="fileSort(index,1)"
								class="far fa-arrow-from-left file-move"></i>
						</div>

					</el-tag>
					<file-upload v-show="withSubmit" home="flowtask" :project="project" @success="fileUploaded">
						<el-button type="success" icon="Plus" size="default" style="margin: 5px;">
							上传文件
						</el-button>
					</file-upload>


					<!-- 
					<el-table :data="fileList" stripe border style="width: 100%;">
						<el-table-column prop="fileName" label="文件名">
							<template #default="scope">
								<el-link @click="fileClick(scope.row)" :href_="scope.row.url"
									target="_blank">{{ scope.row.fileName }}</el-link>
							 
							</template>
						</el-table-column>
						<el-table-column prop="humanSize" label="大小" width="120px" />
						<el-table-column prop="createTime" label="上传时间" width="180px" />
						<el-table-column prop="" label="删除" width="150px" align="center">
							<template #default="scope">
								<el-button @click="toDeleteFile(scope.row)" type="danger" icon="CloseBold"
									size="small">删除</el-button>
							</template>
							<template #header>
								<file-upload home="flowtask" :project="project" @success="fileUploaded">
									<el-button type="success" icon="Plus">上传文件</el-button>
								</file-upload>
							</template>
						</el-table-column>

					</el-table>
 -->
				</div>
			</div>

		</template>

		<div v-show="flowNode && flowNode.nodeRule && ((flowNode.nodeRule.userSet =='single' || flowNode.nodeRule.userSet =='multi') && flowNode.nodeRule.userVar ) "
			class="decide-row">
			<div class="decide-label">下一步参与人 </div>
			<div class="decide-comp">
				<el-tag v-for="user in nextUsers" :key="user.key" :title="user.tip" closable
					@close="removeUser(user,nextUsers)">
					{{user.label}} </el-tag>
				<el-button type="success" size="small" @click="toSelectUser(nextUsers)">
					<i class="far fa-user-plus"></i>添加
				</el-button>
				<el-button type="default" size="small" @click="toClearUsers(nextUsers)">
					<i class="far fa-times"></i>清空
				</el-button>

			</div>

		</div>


		<div class="flow-submit-tool">
			<div v-if="submitSucess || flowNode && flowNode.task && flowNode.task.finishTime" class="flow-submit-tool">
				<i class="far fa-check"></i> 已完成
			</div>
			<template v-else><!-- v-show="flowNode.task.id || flowNode.nodeType=='start'" -->
				<el-button v-show="withSubmit" @click="flowSubmit" :type="model.color" :plain="model.plain=='Y'"
					:round="model.round=='Y'" :circle="model.circle=='Y'" :loading="model.loading=='Y'"
					:size="model.size" :link="model.link=='Y'">
					<i v-if="model.loading=='N' && model.ico" :class="model.ico"></i>

					{{model.value}}
				</el-button>
			</template>

			<el-button @click="onCancel" :type="flowNode.task.id || flowNode.nodeType=='start'?'primary':'primary'"
				:plain="model.plain=='Y'" :round="model.round=='Y'" :circle="model.circle=='Y'" :size="model.size"
				:link="model.link=='Y'">
				<i class="far fa-reply"></i> 取 消
			</el-button>

			<el-button v-if="model.printerId" @click="toPrint" type="default" :plain="model.plain=='Y'"
				:round="model.round=='Y'" :circle="model.circle=='Y'" :size="model.size" :link="model.link=='Y'">
				<i class="far fa-print"></i> 打 印
			</el-button>


		</div>


		<el-tabs v-if="flowIns" v-model="cardItem">
			<el-tab-pane v-if="flowIns && flowIns.id>0" name="history">
				<template #label>
					<span class="tabs-label">
						<i class="far fa-user-clock icon16"></i>
						<span>审核记录</span>
					</span>
				</template>
				<div style="height:20px;"> </div>

				<el-timeline style="--el-timeline-node-size-large:15px;--el-font-size-small:15px;">

					<el-timeline-item v-for="item in recordList" :timestamp="item.finishTime +'   @'+item.nodeName"
						:icon="item.icon" :type="item.color" size="large" placement="top">
						<el-card>

							<h4>
								<span class="user-name">
									<i class="far fa-user-check  icon14"></i> {{item.userName}}
								</span>

								<el-tag
									:type="item.decide=='Y'?'success': item.decide=='N'?'danger':''">{{decideMap[item.decide]}}</el-tag>

							</h4>
							<!-- 	<div style="display: flex;">		
					</div> -->



							<p>
								{{item.comments}}
							</p>
							<div v-if="item.signId && item.signData" class="sign-pad-his">
								<sign-pad :option="padOption" pad-width="90vw" pad-height="90vh"
									:sign-data="item.signData" :allow-edit="false" :with-border="false"></sign-pad>
							</div>
							<div v-if="item.fileList && item.fileList.length>0">
								<el-tag v-for="(file,index) in item.fileList" :key="file" size="large" type="info"
									effect="plain" class="file-item">
									<el-link :href="file.fullUrl" target="_blank"
										type="primary">{{ file.fileName }}({{file.humanSize}})</el-link>

								</el-tag>
							</div>
						</el-card>
					</el-timeline-item>

				</el-timeline>

			</el-tab-pane>
			<el-tab-pane name="flow">
				<template #label>
					<span class="tabs-label">
						<i class="fad fa-chart-network icon16"></i>
						<span>流程监控</span>
					</span>
				</template>
				<div v-if="flowModel" style="overflow: auto;">
					<div class="flow-panel" :style="{minWidth:flowModel.width}">
						<flow-view :flow-model="flowModel" :flow-ins="workFlowViewIns" :project="project"
							@nodeopen="nodeOpen" @nodeclose="nodeClose" @taskchange="taskChange" />
					</div>
				</div>




			</el-tab-pane>
		</el-tabs>




	</div>
	<div v-else class="flow-submit-info">

		<el-button :type="model.color" :plain="model.plain=='Y'" :round="model.round=='Y'" :circle="model.circle=='Y'"
			:loading="model.loading=='Y'" :size="model.size" :link="model.link=='Y'">
			<i v-if="model.loading=='N' && model.ico" :class="model.ico"></i>

			{{model.value}}
		</el-button>

		<span class="flow-submit-tip">
			运行时根据当前节点动态渲染
		</span>

	</div>

	<logicdialog ref="userDialog" @ok="userDialogOk" title="用户选择" dialog-width="800px" dialog-height="80vh"
		icon="far fa-user-plus">
		<orguserchoose ref="userchoose" :max-size="0" @change="userSelected" />
	</logicdialog>
</template>

<script>
	import FileUpload from '../../../../comm/file/FileUpload.vue'
	import SignPad from '../../../../comm/input/SignPad.vue'
	import DecideTypes from './config/decideTypes.js'
	import FlowView from './FlowView.vue'
	import OrgUserChoose from '../../../../comm/business/OrgUserChoos.vue'
	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase],
		data() {
			return {
				flowInsNew: null, //用于更新流程显示状态
				//flowNode: null,
				fileList: [], //附件列表
				flowForm: null,
				decide: '',
				comments: '',
				overTime: null, //计划完成时间，用于柔性控制时效
				targetUsers: [],
				nextUsers: [],
				selTargetUsers: [],
				selectUsers: [],
				skipNodeKey: null,
				submitSucess: false,
				//flowIns: null,
				cardItem: 'history',
				signData: '',
				padOption: {
					dotSize: 3, // 3, //点的大小（在屏幕上点击留下的点的大小，单位为像素）。
					minWidth: 1, //  0.5, //线的最小宽度（单位为像素，默认值为0.5）。
					maxWidth: 10, //  2.5, //线的最大宽度（单位为像素，默认值为2.5）。
					throttle: 16, //  16, //节流（每次绘制两个点之间的时间，单位为毫秒，默认值为16，注意设置过大会导致绘制卡顿）。
					minDistance: 5, //  5, //最小距离（每次绘制两个点之间的最小距离，单位为像素，默认值为5）。
					backgroundColor: '#fff', //  '#000', //背景色（默认为黑色# 000）。
					penColor: '#000', //  '#fff', //线条颜色（默认为白色#fff）。
					velocityFilterWeight: 0.7, //  0.9, //根据速度控制线的粗细（默认值为0.7）。

				},
				commentsInit: [{
						decide: 'Y',
						comments: '同意，请相关部门按照规定执行'
					},
					{
						decide: 'Y',
						comments: '同意，请相关部门尽快办理'
					},
					{
						decide: 'Y',
						comments: '同意，但某些细节需要调整后再执行'
					},
					{
						decide: 'Y',
						comments: '同意，但需暂缓执行'
					},
					{
						decide: 'N',
						comments: '不同意，请申请部门重新梳理后再提交'
					},
					{
						decide: 'N',
						comments: '不同意，费用超出预算'
					},
					{
						decide: 'N',
						comments: '不同意，费用超出标准'
					},
					{
						decide: 'N',
						comments: '不同意，存在的风险点，需重新修改方案做好应对措施'
					},
					{
						decide: 'N',
						comments: '不同意，请对申请内容进行修改，并重新提交审批'
					},
					{
						decide: 'N',
						comments: '不同意，请补充相关材料后再提交审批'
					},
					{
						decide: 'N',
						comments: '不同意，此事项涉及专业领域，建议咨询相关专家后再做决定'
					},

				]




			}
		},

		methods: {
			/* fileClick(file) {
				let url = this.$logic.http.baseUrl + (file.url ? file.url : '/upload/' + file.localPath)
				window.open(url)
			}, */
			taskChange(user, taskItem) {
				this.flushFlowIns()
				//this.$emit('taskchange', node)
				if (this.model._onTaskChange) {
					this.model._onTaskChange(user, taskItem)
				}
			},
			nodeClose(node) { //WorkFlow传给FlowView

				this.flushFlowIns()
				if (this.model._onNodeClose) {
					this.model._onNodeClose(this.flowNode)
				}
			},
			nodeOpen(node) {

				this.flushFlowIns()
				//this.$emit('nodeopen', node)
				if (this.model._onNodeOpen) {
					this.model._onNodeOpen(this.flowNode)
				}
			},
			toPrint() {
				let obj = this.$logic.pageUtil.getElementById(this.page.formData, this.model.printerId)
				if (obj) {
					if (obj.$print) {
						obj.$print()
					} else {
						this.$logic.tip.error('设定的打印容器ID不是打印容器组件')
					}
				} else {
					this.$logic.tip.error('设定的打印容器ID不存在')
				}

			},
			autoSetComments(item) {
				this.comments = this.comments + item.comments
			},
			fileSort(index, offset) {
				let item = this.fileList[index]
				this.fileList[index] = this.fileList[index + offset]
				this.fileList[index + offset] = item
			},
			toDeleteFile(file, index) {
				this.fileList.splice(index, 1)
			},
			fileUploaded(dataItem, response, file, fileList) {
				for (let file of response.data) {
					file.fullUrl = this.$logic.http.baseUrl + (file.url ? file.url : '/upload/' + file.localPath)
					this.fileList.push(file)
				}

			},
			sign(data) {
				if (this.isedit) {
					return
				}
				this.signData = data
			},
			removeUser(user, userList) {
				let idx = this.$logic.arrayUtil.indexByKey(userList, 'key', user.key)
				if (idx > -1) {
					userList.splice(idx, 1)
				}
			},
			toClearUsers(userList) {
				this.$logic.pop.confirm('确认', '是否确定清空所有参与人', () => {
					userList.splice(0, userList.length)
				})
			},
			toSelectUser(users) {
				this.selTargetUsers = users
				if (this.$refs.userchoose) { //弹出窗口没弹出之前没有实例产生,加个判断
					this.$refs.userchoose.clearResult()
				}
				this.$refs.userDialog.show()
			},
			userDialogOk() {
				let user = this.$logic.getUser()
				for (let user of this.selectUsers) {
					if (user.key == user.id && this.selTargetUsers === this.targetUsers) {
						this.$logic.tip.error('不允许添加自已，已排除')
						continue
					}
					let tu = this.$logic.arrayUtil.itemByKey(this.selTargetUsers, 'key', user.key)
					if (!tu) {
						this.selTargetUsers.push(user)
					}
				}
				this.$refs.userDialog.hide()
			},
			userSelected(users) {
				this.selectUsers = users

			},
			onSuccess(result) {
				this.submitSucess = true
				if (this.model._onSuccess) {
					this.model._onSuccess(result)
				}
			},
			onError(err) {
				if (this.model._onError) {
					this.model._onError(err)
				}
			},
			onCancel() {

				if (this.model._onCancel) {
					this.model._onCancel(this.flowNode)
				}
			},
			flushFlowIns() {
				let flowIns = this.workFlowViewIns
				this.$logic.http.get(true, '/api/flow/getflowins/', {
						flowClassId: flowIns.classId,
						versionId: 0,
						flowInsId: flowIns.id,
						taskId: 0,
						dbSourceKey: this.dataSource.sourceKey,
					},
					res => {
						this.flowInsNew = res.data.data
					})

			},


			flowSubmit() {
				if (!this.flowForm) {
					this.$logic.tip.error('不存在的流程表单元素')
					return
				}
				if (this.flowNode.task.taskType == 0) { //待办工作提交
					if (!this.decide) {
						this.$logic.tip.error('请选择一种表决类型')
						return
					}
					if (this.allowSetUser && this.targetUsers.length < 1) {
						this.$logic.tip.error('请至少添加一个参与人')
						return
					}
					if (',ZJ,JH,'.indexOf(',' + this.decide + ',') > -1 && this.targetUsers.length > 1) {
						this.$logic.tip.error('此操作只允许选择一个参与人')
						return
					}

				}
				let nextUsers = []
				//普通表决的的情况下校验手选参与人
				if (this.flowNode.nodeRule.userVar) {
					if (this.flowNode.nodeRule.userSet == 'single') {
						if (this.nextUsers && this.nextUsers.length == 1) {
							nextUsers = this.nextUsers
						} else {
							if (this.decide === 'Y' || this.decide === 'N') {
								this.$logic.tip.error('下一步必须选择且只能选择一位参与人')
								return
							}

						}
					} else if (this.flowNode.nodeRule.userSet == 'multi') {
						if (this.nextUsers && this.nextUsers.length > 0) {
							nextUsers = this.nextUsers
						} else {
							if (this.decide === 'Y' || this.decide === 'N') {
								this.$logic.tip.error('下一步必须至少选择一位参与人')
								return
							}
						}
					}

				}
				for (let item of nextUsers) {
					item.id = item.key
					item.userName = item.label
				}

				let validate = this.flowForm.$validate()
				if (!validate.success) {
					this.flowForm.$validateInfo(validate)
					return
				}
				let beforeRs = this.flowForm._submitBefor()
				if (!beforeRs) {
					return
				}
				/* 	if (!validate.success) {
					if (this.flowForm.$functions && this.flowForm.$functions.$comp_validateFailed) {
						this.flowForm.doEvent({
							eventName: 'validatefailed',
							error: validate.info,
						}, '$comp_validateFailed')
					} else {
						let tips = '表单验证失败：'
						for (let info of validate.info) {
							tips = tips + info + ' 。 '
						}
						this.$logic.tip.error(tips)
						return
					}


				}
			
 */

				let formData = this.flowForm.$pick()
				let flowNode = this.flowNode
				let userList = []
				let userNames = ''
				let ex = ''
				if (this.allowSetUser) {
					for (let user of this.targetUsers) {
						userList.push({
							id: user.key,
							userName: user.label
						})
						if (userNames.length > 0) {
							userNames = userNames + ','
						}
						userNames = userNames + user.label
					}
					ex = ' [ ' + this.decideMap[this.decide] + ' ：' + userNames + ' ] '
				}

				switch (this.decide) { //预留处理
					case 'ZJ': //转交
					case 'JP': //'普通加签'
					case 'JQ': //'前置加签'
					case 'JH': //'后置加签'
					case 'JY': //加阅	
						break
					case 'TS': //退回上一步

					case 'TF': //退发起人
					case 'ZZ': //终止流程
						//ex = '[ ' + this.decideMap[this.decide] + ' ]'
						break
					case 'TL':
					case 'TR':
						if (!this.skipNodeKey) {
							this.$logic.tip.error('请选择跳转的流程节点')
							return
						}
						for (let node of this.skipNodes) {
							if (node.nodeKey === this.skipNodeKey) {
								ex = ex + ' 重定向至：[' + node.nodeName + ']'
								break
							}

						}

						break

				}


				let taskItem = {
					...flowNode.task
				}
				//taskItem.decide = this.decide
				//taskItem.comments = this.comments + ex






				let flowIns = this.flowIns
				let approve = {
					debugMode: this.model._debugMode ? true : false,
					//流程ID》版本》流程实例》节点》任务
					flowClassId: flowIns.classId, // 流程定义 ID:flowIns ? flowNode.flowDefId, 
					versionId: flowIns.versionId, //flowIns ? 流程版本ID: flowNode.versionId
					flowInsId: flowIns.id, //flowIns ?: flowNode.flowInsId0表示创建新流程实例，否则节点实例与任务实例ID必须大于0
					/* 	nodeId: flowNode.id,
						nodeName: flowNode.nodeName,
						nodeMode: flowNode.nodeMode,
						nodeType: flowNode.nodeType, */
					//flowNode: flowNode,
					decide: this.decide,
					comments: this.comments + ex,
					signData: this.signData,
					overTime: this.overTime,
					nodeKey: flowNode.id, //流程图中的节点ID
					nodeType: flowNode.nodeType,
					nodeInsId: flowNode.task.nodeId, //
					taskItem: taskItem, //值为零表示创建新流程
					skipNodeKey: this.skipNodeKey, //跳转节点 

					targetUsers: userList,
					nextUsers: nextUsers,
					fileList: this.fileList


				}
				//console.log(flowNode.task.id>0 || flowNode.task.flowInsId==0)
				let flowData = {
					formData: formData,
					approve: approve,
					appId: this.project.id,
					sourceKey: this.dataSource.sourceKey
				}
			
				if (this.model.ask === 'Y') {
					this.$logic.pop.confirm('确认', this.model.message, () => {
						this.doSubmit(flowData)
					})
				} else {
					this.doSubmit(flowData)
				}



			},
			doSubmit(flowData) {
				this.$logic.http.post(true, '/api/flow/flowsubmit/', null, flowData, res => {
					if (this.model.tell === 'Y') {
						this.$logic.tip.success(this.model.tip)
					}
					//this.$logic.tip.success('提交成功')
					this.flowForm._submitAfter()
					this.onSuccess(res.data.data)

				}, err => {
					let info = err.info
					this.$logic.tip.error(info)
					this.onError(err)
				})
			},

			/* 	dataInit(flowNode) {
					this.model._node = flowNode
					this.decide = ''
					console.log('button')
				} */

		},
		computed: {
			skipNodes() {
				let list = []
				if (!this.flowNode) {
					return list
				}
				let nodes = this.model._flowModel.items

				if (this.decide === 'TR') { //全部节点

					for (let node of nodes) {
						if (node.nodeMode === 'task' && node.id != this.flowNode.id) { //排除当前节点
							list.push({
								nodeKey: node.id,
								nodeName: node.name
							})
						}
					}

				} else if (this.decide === 'TL') {
					for (let node of nodes) { //保持节点的顺序
						for (let item of this.flowIns.flowNodes) {
							if (node.id === item.nodeKey && node.id != this.flowNode.id) {
								list.push({
									nodeKey: node.id,
									nodeName: node.name
								})
								break
							}
						}
					}

				}
			
				return list
			},
			workFlowViewIns() {
				return this.flowInsNew || this.flowIns
			},
			withSubmit() {
				//节点审批或流程发起状态时提交按钮可见
				return this.flowNode.task.id > 0 || this.flowNode.task.flowInsId == 0
			},
			flowModel() {
				return this.model._flowModel
			},
			flowNode() { // 				
				return this.model._node
			},
			flowIns() {
				return this.model._flowIns
			},
			allowSetUser() {
				return ',ZJ,JQ,JH,JY,'.indexOf(',' + this.decide + ',') > -1
			},
			/* 	lastApproveMap() { //各节点的最后审批人集合
					let nodeMap = {}
					if (this.flowIns && this.flowIns.flowTasks) {
						for (let task of this.flowIns.flowTasks) {
							let nodeKey = task.nodeKey
							if (nodeKey in nodeMap) {
								let item = nodeMap[nodeKey]
								if (task.finishTime > item.finishTime) {
									nodeMap[nodeKey] = task
								}

							} else {
								nodeMap[nodeKey] = task
							}

						}

					}
					return nodeMap

				}, */
			/* lastApproveList() { //各节点的最后审批人列表 
				let list = []

				return list
			}, */
			recordList() {
				let list = []
				if (this.flowIns && this.flowIns.flowTasks) {
					//console.log(this.flowIns)

					for (let task of this.flowIns.flowTasks) {
						if (task.taskStatus >= 10) {
							let node = this.$logic.arrayUtil.itemByKey(this.flowIns.flowNodes, 'id', task.nodeId)
							if (node) {
								task.nodeName = node.nodeName
							}
							task.icon = 'Clock'

							task.color = task.decide == 'N' ? 'danger' : 'success'
							list.push(task)
						}
						//查找本节点下的文件
						task.fileList = []
						for (let file of this.flowIns.flowTaskFiles) {
							if (file.taskId == task.id) {
								file.fullUrl = this.$logic.http.baseUrl + '/upload/' + file.localPath
								task.fileList.push(file)

							}
						}
						task.decideText = this.decideMap[task.decide] || ''
					}

					//最终审批记录集合
					if (this.model.lastTo) {
						let nodeMap = {}
						if (this.flowIns && this.flowIns.flowTasks) {
							for (let task of this.flowIns.flowTasks) {
								if (task.taskStatus < 10) {
									continue
								}
								let nodeKey = task.nodeKey
								if (nodeKey in nodeMap) {
									let item = nodeMap[nodeKey]
									if (task.finishTime > item.finishTime) {
										nodeMap[nodeKey] = task
									}

								} else {
									nodeMap[nodeKey] = task
								}

							}

						}

						let ids = this.model.lastTo.split(',')
						for (let objId of ids) {
							objId = objId.trim()
							if (objId) {
								let obj = this.$logic.pageUtil.getElementById(this.page.formData, objId)
								if (obj) {
									obj.$bind(nodeMap, true)
									//console.log(obj)
								}
							}
						}
					}


				}
				if (list.length > 0) {
					list[list.length - 1].icon = 'CaretTop'
				}
				if (list.length > 1) {
					list[0].icon = '' // 'CaretBottom'
				}

				this.dataTo(this.model.dataTo, list)



				return list
			},
			otherTasks() {
				let list = []

				if (this.flowIns && this.flowIns.flowTasks) {
					let flowNode = this.$logic.arrayUtil.itemByKey(this.flowIns.flowNodes, 'id', this.flowNode.task.nodeId)

					if (flowNode) {
						for (let task of this.flowIns.flowTasks) {
							if (task.nodeId == flowNode.id && task.taskStatus <
								20
							) { //&& task.id != this.flowNode.task.id //&& (task.decide=='' || task.decide=='Y' || task.decide=='N' )
								list.push(task)
							}
						}
					}

				}


				if (this.project.userList) { //获取工作项用户级别
					for (let task of list) {
						task.userLevel = 0 // 容错处理，如果没有设置，初始化为0
						for (let user of this.project.userList) {
							if (task.userId == user.id) {
								task.userLevel = user.tag
								break
							}
						}
					}
				}
				/* list = this.$logic.arrayUtil.sort(list, -1, (p1, p2) => {
					return p1.taskStatus < p2.taskStatus ? -1 : 1
				}) */
				//排序以级别、主从、状态排序
				this.$logic.arrayUtil.sort(list, -1, 'taskStatus')
				//this.$logic.arrayUtil.sort(list, -1, 'nextId')
				this.$logic.arrayUtil.sort(list, 1, 'userLevel')
				/* list = this.$logic.arrayUtil.sort(list, -1, (p1, p2) => { //倒序排列
					let comp = p1.userLevel < p2.userLevel ? -1 : 1
					//console.log(p1.userLevel, p2.userLevel, comp)
					return comp
				})
				 */



				return list
			},
			decideAllow() {
				let list = []
				let allows = this.flowNode.nodeRule.operations
				for (let item of DecideTypes) {
					if (item.public === true) {
						list.push(item)
					} else {
						for (let op of allows) {
							if (op === item.key) {
								list.push(item)
								break
							}
						}
					}

				}
				return list
			},
			decideMap() {
				let map = {
					Y: '同意',
					N: '不同意',
					A: '发起',
					R: '重新提交',
					T: '跳过',
					C: '成功',
					B: '失败',
					X: '取消'
				}
				for (let item of DecideTypes) {
					map[item.key] = item.name
				}
				return map
			},
			defaultComments() {
				let list = []
				for (let item of this.commentsInit) {
					if (item.decide == this.decide) {
						list.push(item)
					}
				}
				return list
			}

		},
		watch: {
			/* 'model._node'(newValue, oldValue) {
				this.initFlowNode()
			} */
		},
		components: {
			orguserchoose: OrgUserChoose,
			flowView: FlowView,
			SignPad: SignPad,
			FileUpload: FileUpload,

		},
		mounted() { //流程创建模式切换选项卡
			if (this.flowIns && this.flowIns.id == 0) {
				this.cardItem = 'flow'
			}

		},
		created() {
			//this.model.$setFlowNode = this.setFlowNode
			//	this.dataInit()
			//	this.$dataInit = this.dataInit
			//查找表单元素
			if (this.flowNode) {
				if (this.flowNode.task && this.flowNode.task.overTime) {
					this.overTime = this.flowNode.task.overTime
				}
				let forms = this.$logic.pageUtil.getElements(this.flowNode.nodeForm.items, '.form', true)
				if (forms.length > 0) {
					this.flowForm = forms[0]
				} else {
					this.$logic.tip.error('未检测到流程页面中的表单元素')
				}

			}



		}
	}
</script>

<style scoped>
	.flow-submit-info {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 10px;
		background-color: #f0f0f0;
		height: 100px;
		color: #409EFF;

	}

	.flow-submit-tip {
		color: #616161;
	}

	.decide-bar {
		padding: 10px 20px;
		/* border: 1px solid #cccccc; */
		border-radius: 5px;
	}

	.decide-node {
		border-left: 5px solid #409EFF;
		background-color: #f8f8f8;
		padding: 10px 20px;
	}

	.user-item {
		margin: 5px 10px;
	}

	.user-item i {
		font-size: 16px;
	}

	.task-comments {
		margin: 10px 0px;
		padding: 10px;
		background-color: rgb(251, 255, 175);
		border: solid 1px #cccccc;
		border-radius: 5px;
	}

	.task-comments i {
		color: #ffaa00;
		margin-right: 10px;
	}

	.decide-row {
		margin-top: 10px;
		display: flex;
		align-items: center;
	}

	.decide-label {
		width: 90px;
		padding: 10px;
		text-align: right;
		/*  */
	}

	.decide-comp {
		width: calc(100% - 130px);

	}

	.decide-comp>.el-tag {
		margin-right: 10px;
	}

	.decide-more {
		margin: 0px 15px;
		border-left: 2px solid #409EFF;
	}

	.flow-submit-tool {
		display: flex;
		justify-content: center;
		padding: 10px;
	}

	.icon16 {
		margin-right: 5px;
		font-size: 16px;
	}

	.icon14 {
		margin-right: 5px;
		font-size: 14px;
	}

	.user-name {
		margin-right: 15px;
	}

	.user-decide-yes {
		color: #67C23A;
	}

	.user-decide-no {
		color: #F56C6C;
	}

	.user-decide-other {
		color: #409EFF;
	}

	.flow-panel {
		/**/
		display: flex;
		justify-content: center;
		width: 100%;
		padding-top: 20px;
		overflow: auto;
	}

	.sign-pad-audit {
		width: 300px;
		height: 100px;
		margin: 10px 0px;
	}

	.sign-pad-his {
		width: 200px;
		height: 60px;
		margin: 10px 0px;
	}

	.file-panel {
		margin-top: 10px;
		display: flex;
		flex-wrap: wrap;
		padding: 5px;
		border: solid 1px #cccccc;
		border-radius: 5px;
		background-color: #f8f8f8;
	}

	.file-item {
		margin: 5px;
		--file-move: none;
	}

	.file-item:hover {
		--file-move: block;
	}

	.file-move {
		display: var(--file-move);
		cursor: pointer;
		font-size: 16px;
		color: #409EFF;
		margin: 0px 5px;
	}

	/* 	.tabs-label {
		margin-left: 20px;
	}
 */
	:deep(.el-tabs__item) {
		padding: 0px 10px !important;
	}

	:deep(.el-card__body) {
		padding: 0px 20px;
	}
</style>