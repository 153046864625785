<template>
	<treelistdialog ref="dialog" :node-list="nodeList" :query-result="queryData" :data-text="userItem.userName"
		text-field="姓名" :reset="true" size="size" :title="dialogTitle" :text-path="textPath" :icon="userIcon"
		placeholder="请选择用户" :disabled="disabled" :width="dialogWidth" :height="dialogHeight" split-char="/"
		:before-oK="beforeOk" @open="dialogOpen" @select="userSelect" @query="toQuery" />

</template>

<script>
	import options from '../../form/config/public/options.js'
	import TreeListDialog from './TreeListDialog.vue'
	export default {
		props: {
			userItem: {
				type: Object,
				default: {}
			},
			disabled: {
				type: Boolean,
				default: false
			},
			size: {
				type: String,
				default: 'default'
			},
			dialogTitle: {
				type: String,
				default: '组织机构选择'
			},
			icon: {
				type: String,
				default: 'far fa-user-check'
			},
			dialogWidth: {
				type: String,
				default: '1000px'
			},
			dialogHeight: {
				type: String,
				default: '80vh'
			},
			nodeSql: {
				type: String,
				default: null
			},
		},
		emits: ['select'],
		data() {
			return {

				nodeList: [],
				queryData: {
					heads: [{
							"label": "ID",
							"fieldName": "id",
							"with": "100px"
						}, {
							"label": "姓名",
							"fieldName": "username",
							"with": ""
						}, {
							"label": "编号",
							"fieldName": "empcode",
							"with": ""
						}, {
							"label": "手机",
							"fieldName": "mobile",
							"with": ""
						}

					],
					dataList: []
				}
			}
		},

		methods: {

			userSelect(user) {
				let data = this.userItem
				let node = user.treeNode
				data.nodeId = node.id
				data.nodeText = node.label
				data.nodeTextPath = node.textPath
				data.nodeIdPath = node.idPath
				data.userId = user.listItem.id
				data.userName = user.listItem.username 
				this.$emit('select', user)
			},
			toQuery(param) {
				this.queryData.dataList = []
				let sql = options.userSql
				sql = sql.replace('$id', param.id).replace('$keyword', param.keyWord)
				
				this.$logic.http.sqlQuery('@', sql, [], {
					isJson: false
				}).then(res => {
					this.queryData.dataList = res.data.data.dataList
				}).catch(err => {
					this.$logic.tip.error('数据请求异常：' + err.info)
				})

			},
			dialogOpen() {
				this.$refs.dialog.show()
				this.nodeList = []
				let sql = this.nodeSql ? this.nodeSql : options.orgSql
			
				this.$logic.http.sqlQuery('@', sql, [], {
					isJson: true
				}).then(res => {
					this.nodeList = res.data.data.dataList

				}).catch(err => {
					this.$logic.tip.error('数据请求异常：' + err.info)
				})
			},
			beforeOk() {
				return true
			}
		},
		computed: {
			textPath() {
				let path = this.userItem && this.userItem.userId ? this.userItem.nodeTextPath + '/' + this.userItem
					.userName : ''
				return path
			},
			userIcon() {
				let icon = this.icon
				if (this.disabled && icon == 'far fa-user-check') {
					icon = 'far fa-user-lock'
				}				 
				return icon
			}
		},
		components: {
			treelistdialog: TreeListDialog
		},
		created() {
 

		}
	}
</script>

<style scoped>

</style>