<template>
	<!-- -->
	<el-dialog title="" v-model="dialog.visible" :draggable="true" :show-close="showTitle" :close-on-click-modal="false"
		:width="dialogWidth || dialog.width" :top="dialogTop?dialogTop:dialog.fullHeight?'10px':dialog.top" @open="open"
		:before-close="beforClose">
		<template #header>
			<div v-if="showTitle" class="custom-head" :style="dialogClass">
				<i v-if="dialog.icon || icon"
					:class="dialog.icon?dialog.icon: icon.indexOf('iconfont')==0? icon:icon.indexOf('fa')==0? icon:  ('fal fa-'+icon)"
					style="margin-right: 5px;font-size: 18px;"></i>

				{{dialog.title}}
			</div>
		</template>
		<div :style="bodySize" :class="bodyCss">
			<slot></slot>
		</div>
		<template #footer>
			<div class="dialog-footer" :style="{textAlign:buttonLayout}">
				<el-button v-if="showCancel" :type="cancelType" @click="beforClose">
					<i :class="cancelIcon"></i>
					{{cancelText}}</el-button>

				<el-button v-for="button in buttons" :type="button.type" :loading="button.loading=='Y'"
					:disabled="button.enabled=='N'" @click="buttonClick(button)">
					<i :class="button.icon"></i>
					{{button.text}}</el-button>

				<el-button v-if="showOk" :type="okType" @click="dialogOk">
					<i :class="okIcon"></i>
					{{okText}}</el-button>  
			</div>
		</template>
	</el-dialog>
</template>

<script>
	export default {
		props: {
			showTitle: {
				type: Boolean,
				default: true
			},
			title: {
				type: String,
				default: ''
			},
			icon: {
				type: String,
				default: ''
			},
			titlebg: {
				type: String,
				default: '#f4f4f5'
			},
			autoClose: {
				type: Boolean,
				default: true
			},
			dialogWidth: {
				type: String,
				default: null
			},
			dialogHeight: {
				type: String,
				default: null
			},
			dialogTop: {
				type: String,
				default: '5vh'
			},
			buttonLayout: { //按钮排布
				type: String,
				default: 'right'
			},
			showCancel: { //取消按钮
				type: Boolean,
				default: true
			},
			cancelIcon: {
				type: String,
				default: ''
			},
			cancelText: {
				type: String,
				default: '取 消'
			},
			cancelType: {
				type: String,
				default: 'default'
			},

			showOk: {
				type: Boolean,
				default: true
			},
			okIcon: {
				type: String,
				default: ''
			},
			okText: {
				type: String,
				default: '确 定'
			},
			okType: {
				type: String,
				default: 'primary'
			},
			buttons: {
				type: Array,
				default: []
			}
		},
		emits: ['ok', 'init', 'close', 'open', 'buttonclick'],
		data() {
			return {
				dialog: {
					title: "标题",
					//confirmText:'确 定',
					icon: null,
					visible: false,
					width: "60%",
					top: "10vh",
					minHeight: "40vh",
					maxHeight: "90vh",
					fullHeight: false,
					topBottomPadding: '150px' //当fullHeight为true时生效,上下合计空白
				}

			}
		},

		methods: {
			show() {
				this.dialog.visible = true
			},
			hide() {
				this.dialog.visible = false
			},
			beforClose(done) {
				if (this.autoClose) {
					this.dialog.visible = false
					this.$emit("close", this.dialog)
				} else {
					this.$emit("close", this.dialog)

				}

				return false
			},
			/* 	close() {
					
				}, */
			open() {
				this.$emit("open", this.dialog)
			},
			dialogOk(event) {
				this.$emit("ok", this.dialog, event)
			},
			buttonClick(button) {
				this.$emit("buttonclick", button)
			}
		},
		computed: {
			bodySize() {
				let css = {}
				let outSize = this.showCancel || this.showOk ? 150 : 80
				if (!this.showTitle) {
					outSize = outSize - 52
				}
				outSize = outSize + 'px'
				if (this.dialogHeight || this.dialog.height) {
					css['--dialog-height'] = 'calc(' + (this.dialogHeight || this.dialog.height) + ' - ' + outSize + ')'
				} else if (this.dialog.fullHeight) {
					css['--dialog-height'] = 'calc(100vh - ' + this.dialog.topBottomPadding + ')'
				} else {
					css.minHeight = 'calc(' + this.dialog.minHeight + ' - ' + outSize + ')'
					css.maxHeight = 'calc(' + this.dialog.maxHeight + ' - ' + outSize + ')'
				}
				return css
			},

			bodyCss() {
				let css = ['dialogbody']
				if (this.dialogHeight || this.dialog.height) {
					css.push('fixheight')
				} else if (this.dialog.fullHeight) {
					css.push('fullheight')
				}
				/* if (!this.showTitle) {
					css.push('notitlebody')
				} */
				return css
			},
			dialogClass() {
				let css = {}
				css['--head-bg-color'] = this.titlebg
				return css
			}
		},
		watch: {
			title(newValue, oldValue) {
				this.dialog.title = this.title
			}

		},
		/* mounted() {

			setTimeout(() => {
				this.show()
				setTimeout(() => {
					this.hide()

				}, 10)
			}, 10)

		}, */
		created() {
			this.dialog.title = this.title
			this.dialog.icon = this.icon
			this.$emit("init", this.dialog)

		}
	}
</script>

<style scoped>
	.dialogbody {
		/* box-shadow: 0px 0px 10px 0px #DDDDDD ; */
		background-color: #fcfcfc;
		overflow: auto;

	}

	.notitlebody {
		border-radius: 20px 20px 0px 0px;
	}

	.fullheight {
		height: var(--dialog-height);
	}

	.fixheight {
		height: var(--dialog-height);
	}

	.dialog-footer {
		text-align: center; 
	}

	.custom-head {
		text-align: left;
		height: 30px;
		padding: 10px 20px 0px 20px;
		border-radius: 10px 10px 0px 0px;
		background-color: var(--head-bg-color);
	}

	/* :deep(.el-dialog__header){
		background-color: #ffff00;
		
	} */
	 
</style>