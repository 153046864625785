<template>
	<treeviewdialog :node-list="nodeList" :text-path="orgPath" :reset="true" :size="size" :title="dialogTitle"
		:icon="icon" :placeholder="placeholder" :disabled="disabled" :width="dialogWidth" :height="dialogHeight"
		split-char=" / " :before-oK="beforeOk" @open="dialogOpen" @select="nodeSelect" />

</template>

<script>
	import options from '../../form/config/public/options.js'
	import TreeViewDialog from './TreeViewDialog.vue'
	export default {
		props: {
			treeNodes: {
				type: Array,
				default: null
			},
			disabled: {
				type: Boolean,
				default: false
			},
			dialogTitle: {
				type: String,
				default: '组织机构选择'
			},
			placeholder: {
				type: String,
				default: '请选择组织'
			},
			size: {
				type: String,
				default: 'default'
			},
			icon: {
				type: String,
				default: 'far fa-folder-tree'
			},
			dialogWidth: {
				type: String,
				default: '800px'
			},
			dialogHeight: {
				type: String,
				default: '80vh'
			},
			nodeSql: {
				type: String,
				default: null
			},
			orgNode: {
				type: Object,
				default: {
					text: '',
					textPath: '',
					id: '',
					idPath: ''
				}


			}
		},
		emits: ['select'],
		data() {
			return {
				nodeList: [],

			}
		},

		methods: {
			nodeSelect(node) {
				this.orgNode.id = node.id
				this.orgNode.text = node.label
				this.orgNode.textPath = node.textPath
				this.orgNode.idPath = node.idPath
				this.$emit('select', node)

			},
			dialogOpen() {
			
				if (this.treeNodes ) { //如果指定了树数据，使用指定数据，否则动态加载
					this.nodeList = this.treeNodes
					
					return
				}
				this.nodeList = []
				let sql = this.nodeSql ? this.nodeSql : options.orgSql
				
				this.$logic.http.sqlQuery('@', sql, [], {
					isJson: true
				}).then(res => {	
					this.nodeList =res.data.data.dataList//this.$logic.arrayUtil.parseTree(res.data.data.dataList) 

				}).catch(err => {
					this.$logic.tip.error('数据请求异常：' + err.info)
				})
			},
			beforeOk() {
				return true
			}
		},
		computed: {
			orgPath() {
				return this.orgNode.textPath
			}
		},
		components: {
			treeviewdialog: TreeViewDialog
		},
		created() {


		}
	}
</script>

<style scoped>

</style>