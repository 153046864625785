/* function deepClone(source){
  const targetObj = source.constructor === Array ? [] : {}; // 判断复制的目标是数组还是对象
  for(let keys in source){ // 遍历目标
    if(source.hasOwnProperty(keys)){
      if(source[keys] && typeof source[keys] === 'object'){ // 如果值是对象，就递归一下
        targetObj[keys] = source[keys].constructor === Array ? [] : {};
        targetObj[keys] = deepClone(source[keys]);
      }else{ // 如果不是，就直接赋值
        targetObj[keys] = source[keys];
      }
    }
  }
  return targetObj;
} 
export default 1//deepClone
 */
class DeepClone {
	constructor() {

	}
	cloneModel(model,data) { //克隆页面元素对象
		let excludFields = ['parent', '$linkObj', '$sqlLink']
		let refsFields = ['$linkObj', '$sqlLink']
		let obj= this.deepClone(model, excludFields, refsFields)
		this.setParent(obj,data)
		return obj
	}
	setParent(obj, bindDataItem = null) { //为克隆出来的子元素设置parent值,如果bindDataItem传值，表示将对象都标识为模板内元素,且绑定到此数据对象
		if (obj && obj.items) {
			//let id = new Date().getTime()
			let sk = [obj]
			while (sk.length > 0) {
				let node = sk.pop()
				if (bindDataItem) {
					node.$bindData = bindDataItem
				}
				//node.id = id++
				if (node.items) {
					for (let sb of node.items) {
						sb.parent = node
						sk.push(sb)
					}
				}
			}
		}
	}
	deepClone(source = null, exclude = null, refs =
		null) { //简单复制 ,exclude 排除属性,字符串数据,或字符串数组, refs 需源带入引用值的属性，refs是exclude的一部分
		if (source == null) {
			return null
		}
		const targetObj = source.constructor === Array ? [] : {}; // 判断复制的目标是数组还是对象
		for (let keys in source) { // 遍历目标
			if (exclude) { //排除指定的属性 
				if (exclude.indexOf(keys) > -1) { //非克隆的属性 是否需进行值引用，否则丢弃
					if (refs && refs.indexOf(keys) > -1) { //
						targetObj[keys] = source[keys]
					} else {

					}
					continue

				}
			}
			if (source.hasOwnProperty(keys)) {
				if (source[keys] && typeof source[keys] === 'object') { // 如果值是对象(包括数组)，就递归一下
					//targetObj[keys] = source[keys].constructor === Array ? [] : {};

					targetObj[keys] = this.deepClone(source[keys], exclude, refs);
				} else { // 如果不是，就直接赋值
					targetObj[keys] = source[keys];
				}
			}
		}
		return targetObj;
	}
	treeClone(source, reCall) { //差异化层级数据克隆，source应为数组对象
		let src = source
		if (!src) {
			return []
		}
		if (typeof(src) == 'string') {
			try {
				src = JSON.parse(src)
			} catch (ex) {
				console.log('JSON数据格式错误')
				return []
			}

		}
		let list = src.items ? src.items : src //兼容直接数据或对象

		//树型数据对象复制算法：逐层添加，一个数组记录第N层的横向节点，依次推进
		let rs = []
		let layerS = list //原数据每一层
		let layerD = [] //新数据每一层
		for (let item of layerS) {
			let newData = reCall(item)
			layerD.push(newData)
			rs.push(newData)
		}
		let tag = true
		while (tag) { //如果在某一层中没有发现一个以上的子节点即完成
			tag = false
			let listS = []
			let listD = []
			for (let i = 0; i < layerS.length; i++) {
				let itemS = layerS[i]
				let itemD = layerD[i]
				if (itemS.items && itemS.items.length > 0) {
					tag = true
					for (let sub of itemS.items) {
						let newData = reCall(sub)
						itemD.items.push(newData)
						listS.push(sub)
						listD.push(newData)
					}
				}
			}
			layerS = listS
			layerD = listD
		}
		return rs
	}
}
export default new DeepClone()