//import store from '../store/index.js'
import compReg from "../components/comm/reg.js"
import layout from '../components/form/config/public/layout.js'
import font from '../components/form/config/public/font.js'
import config from '../components/form/config/config.js'
import tip from './logictip.js'
import message from './logicMessage.js'
import LogicHttp from './logicHttp.js'
import arrayUtil from './logicArrayUtil.js'
import pageUtil from './pageutil.js'
import color from './logicColor.js'
import logicDate from './logicDate.js'
import dataBase from './logicDB.js'
import formUtil from './logicForm.js'
import clone from './deepClone.js'
import security from './logicSecurity.js'
import pingYin from './pingyin/pinyinutil.js'
//import md5 from './md5.js'

class Logic {
	/* 	#store = null
		#layerShow = false
		#arrayUtil = null
		#http = null
		#message = null
		#tip = null
		#color = null
		#date = null
		#db = null
		#form = null
		#clone = null
		#user = null
		#encode = null
		#isDebug = null */
	constructor() {
		//this.isDebug = true //打包时为false采用与服务器一致的端口
		this.arrayUtil = arrayUtil
		this.pageUtil = pageUtil
		/* 		this.user = {
					unitGroup: 0,
					unitId: 1,
					userId: 1,
					userName: '张小鱼'
				} */
		/* this.project = {
			groupId: 0,
			unitId: 0,
			appId:0
		} */
		this.pingyin=pingYin
		this.tip = tip
		this.pop = message
		this.color = color
		this.date = logicDate
		this.db = dataBase
		dataBase.setLogic(this) //需要使用到本类的getUser方法
		this.clone = clone
		this.encoder = {
			md5: security.getMd5,
			base64: security.getBase64,
			fromBase642: security.fromBase642,
			fromBase64: security.fromBase64,

		}
		this.security = security
		this.form = formUtil
		this.form.setLogic(this)
		this.sysInfo = {
			regLevel: 0,
			unitName: '数据错误',
			unitShort: '数据错误',
			sysTitle: '注册信息错误',
			sysShort: '数据错误',
			sysLogo: '',
			sysUrl: '数据错误',
			support: '注册信息错误',
			sysName: '数据错误'

		}
		this.regInfo = {
			'10': '试用版',
			'100': '免费版',
			'500': '欠费版',
			'1000': '铁粉免费版',
			'10000': '正式版'

		}
		//alert('Start')
		let url = window.location.href + "/"; //以防不是以/结尾的
		//alert(url)
		let x = url.indexOf("//")
		x = x < 0 ? 0 : x
		let p = url.indexOf("/", x + 2)
		/* 		url = url.substring(0, p)
				x = url.indexOf(":", x + 2) //http:之后的端口号,第二个：位置
				let port = x < 0 ? url.indexOf('https:') > -1 ? '443' : '80' : url.substring(x + 1, p)
				console.log(port)
				port = this.isDebug ? "9999" : port
				url = x < 0 ? url : url.substring(0, x)
				if (this.isDebug) {
					url = url.replace('192.168.215.215', 'localhost')
				}
				let baseUrl = url + ":" + port
				*/

		let baseUrl = url.substring(0, p)
		url = url.replace('192.168.215.215', 'localhost')
		this.isDebug = url.indexOf('http://localhost') > -1
		//console.log(baseUrl)
		if (this.isDebug) {
			baseUrl = 'http://localhost:9999'
		}
		//alert(baseUrl)
		let http = new LogicHttp(baseUrl, 1800000, this.isDebug ? 'http://localhost:9999' :
			null) //1800000 30分钟,与数据库最大响应时间致
		http.init(this)
		this.http = http
	}
	showLayer(isShow) { //设置遮罩层可见状态
		this.layerShow = isShow
		if (isShow) { //避免短时间内交互出现遮罩层显示隐藏产生屏幕闪烁，采用延时方法，发果短时间内已返回不显示	
			setTimeout(() => {
				if (this.layerShow) {
					this.commitStore('setCoverLayerShow', this.layerShow)
				}
			}, 500)
		} else {
			this.commitStore('setCoverLayerShow', isShow)
		}


	}
	setTemplateUser(unitGroup, unitId) {
		let tm = (new Date()).getTime()
		let user = {
			id: 0,
			orgId: 0,
			userName: '访客',
			unitGroup: unitGroup,
			unitId: unitId,
			loginTime: tm,
			endTime: tm + 86400000 * 1000
		}
		this.saveUser(user)
	}
	saveUser(user) {
		//this.unit.groupId = user.unitGroup
		//this.unit.unitId = user.unitId
		let code = '' + user.loginTime
		code = code.substring(3) + user.id //id+10位
		code = security.encodeSql(code) //  this.encoder.fromBase642(code)
		user.signCode = code
		this.commitStore('setUser', user)
		//localStorage.setItem('$$user', JSON.stringify(user))
	}
	getSimpleUser() { //获取简单的用户对象
		let user = {
			...this.getUser()
		}
		let keys = []
		for (let key in user) {
			let value = user[key]
			if (value == null) {
				continue
			}
			if (typeof value === 'object') { //数组、函数、对象等属性全排除掉
				keys.push(key)
			}

		}
		for (let key of keys) {
			delete user[key]
		}
		return user
	}
	getUser() {
		let user = this.store.state.user
		if (!user) {
			let us = localStorage.getItem("$$user")
			if (us && us.length > 1) {
				user = JSON.parse(us)
			}
		}
		return user || {}
	}
	setStore(store) {
		this.store = store
	}
	setClipboard(toClipboard) {
		this.clipboard = {
			toClipboard: toClipboard,
			copy: async function(text) {
				try {
					await toClipboard(text)
					console.log('success:' + text)
				} catch (ex) {
					console.log('copy error')
					console.log(ex)
				}
			}
		}
	}
	commitStore(objName, value) {
		this.store.commit(objName, value)
	}

	getStyle(element, project) {//isHover
		if (element) {

		} else {
			return null
		}
		let css = {}
		let confs = [].concat(config.objs[element.type].configs).concat([{ // layout.concat(font).concat([{
			key: 'lineColl',
			target: 'border-collapse'
		}]) //border-collapse只用于表格特有 
		for (let item of confs) {
			if (item.target) { //可以直接输入样式的属性


				let val = element[item.key]

				if (val && val.length > 0) // || item.key.indexOf('border') ==0) 
				{ //有值且不以0px开头,边框类属性特殊，需全输出
					css[item.target] = val
				}
			}

		}
		/* 		//设置了alignContent后alignItems不生效，简化用户操作  设置垂直对齐，alignContent 在容器内只有单行元素时不生效，此时采用alignItems 替换
				if(css['alignContent']  && element.items.length<){
					css['alignItems']=css['alignContent']
					css['alignContent']=null
				} */
		if (css['alignContent']) { //同时设置两者对齐方式，确保垂直方向对齐生效
			css['alignItems'] = css['alignContent']
		}
		//设置可见性
		if (element.show == 'hidden') {
			css['visibility'] = 'hidden'
		} else {
			css['visibility'] = 'visible'
		}

		//如果设置了内边距计算宽高
		//let ht = this.getNumber(element.height)
		let eh = (element.height || '').trim().toLocaleLowerCase()

		let pt = this.getNumber(element.paddingT)
		let pb = this.getNumber(element.paddingB)
		let pl = this.getNumber(element.paddingL)
		let pr = this.getNumber(element.paddingR)

		let mt = this.getNumber(element.marginT)
		let mb = this.getNumber(element.marginB)
		let ml = this.getNumber(element.marginL)
		let mr = this.getNumber(element.marginR)

		let bt = this.getNumber(element.borderT)
		let bb = this.getNumber(element.borderB)
		let bl = this.getNumber(element.borderL)
		let br = this.getNumber(element.borderR)

		let xh = pl + pr + ml + mr + bl + br
		let xv = pt + pb + mt + mb + bt + bb
		if (eh.length > 0 && xv > 0) {

			if (eh.startsWith('calc(')) { //脱掉元素的外层calc函数
				let x = eh.indexOf(')')
				if (x > -1) {
					eh = eh.substring(5, x)
				}
			}
			css.height = 'calc( ' + eh + ' - ' + xv + 'px )'
		}
		//let wt = this.getNumber(element.width)
		let ew = (element.width || '').trim().toLocaleLowerCase()
		if (ew.length > 0 && xh > 0) {
			if (ew.startsWith('calc(')) { //脱掉元素的外层calc函数
				let x = ew.indexOf(')')
				if (x > -1) {
					ew = ew.substring(5, x)
				}
			}
			css.width = 'calc( ' + ew + ' - ' + xh + 'px )'
		}
		//响应式容器宽不处理
		if (element.autoWidth) { //如果是动态宽度，设置宽度变量
			let width = element.autoWidth
			//实际宽度为定义值减掉边距空白边框
			if (width.xs.indexOf('calc') > -1) { //计算类型值内容附加减除， 非计算值改为计算值				
				css['--auto-width-xs'] = width.xs.substring(0, width.xs.lastIndexOf(')')) + ' - ' + xh + 'px)'
			} else {
				css['--auto-width-xs'] = 'calc(' + width.xs + ' - ' + xh + 'px)'
			}
			if (width.sm.indexOf('calc') > -1) {
				css['--auto-width-sm'] = width.sm.substring(0, width.sm.lastIndexOf(')')) + ' - ' + xh + 'px)'
			} else {
				css['--auto-width-sm'] = 'calc(' + width.sm + ' - ' + xh + 'px)'
			}
			if (width.md.indexOf('calc') > -1) { //计算类型值内容附加减除， 非计算值改为计算值
				css['--auto-width-md'] = width.md.substring(0, width.md.lastIndexOf(')')) + ' - ' + xh + 'px)'
			} else {
				css['--auto-width-md'] = 'calc(' + width.md + ' - ' + xh + 'px)'
			}
			if (width.lg.indexOf('calc') > -1) { //计算类型值内容附加减除， 非计算值改为计算值
				css['--auto-width-lg'] = width.lg.substring(0, width.lg.lastIndexOf(')')) + ' - ' + xh + 'px)'
			} else {
				css['--auto-width-lg'] = 'calc(' + width.lg + ' - ' + xh + 'px)'
			}

		}
		/* 响应式排版 */
		if (element.autoAlign) {
			let align = element.autoAlign
			css['--auto-align-xs'] = align.xs
			css['--auto-align-sm'] = align.sm
			css['--auto-align-md'] = align.md
			css['--auto-align-lg'] = align.lg
		}


		/* 		
				isHover = false //不作判断
				if (ht > 0) { //设置了高度才做高度计算
					if (isHover || pt != 0 || pb != 0 || bt != 0 || bb != 0) {
						css.height = 'calc(' + element.height + ' - ' + element.paddingT + ' - ' + element.paddingB +
							' - ' + element.borderT + ' - ' + element.borderB
						//if(isHover){//多减2个象素，为防止悬停时虚线点两个高度撑出总高度导致出现滚动条
						if (bb > 0) { //多减一个象素确保下边框出来
							css.height = css.height + ' - 1px'
						}
						css.height = css.height + ')'
					}
				}
				if (isHover || pl != 0 || pr != 0 || bl != 0 || br != 0) {
					css.width = 'calc(' + element.width + ' - ' + element.paddingL + ' - ' + element.paddingR + ' - ' +
						element.borderL + ' - ' + element.borderR
					if (isHover) { //多减2个象素，为防止悬停时虚线点两个宽度撑出总宽度导致换行
						css.width = css.width + ' - 2px'
					}
					css.width = css.width + ')'
				}
		 */
		if (element.backImg) { //&& element.backImg.length > 0id: 55, : 0, unitId
			let backImg =project && element.backImg.startsWith('@') ? '/upload/'+project.unitGroup+'/'+project.unitId+'/'+project.id+element.backImg.substring(1) : element.backImg
			
			let url = this.isDebug ? this.http.baseUrl + backImg : backImg

			css.backgroundImage = "url('" + url + "')"
			switch (element.backMode) {
				case "cover":
					css.backgroundSize = 'cover'
					break
				case 'fill':
					css.backgroundSize = '100% 100%'
					break;
				default:
					css.backgroundRepeat = element.backMode
			}
		}

		//扩展样式
		if (element.cssStyle) {
			let style = element.cssStyle.split(';')
			for (let item of style) {
				let row = item.trim().replace(/\n/g, '').replace(/\r/g, '')
				if (row.length < 3) {
					continue
				}
				let parts = row.split(':')
				if (parts.length != 2) {
					continue
				}
				let key = parts[0].trim()
				let value = parts[1].trim()
				if (key.length < 1 || value.length < 1) {
					continue
				}
				css[key] = value

			}
		}
		return css
	}
	getCss(element) {
		return element.align
	}
	getNumber(text) {
		if (text) {
			let v = parseInt(text)
			if (isNaN(v)) {
				return 0
			} else {
				return v
			}
		} else {
			return 0
		}
	}

	setUser(user) {
		this.user = user
	}

}
let rs = {
	logic: new Logic(),
	install(app, options) {
		app.config.globalProperties.$logic = this.logic
		compReg(app)
	}
}
export default rs