<template>
	<div ref="block" @click="mouseClick" @mousedown.self="myMouseDown" @mouseover="mouseOver" @mouseleave="mouseLeave"
		@mouseup="myMouseUp" @mousemove="myMouseMove" :style="myStyle" :class="myCss" :title="model.tipText">
		<template v-for="item in model.items">
			<container v-if="item.group==='layout'" :model="item" :project="project" :page="page" :host="host"
				:isedit="isedit" :datas="bindData" />
			<base-element v-else :model="item" :project="project" :page="page" :host="host" :isedit="isedit"
				:datas="bindData" />

		</template>
	</div>
</template>

<script>
	import layoutBase from './layoutBase.js'

	export default {
		mixins: [layoutBase],
		data() {
			return {
				bindData: null,
				moveStart: false,
				x1: 0,
				y1: 0,
				xy: { //当前鼠标点击的位置
					x: 0,
					y: 0
				}


			}
		},

		methods: {
			myMouseDown(event, target) {
				let model = this.model
				if (!this.isedit && model.allowMove === 'Y') {
					let block = this.$refs.block
					this.moveStart = true
					/* 	this.resizeWidth = block.offsetWidth */
					this.x1 = event.pageX
					this.y1 = event.pageY
					this.xy.x = event.offsetX
					this.xy.y = event.offsetY

					//console.log(event)
				}
				this.mouseDown(event, target)
			},
			myMouseMove(event) {
				if (this.moveStart === true) {
					let model = this.model
					let x = event.pageX
					let y = event.pageY
					model.left = (x - this.xy.x) + 'px'
					model.top = (y - this.xy.y) + 'px'
					model.right = null
					model.bottom = null
					//console.log(model.top)
					//console.log(y, this.xy.y, y - this.xy.y)

				}
			},
			myMouseUp(event, target) {
				this.moveStart = false
				this.mouseUp(event, target)
			}

		},
		computed: {
			myStyle() {
				let model = this.model
				let css = this.layoutstyle
				if (!this.isedit) {
					css.position = 'fixed'
					css['z-index'] = 100
					if (model.allowMove === 'Y') {
						css.cursor = 'move'
					}


					if (model.left) {
						css.left = model.left
					}
					if (model.top) {
						css.top = model.top
					}
					if (model.right) {
						css.right = model.right
					}
					if (model.bottom) {
						css.bottom = model.bottom
					}
				}
				return css
			},
			myCss() {
				let css = this.layoutcss
				if (this.model.allowMove === 'Y') {
					css.push('noselect')
				}
				return css

			}
		},
		created() {


		}
	}
</script>

<style scoped>

</style>