<template>
	<div @click="mouseClick" @mousedown="mouseDown" @mouseover="mouseOver" @mouseleave="mouseLeave" @mouseup="mouseUp"
		:style="layoutstyle" :class="divClass" :title="model.tipText">
		<span v-if="model.innerText">{{model.innerText}}</span>
		<template v-for="item in model.items">
			<container v-if="item.group==='layout'" :model="item" :project="project" :page="page" :host="host"
				:isedit="isedit" :datas="bindData" />
			<base-element v-else :model="item" :project="project" :page="page" :host="host" :isedit="isedit"
				:datas="bindData" />

		</template>

	</div>



</template>

<script>
	//import containerBase from '../containerBase.js'
	import layoutBase from './layoutBase.js'


	export default {
		mixins: [layoutBase],
		data() {
			return {
				bindData: null,
			}
		},

		methods: {

		},
		computed: {
			divClass() {
				let css = this.layoutcss


				if ('enabled' in this.model) { //含用可用状态的属性情况下

					if (this.model.enabled === 'Y') {
						if (this.model.url) {
							css.push('custom-cursor-pointer')
						}
					} else {
						if (!this.isedit) {
							css.push('custom-disabled')
						}

					}
				}

				return css
			}

		},

		created() {
			if (!this.isedit) {
				this.model.$linkTo = this.toLink
			}
			this.createInit()

		}
	}
</script>

<style scoped>

</style>