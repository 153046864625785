
import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import font from '../../public/font.js'
import extend from '../../public/extend.js'
let datas =JSON.stringify( [
    { 		label: '选项一', 		data: {} 	},
 	{ 		label: '选项二', 		data: {} 	},
 	{ 		label: '选项三', 		data: {} 	},
 	{ 		label: '选项四', 		data: {} 	}, 
	 
]) //
let queryResult=JSON.stringify(  [{
						label: 'ID',
						fieldName: 'id',
						with: '100px'
					}, {
						label: '名称',
						fieldName: 'label',
						with: ''
					}] 
				).replaceAll('},{','},\r\n{')
				
				
				
let events=[
	{key:'title',text:'组件业务事件'},
	//{key: '$comp_getsql',text: 'SQL生成',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '查询条件使用预编译占位符形式,函数返回值示例：{sql:"select label from table where id>? and keyword like ?",params:[0,"abc%"]}',list: null	},
	{key: '$comp_choose',text: '条目选定后',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: 'event中的item属性为当前已选条目',list: null	},

]
let styles=compBase.toOptions('方框=A,按钮=B')
let idFieldConfig=compBase.getDataConfig()
let labelFieldConfig=compBase.getDataConfig()
let validate=compBase.getValidate() 
validate.validateSet.exclude='input'

let inputOption=options.getInputOption() //options.inputOption //{seted:false,sourceKey:'',default:'',tree:{width:'800px',height:'80vh',char:'/',nodes:null,sql:'',id:'',pId:'',label:'',textPath:'',idPath:''},treedata:{sql:'',tableStyle:{heads:[]},ListResult:[]} ,load:{sql:'',fields:[],list:[],fill:[],tableMenu:'N',menuWidth:'',menuHeight:'200',tableStyle: {heads: []}}}//dataSql:'',listSql:''

let base = [
	{key: 'title',text: '联想输入'	},
	{key:'inputType',text:'输入方式',type:'text',init:'suggest',target:'',bursh:true,bind:false,tip:'',list: null},
	{key:'inputOption',text:'选项设置',type:'inputset',init:inputOption,target:'',bursh:true,bind:false,tip:'',list: null,attribute:'inputType'},
	//{key: 'maxLength',text: '下拉数量',type: 'number',init: 20,	target: '',	bursh: true,bind: true,tip: '值为0不限制数量,最大值1000',list: null},
		
	{key: 'idValue',text: '选项ID值',type: 'text',init: null,	target: '',bursh:true,bind:true,tip: '',list: null},
	{key: 'labelValue',text: '选项文本值',type: 'text',init: '',	target: '',bursh:true,bind:true,tip: '',list: null},
	{key: 'save',text: '提交保存',type: 'switch',init: 'Y',target: '',bursh: true,bind: true,tip: '是否将此组件数据提交写入到数据库',list: null},
	{key: 'idField',text: 'ID字段',type: 'field',init: idFieldConfig,	target: '',	bursh: true,bind: false,tip: '',attribute:'idValue'},
	{key: 'labelField',text: '文本字段',type: 'field',init: labelFieldConfig,	target: '',	bursh: true,bind: false,tip: '',attribute:'labelValue'},

 	{key: 'validateSet',text: '校验时机',type: 'triger',init: validate.validateSet,	target: '',	bursh: true,bind: false,tip: '',list:null},
	{key: 'validateRule',text: '校验规则',type: 'validate',init: validate.validateRule,	target: '',	bursh: true,bind: false,tip: '',attribute:'idValue'},
	{key: 'ignoreHide',text: '隐藏时忽略',type: 'switch',init: 'N',target: '',bursh: true,bind: true,tip: '元素为不可见状态时不进行数据校验',list: null},

//	{key: 'remote',text: '远程加载',type: 'select',init: 'Y',	target: '',	bursh: true,bind: true,tip: '如果启用远程数据加载请实现SQL生成函数，详见函数说明',list: options.YON},

//    {key: 'multiple',text: '是否多选',type: 'select',init: 'N',	target: '',	bursh: true,bind: false,tip: '',list: options.YON},	
 //   {key: 'max',text: '最多选择数',type: 'number',init: '0',	target: '',	bursh: true,bind: false,tip: '值为0不限制数量',list: null},
	{key: 'iconIH',text: '内置前图标',type: 'icon',init: '',	target: '',	bursh: true,bind: true,tip: '',list: null},
    {key: 'iconIT',text: '内置后图标',type: 'icon',init: 'ChatDotRound',	target: '',	bursh: true,bind: true,tip: '',list: null},
    {key: 'iconOH',text: '外置前图标',type: 'icona',init: '',	target: '',	bursh: true,bind: true,tip: '',list: null},
    {key: 'textOH',text: '外置前文本',type: 'text',init: '',	target: '',	bursh: true,bind: true,tip: '',list: null}, 
    {key: 'iconOT',text: '外置后图标',type: 'icona',init: '',	target: '',	bursh: true,bind: true,tip: '',list: null},
    {key: 'textOT',text: '外置后文本',type: 'text',init: '',	target: '',	bursh: true,bind: true,tip: '',list: null},

	{key: 'size',text: '大小样式',type: 'select',init: 'default',target: '',	bursh: true,bind: true,tip: '',list: compBase.sizes},
    {key: 'tip',text: '空值提示',type: 'text',init: '请输入',	target: '',	bursh: true,bind: true,tip: '',list: null},
	{key: 'clearable',text: '清空按钮',type: 'select',init: 'Y',	target: '',	bursh: true,bind: false,tip: '',list: options.YON},
//    {key:'menuWidth',text:'选项区宽度',type:'number',init:null,target:'',	bursh: true,bind: true,tip:'选择列表项的区域宽度，象素值，不设置默认与输入框同一宽度,如果设置的宽度小于输入框宽度以输入框为宽度',list:null},
//    {key:'menuHeight',text:'选项区高度',type:'number',init:'200',target:'',	bursh: true,bind: true,tip:'选择列表项的区域最大高度，象素值',list:null},

    {key:'width',text:'宽度',type:'text',init:'100%',target:'width',	bursh: true,bind: true,tip:'象素、百分比、函数类型',list:null},
   
   compBase.status2


]
let sql=JSON.stringify({sql:'select id,title as label from table where title like ?',params:['%$keyword%']}) 
let suggest = {
	data: {
		type: 'suggest',isInput:'M',
	},
	delete: '',
	hide: 'multiple,width,max,inputType',
	base: base.concat(compBase.base),
	special: extend.tip.concat([
		
	
		
/* 		
		{key: 'sql',text: '查询Sql',type: 'json',init:sql,	target: '',	bursh: true,bind: true,tip: '包含SQL与参数集合,返回结果中至少需要有id、label字段，$keyword为固定变量名，用于替换用户输入的内容，示例：{sql:"select id, title as label from table where status=? and title like ?",params:[0,"$keyword%"]}',list: null	},
		{key: 'listData',text: '固定选项',type: 'jsonarray',init: datas,	target: '',	bursh: true,bind: true,tip: '本地选项数据，如不启用远程加载即使用本地数据',list: null,treeMap:{id:'label',label:'label',icon:null},dataMap: {label:{label:'列表项文本',type:'text'},data:{label:'数据对象',type:'json',init:{}}}},
		{key:'tableMenu',text:'表格式选项',type:'switch',init:'N',target:'',	bursh: true,bind: true,tip:'列表选择项以表格方式弹出，如果启用此选项，请设置列表样式',list:null},
		{key: 'tableHead',text: '表格列样式',type: 'jsonarray',init: queryResult,	target: '',bursh:true,bind:true,tip: '设置数据查询结果的表格，heads定义表格列，成员中label为表格列的标题，fieldName为SQL查询返回的字段名称，必须大小写一致',list: null,treeMap:{id:'fieldName',label:'label',icon:null},dataMap: {label:{label:'列表标题',type:'text'},fieldName:{label:'字段名',type:'text'},width:{label:'列宽',type:'text'}}},
		 */
		]),



	action: [
		{key: '$comp_queryBefore',text: '数据查询前',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '返回JSON格式查询对象，参考SQL配置样式，如有返回值以此查询为准，以实现更复杂的动态查询逻辑',list: null	},
		
	].concat( events.concat( options.getEvents(null,'$event_click,$event_change')).concat(options.getEvents(null,'$hook')))
}

export default suggest
 