<template>
	<div :style="imgStyle" class="img-content">
		<!-- 	<img :src="imgSrc" class="img-item" onerror="onerror=null;src=''">
		 -->
		<el-image style="width: 100%; height: 100%" :src="imgUrl" :preview-src-list="[imgUrl]"
			:preview-teleported="true" fit="fill">
			<template #error>
				<div class="img-error">
					<img v-if="imgError" :src="imgError" style="width: 100%;height:100%;" />
					<i v-else class="fal fa-image"></i>
				</div>

			</template>
		</el-image>


		<div class="img-tool">
			<i class="fas fa-times" @click="fileDelete"></i>
			<el-upload ref="upload" :action="uploadUrl" :data="uploadData" :before-upload="beforeUpload"
				:multiple="false" :limit="1" :file-list="selectedFiles" :on-exceed="onExceed" :on-success="onSuccess"
				:on-error="onError" :show-file-list="false">
				<i class="fas fa-arrow-alt-up"></i>

			</el-upload>


		</div>


	</div>
</template>

<script>
	import fileUpload from '../file/upload.js'
	export default {
		mixins: [fileUpload],
		emits: ['success', 'delete'],
		props: {
			appId: {
				type: Number,
				default: 0
			},
			imgSrc: {
				type: String,
				default: '/img/userhead.png'
			},
			imgError: {
				type: String,
				default: null
			},
			saveName: { //如果指定此值，将以此文件名保存到服务器
				type: String,
				default: ''
			},

			imgWidth: {
				type: String,
				default: '30px'
			},
			imgHeight: {
				type: String,
				default: '30px'
			},
			imgRadius: {
				type: String,
				default: '0px'
			},
			borderWidth: {
				type: String,
				default: '0px'
			},
			borderColor: {
				type: String,
				default: '#cccccc'
			}
		},
		data() {
			return {
				imgList: [
					/* 	'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
						'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
						'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
						'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
						'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
						'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg',
						'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg', */
				]

			}
		},

		methods: {
			fileDelete() {
				this.$logic.pop.confirm('确认', '是否确定删除此文件', () => {

					this.$logic.http.post(true, '/api/editor/deletefile/', {
						appId: this.appId,
						reList: false,
						fileUrl: this.imgSrc
					}, null, res => {
						this.sn++
						let fileItem = {
							localName: this.saveName,
							url: this.imgSrc
						}
						this.$emit('delete', this.dataItem, [fileItem])
					})


				})



			}

		},
		computed: {
			imgStyle() {
				let css = {
					width: this.imgWidth,
					height: this.imgHeight,
					borderRadius: this.imgRadius,
					borderWidth: this.borderWidth,
					borderColor: this.borderColor
				}
				return css
			},
			fileSaveName() { //指定上传到服务器的文件名，覆盖的upload.js方法
				return this.saveName
			},
			baseUrl() {
				let url = this.$logic.http.baseUrl
				return url
			},
			imgUrl() {
				return this.imgSrc ? this.baseUrl + this.imgSrc + '?' + this.sn : ''
			},
			/* 	imgList(){
					//return [this.imgUrl]
				 
				} */
		},
		created() {


		}
	}
</script>

<style scoped>
	.img-content {
		position: relative;
		cursor: pointer;
		--img-tool: none;
	}

	.img-content:hover {
		--img-tool: block;
	}

	.img-error {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.img-tool {
		position: absolute;
		top: -2px;
		right: 0px;
		/* width: 40px; */
		text-align: right;
		/* 	z-index: 100; */
		/* 	background-color: rgba(171, 171, 171, 0.5); */
		width: 100%;
		display: flex;
		justify-content: center;
		/* align-items: center; */
	}

	.img-tool i {
		margin-top: 5px;
		margin-left: 2px;
		margin-right: 2px;
		font-size: 16px;
		color: #00aa00;
		display: var(--img-tool);
	}

	.img-tool i:hover {
		color: #F56C6C;
	}
</style>