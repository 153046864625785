import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import font from '../../public/font.js'
import extend from '../../public/extend.js'
/* let queryResult=JSON.stringify(  {
					heads: [{
						label: 'ID',
						fieldName: 'id',
						with: '100px'
					}, {
						label: '名称',
						fieldName: 'label',
						with: ''
					}] 
				}).replaceAll('},{','},\r\n{') */
 let queryResult=  {
					heads: [/* {
						label: 'ID',
						fieldName: 'id',
						width: '100px'
					}, {
						label: '名称',
						fieldName: 'label',
						width: ''
					} */] 
				}				
				
let typeOption=options.getInputOption() // options.inputOption //{seted:false,sourceKey:'',default:'',textAction:[],decimal:2,numAction:'',dateInit:'',dateType:'datetime',selectOptions:[],express:{content:'',bolanshi:null,vars:{}},tree:{width:'800px',height:'80vh',char:'/',nodes:null,sql:'',id:'',pId:'',label:'',textPath:'',idPath:''},treedata:{sql:'',tableStyle:{heads:[]},ListResult:[]} ,load:{sql:'',fields:[],list:[],fill:[],tableMenu:'N',menuWidth:'',menuHeight:'200',tableStyle:queryResult}}//dataSql:'',listSql:''
typeOption.jsonArray.heads=[
	{key:'value',label:'选项值',type:'text',default:'',list:[],field:'',width:'30%',unique:true},
	{key:'label',label:'选项文本',type:'text',default:'',list:[],field:'',width:'30%',unique:false},
	{key:'disabled',label:'禁用状态',type:'switch',default:'N',list:[],field:'',width:'',unique:false},
 
]
let dataConfig=compBase.getDataConfig()
let validate=compBase.getValidate() 
validate.validateSet.exclude='change,input,blur'
let cs=[
{key:'title',text:'表格列'},
{key:'headName',text:'列名',type:'text',init:'',target:'',bursh:true,bind:true,tip:'列名采用大写字母的规范，系统自动生成，可修改',list: null},
{key:'headText',text:'标题',type:'text',init:'标题',target:'',bursh:true,bind:true,tip:'',list: null},
{key: 'save',text: '提交保存',type: 'switch',init: 'Y',target: '',bursh: true,bind: true,tip: '是否将此组件数据提交写入到数据库',list: null},
{key:'dataName',text:'数据名称',type:'text',init:'',target:'',bursh:true,bind:false,tip:'绑定列表查询数据集的字段名称,不一定用于数据提交',list: null},

{key: 'fieldName',text: '数据表字段',type: 'field',init: dataConfig,	target: '',	bursh: true,bind: false,tip: '指定该列数据保存到数据表的所在字段，如不需保存不必设置',host:'^dbTable'},//:开头表示找父元素的上级属性

{key:'inputType',text:'输入方式',type:'select',init:'text',target:'',bursh:true,bind:false,tip:'',list: options.inputTypes},
{key:'inputOption',text:'输入选项',type:'inputset',init:typeOption,target:'',bursh:true,bind:false,tip:'',list: null,attribute:'inputType'},

{key: 'validateSet',text: '校验时机',type: 'triger',init: validate.validateSet,	target: '',	bursh: true,bind: false,tip: '',list:null},
{key: 'validateRule',text: '校验规则',type: 'validate',init: validate.validateRule,	target: '',	bursh: true,bind: false,tip: '',attribute:null},
{key: 'ignoreHide',text: '隐藏时忽略',type: 'switch',init: 'N',target: '',bursh: true,bind: true,tip: '元素为不可见状态时不进行数据校验',list: null},
{key:'status',text:'操作模式',type:'select',init:'edit',target:'',bursh:true,bind:true,tip:'取值：edit:编辑模式，disabled:只读模式',list: [{label:'可编辑',value:'edit'},	{label:'只读',value:'disabled'}]},

{key:'headWidth',text:'列宽',type:'text',init:null,target:'width',bursh:true,bind:true,tip:'空值为自适应,象素、百分比、函数类型',list:null},
{key:'minWidth',text:'最小列宽',type:'text',init:'100px',target:'minWidth',bursh:true,bind:true,tip:'象素、百分比、函数类型',list:null},

{key:'headAlign',text:'标题对齐',type:'select',init:'center',target:'textAlign',bursh:true,bind:true,tip:'',list: options.LCR},
//{key:'headHeight',text:'标题高度',type:'text',init:'30px',target:'height',bursh:true,bind:true,tip:'',list: null},
{key:'backColor',text:'背景颜色',type:'colorA',init:'#eaeaea',target:'backgroundColor',bursh:true,bind:true,tip:'',list: null},
{key:'inputAlign',text:'内容区对齐',type:'select',init:'',target:'',bursh:true,bind:true,tip:'',list: options.toOptions('左对齐=input-left,中间对齐=input-center,右对齐=input-right')},
//{key:'tdV',text:'单元格纵向',type:'select',init:'middle',target:'',bursh:true,bind:true,tip:'',list: options.TMB},
{key:'actionCols',text:'默认值',type:'json',init:{},target:'',bursh:true,bind:false,tip:'actionCols存每列关联的列名，更新了些列数据将触发关联列自动计算更新',list: null},
{key:'foot',text:'页脚',type:'json',init:{},target:'',bursh:false,bind:false,tip:'',list: null},

	
]

let inputhead= {
	data:{
		type:'inputhead',
	},
	delete:'',
	hide:'actionCols,foot',
	base:cs.concat(font) ,
	special: [	],

	action:[].concat([
		
	])
}

export default inputhead